@use "global" as *;

.p-arena{
  background-color: $white;
  margin-top: rem(53);
  @include mq(md) {
    margin-top: vw-m(87);
  }
}

.p-arena__bg {
  border-radius: rem(30);
  padding: rem(40) rem(84) rem(90);
  background-position: top left;
  @include mq(md) {
    border-radius: 0;
    padding: vw-m(84) vw-m(30) vw-m(108);
  }
}

.p-arena__list {
  margin-top: rem(22);
  @include mq(md) {
    margin-top: vw-m(47);
  }
}