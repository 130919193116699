@use "global" as *;

.p-more-info {
  background-color: $white;
  padding-top: rem(21);
  padding-bottom: rem(68);
  @include mq(md) {
    padding-top: vw-m(80);
    padding-bottom: vw-m(17);
  }
}

.p-more-info__ttl {
  letter-spacing: 0.01em;
}

.p-more-info__ttl::after {
  margin-top: rem(-3);
  letter-spacing: 0.1em;
  @include mq(md) {
    margin-top: vw-m(-3);
    letter-spacing: normal;
  }
}

.p-more-info__list {
  margin-top: rem(80);
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  column-gap: rem(60);
  @include mq(md) {
    margin-top: vw-m(85);
    gap: vw-m(51);
  }
}

.p-more-info__item {
  max-width: rem(520);
  width: 100%;
  @include mq(md) {
    max-width: unset;
  }
}

.p-more-info__item:last-child {
  margin-top: rem(68);
  @include mq(md) {
    margin-top: 0;
  }
}

.p-more-info__img {
  width: 100%;
  border-radius: rem(22);
  position: relative;
  overflow: hidden;
  z-index: 1;
  @include mq(md) {
    border-radius: vw-m(20);
  }
}

.p-more-info__img::before {
  content: "";
  display: block;
  width: rem(41);
  height: rem(41);
  background-image: url(../img/icon/icon_arrow-right.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  bottom: rem(-1);
  right: rem(-1);
  z-index: 1;
  @include mq(md) {
    width: vw-m(67);
    height: vw-m(71);
    bottom: vw-m(-2);
    right: vw-m(-2);
  }
}

.p-more-info__img img {
  width: 100%;
  object-fit: cover;
  aspect-ratio: 520 / 208;
  @include mq(md) {
    aspect-ratio: 690 / 208;
  }
}

.p-more-info__item-ttl{
  font-size: max(rem(26), 10px);
  line-height: calc(37 / 26);
  letter-spacing: 0.11em;
  font-weight: 700;
  margin-top: rem(11);
  text-align: center;
  @include mq(md) {
    font-size: vw-m(26);
    line-height: calc(37 / 26);
    margin-top: vw-m(8);
  }
}
