@use "global" as *;

.p-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1000;
  display: none;
  transform: translate(-50%, -50%);
  transition: transform 0.5s, opacity 0.1s;
}

.p-modal__container {
  margin: 0 auto;
  position: relative;
}

.p-modal__btn-wrapper{
  position: absolute;
  right: rem(48);
  top: rem(-12);
  z-index: 2;
  @include mq(md) {
    right: vw-m(48);
    top: -2.5%;
    z-index: 1000;
  }
}

.p-modal__closed-btn {
  max-width: rem(56);
  width: 100%;
  @include mq(md) {
    max-width: vw-m(80);
  }
}

.js-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  opacity: 1;
  z-index: 999;
  display: none;
}

.js-overlay.l-overlay--news {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 1;
  z-index: 999;
  display: none;
}