@use "global" as *;

.p-access {
  background-color: $white;
  padding-top: rem(96);
  padding-bottom: rem(116);
  @include mq(md) {
    padding-top: vw-m(95);
    padding-bottom: vw-m(94);
  }
}

.p-access__ttl::after {
  margin-top: 0;
}

.p-access__map {
  height: rem(400);
  margin-top: rem(26);
  border: rem(1) solid $black;
  border-radius: rem(30);
  position: relative;
  @include mq(md) {
    margin-top: vw-m(45);
    height: vw-m(718);
  }
}

.p-access__map iframe {
  display: block;
  border-radius: rem(30);
  width: 100%;
  height: 100%;
  aspect-ratio: 1100 / 400;
  pointer-events: none;
  @include mq(md) {
    aspect-ratio: 689 / 718;
  }
}

.p-access__btn {
  position: absolute;
  bottom: rem(17);
  right: rem(21);
  z-index: 100;
  width: rem(222);
  @include mq(md) {
    bottom: vw-m(17);
    right: vw-m(16);
    width: vw-m(340);
  }
}

.p-access__btn a {
  position: relative;
  z-index: 100;
  font-weight: 700;
}

.p-access__info {
  margin-top: rem(31.5);
  display: flex;
  gap: rem(70);
  @include mq(md) {
    flex-direction: column;
    margin-top: vw-m(50.9);
    gap: vw-m(28);
  }
}

.p-access__info-wrapper {
  max-width: rem(284);
  width: 100%;
  @include mq(md) {
    display: contents;
  }
}

.p-access__info-wrapper-item {
  padding-bottom: rem(14);
  border-bottom: rem(1) dashed #D1D1D1;
  @include mq(md) {
    padding-bottom: vw-m(9);
    border-bottom: vw-m(2) dashed #D1D1D1;
  }
}

.p-access__info-wrapper-item + .p-access__info-wrapper-item {
  margin-top: rem(17.5);
  @include mq(md) {
    margin-top: 0;
  }
}


.p-access__info-wrapper-item dt {
  color: $red;
  font-weight: 700;
  letter-spacing: 0.06em;
  font-size: max(rem(16), 10px);
  @include mq(md) {
    font-size: vw-m(24);
  }
}

.p-access__info-wrapper-item dd {
  font-weight: 500;
  letter-spacing: 0.06em;
  font-size: max(rem(16), 10px);
  margin-top: rem(10);
  @include mq(md) {
    font-size: vw-m(24);
    margin-top: vw-m(8);
    white-space: nowrap;
  }
}

.p-access__info-wrapper-item dd > a {
  pointer-events: none;
  @include mq(md) {
    pointer-events: auto;
  }
}


/*SP並び順*/
.p-access__info-wrapper-item--address {
  @include mq(md) {
    order: 1;
  }
}

.p-access__info-wrapper-item--tel {
  @include mq(md) {
    order: 4;
  }
}

.p-access__info-wrapper-item--open {
  @include mq(md) {
    order: 2;
  }
}

.p-access__info-wrapper-item--holiday {
  @include mq(md) {
    order: 5;
  }
}

.p-access__info-wrapper-item--traffic {
  @include mq(md) {
    order: 6;
  }
}

.p-access__info-wrapper-item--parking {
  @include mq(md) {
    order: 3;
  }
}