@charset "UTF-8";
:root {
  --fz12: max(0.75rem, 10px);
  --fz14: max(0.875rem, 10px);
  --fz16: max(1rem, 10px);
  --fz18: max(1.125rem, 10px);
}

html {
  font-size: 100%;
  background-color: #fff;
}
@media (max-width: 1300px) {
  html {
    font-size: 1.2307692308vw;
  }
}
@media screen and (max-width: 767px) {
  html {
    font-size: 100%;
  }
}
@media (max-width: 375px) {
  html {
    font-size: 4.2666666667vw;
  }
}
@media (max-width: 320px) {
  html {
    font-size: 4.375vw;
  }
}

body {
  font-family: "source-han-sans-japanese", "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, sans-serif;
  color: #151515;
}

a:hover,
button:hover {
  cursor: pointer;
}

/*****************************
* A Modern CSS Reset (https://github.com/hankchizljaw/modern-css-reset)
* 上記に、ul要素,ol要素,a要素への記述追加
*****************************/
/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd,
ul,
ol,
li {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul,
ol {
  list-style: none;
  padding: 0;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

a {
  text-decoration: none;
  color: inherit;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

button {
  border: none;
  padding: 0;
  background-color: initial;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
.l-bg-wrapper {
  background-image: url(../img/facilities/bg_facilities.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: auto;
  border-radius: 1.875rem;
}
@media screen and (max-width: 767px) {
  .l-bg-wrapper {
    border-radius: 0;
    background-image: url(../img/background-sp.png);
    width: 100vw;
    margin: 0 calc(50% - 50vw);
  }
}

.l-bg {
  position: relative;
  z-index: 1;
  margin-top: 7.7777777778vw;
  position: relative;
}
@media screen and (max-width: 767px) {
  .l-bg {
    margin-top: 14.9333333333vw;
  }
}

.l-bg::before {
  content: "";
  display: block;
  width: 100%;
  background-image: url(../img/bg_sub.png);
  background-position: top;
  background-repeat: no-repeat;
  position: absolute;
  top: -7.7083333333vw;
  left: 0;
  z-index: -1;
}
@media screen and (max-width: 767px) {
  .l-bg::before {
    background-image: url(../img/bg_sub-sp.png);
  }
}

.l-header {
  position: fixed;
  z-index: 900;
  top: 0;
  right: 0;
  left: 0;
}

.l-inner {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  max-width: 71.875rem;
  padding-right: 1.5625rem;
  padding-left: 1.5625rem;
}
@media screen and (max-width: 767px) {
  .l-inner {
    padding-right: 4vw;
    padding-left: 4vw;
  }
}

.l-main {
  width: 100%;
  padding-top: 6.5rem;
  background-color: #fff;
}
@media screen and (max-width: 767px) {
  .l-main {
    padding-top: 12.5333333333vw;
  }
}

.l-page-top {
  position: fixed;
  right: 0.9375rem;
  bottom: 0.9375rem;
  z-index: 100;
}

.c-btn {
  background-color: #151515;
  max-width: 13.875rem;
  width: 100%;
  min-height: 2.875rem;
  padding: 0.625rem 1.25rem 0.625rem 1.875rem;
  border-radius: 1.4375rem;
  color: #fff;
  font-size: max(1rem, 10px);
  letter-spacing: 0.04em;
  display: flex;
  align-items: center;
  position: relative;
  font-weight: 700;
  transition: all 0.3s ease;
}
@media screen and (max-width: 767px) {
  .c-btn {
    max-width: 45.3333333333vw;
    min-height: 10.4vw;
    padding: 2vw 3.0666666667vw 2vw 5.3333333333vw;
    font-size: 2.6666666667vw;
    border-radius: 5.3333333333vw;
  }
}

.c-btn::after {
  content: "";
  display: block;
  width: 0.8125rem;
  height: 0.8125rem;
  background-image: url(../img/icon/icon_arrow-wh.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  right: 1.25rem;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s ease-in-out;
}
@media screen and (max-width: 767px) {
  .c-btn::after {
    width: 2.8vw;
    height: 2.8vw;
    right: 3.0666666667vw;
  }
}

@media (any-hover: hover) {
  .c-btn:hover {
    scale: 1.025;
    background-color: #FF5362;
  }
  .c-btn:hover::after {
    scale: 1.025;
  }
}
.c-btn.c-btn--pink {
  background-color: #FF5362;
  color: #fff;
}

.c-btn.c-btn--red {
  background-color: #e60013;
  color: #fff;
}

@media (any-hover: hover) {
  .c-btn.c-btn--red:hover {
    background-color: #151515;
  }
}
.c-card-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.125rem 2.875rem;
}
@media screen and (max-width: 767px) {
  .c-card-list {
    grid-template-columns: repeat(2, 1fr);
    gap: 9.0666666667vw 4vw;
  }
}

.c-card-list__item-img {
  aspect-ratio: 280/200;
  border-radius: 1.25rem;
  object-fit: contain;
}
@media screen and (max-width: 767px) {
  .c-card-list__item-img {
    aspect-ratio: 330/236;
  }
}

.c-card-list__item-ttl {
  font-size: max(1rem, 10px);
  font-weight: 700;
  line-height: 1.875;
  letter-spacing: 0.09em;
  margin-top: 0.625rem;
}
@media screen and (max-width: 767px) {
  .c-card-list__item-ttl {
    font-size: 2.9333333333vw;
    line-height: 1.5454545455;
    margin-top: 2.1333333333vw;
  }
}

.c-card__img-wrapper {
  overflow: hidden;
  border-radius: 1.25rem;
  border: 0.0625rem solid #151515;
}
@media screen and (max-width: 767px) {
  .c-card__img-wrapper {
    border: 0.2666666667vw solid #151515;
  }
}

.c-card__img-wrapper img {
  width: 100%;
  transition: scale 0.8s cubic-bezier(0.09, 0.43, 0.1, 0.79);
  scale: 1;
}

@media (any-hover: hover) {
  .c-card__link:hover .c-card__img-wrapper img {
    scale: 1.1;
  }
}
.c-open-btn {
  width: 14px;
  height: 14px;
  position: relative;
  cursor: pointer;
}

.c-open-btn span {
  background: #fff;
  display: block;
  height: 2px;
  transform: translateX(-50%);
  width: 14px;
  transition: 0.4s;
  position: absolute;
  left: 50%;
}

.c-open-btn span:nth-of-type(1) {
  transform: translateX(-50%) rotate(-90deg);
}

.c-open-btn span:nth-of-type(2) {
  transform: translateX(-50%) rotate(0deg);
}

.c-open-btn.c-open-btn--red {
  background: #ff5362;
  width: 1.8125rem;
  height: 1.8125rem;
}
@media screen and (max-width: 767px) {
  .c-open-btn.c-open-btn--red {
    width: 8.1333333333vw;
    height: 8.1333333333vw;
    background-color: #e60013;
  }
}

.c-open-btn.c-open-btn--red span {
  width: 10px;
  height: 2px;
}
@media screen and (max-width: 767px) {
  .c-open-btn.c-open-btn--red span {
    width: 2.8vw;
    height: 0.4vw;
  }
}

/*rotate*/
.js-rotate.is-active span:nth-of-type(1) {
  transform: translateX(-50%) rotate(-180deg);
}

.js-rotate.is-active span:nth-of-type(2) {
  transform: translateX(-50%) rotate(0deg);
}

.c-page-top {
  background-color: rgb(180, 214, 182);
  width: 6.25rem;
  height: 6.25rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.25rem;
  text-align: center;
  color: #fff;
}

.c-sec-ttl {
  font-size: 2.875rem;
  font-family: "Helvetica", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, sans-serif;
  font-weight: 700;
  color: #e60013;
  line-height: 1;
}
@media screen and (max-width: 767px) {
  .c-sec-ttl {
    font-size: 7.4666666667vw;
  }
}

.c-sec-ttl::after {
  content: attr(data-sub);
  display: block;
  font-size: 1rem;
  font-weight: 700;
  color: #151515;
  font-family: "source-han-sans-japanese", "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, sans-serif;
  margin-top: 0.625rem;
}
@media screen and (max-width: 767px) {
  .c-sec-ttl::after {
    font-size: 2.9333333333vw;
    margin-top: 0;
  }
}

.c-sec-ttl.c-sec-ttl--head {
  font-size: 4.375rem;
}
@media screen and (max-width: 767px) {
  .c-sec-ttl.c-sec-ttl--head {
    font-size: 7.4666666667vw;
  }
}

.c-sec-ttl.c-sec-ttl--head::after {
  font-size: 1.5rem;
}
@media screen and (max-width: 767px) {
  .c-sec-ttl.c-sec-ttl--head::after {
    font-size: 2.9333333333vw;
  }
}

.c-sec-ttl.c-sec-ttl--small {
  font-size: max(1.625rem, 10px);
  line-height: 1.4230769231;
  font-weight: 700;
}
@media screen and (max-width: 767px) {
  .c-sec-ttl.c-sec-ttl--small {
    font-size: 5.6vw;
    line-height: 1.4523809524;
    letter-spacing: 0.11em;
  }
}

.c-sec-ttl.c-sec-ttl--small::after {
  font-size: max(1rem, 10px);
  letter-spacing: 0.08em;
  margin-top: 0;
}
@media screen and (max-width: 767px) {
  .c-sec-ttl.c-sec-ttl--small::after {
    font-size: 3.4666666667vw;
    letter-spacing: 0.09em;
    margin-top: -1.3333333333vw;
  }
}

.c-sec-ttl.c-sec-ttl--tiny {
  font-size: max(1rem, 10px);
}
@media screen and (max-width: 767px) {
  .c-sec-ttl.c-sec-ttl--tiny {
    font-size: 5.6vw;
    letter-spacing: 0.06em;
  }
}

.c-sec-ttl.c-sec-ttl--tiny::after {
  font-family: "source-han-sans-japanese", "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, sans-serif;
  font-size: max(1rem, 10px);
  letter-spacing: 0.12em;
  margin-top: 0;
}
@media screen and (max-width: 767px) {
  .c-sec-ttl.c-sec-ttl--tiny::after {
    font-size: 3.4666666667vw;
    letter-spacing: 0.06em;
    margin-top: 0.9333333333vw;
  }
}

.c-sub-ttl {
  font-size: 1.625rem;
  font-weight: 700;
  color: #e60013;
  font-family: "Helvetica", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, sans-serif;
}
@media screen and (max-width: 767px) {
  .c-sub-ttl {
    font-size: 3.7333333333vw;
  }
}

.c-sub-ttl span {
  font-size: max(1rem, 10px);
  font-weight: 700;
  color: #151515;
  display: block;
  margin-top: -0.1875rem;
  font-family: "source-han-sans-japanese", "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, sans-serif;
}
@media screen and (max-width: 767px) {
  .c-sub-ttl span {
    margin-top: -0.6666666667vw;
    font-size: 2.8vw;
    line-height: 1.2;
  }
}

.c-tel-btn {
  max-width: 36rem;
  width: 100%;
  border-radius: 1.25rem;
  min-height: 6.875rem;
  padding: 1.6875rem 1.875rem 1.6875rem 1.875rem;
  display: block;
  background: #f0f0f0;
  margin-inline: auto;
}
@media screen and (max-width: 767px) {
  .c-tel-btn {
    max-width: unset;
    padding: 3.8666666667vw 4vw 4vw 4vw;
    min-height: 22.9333333333vw;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}

.c-tel-btn__wrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
  align-items: baseline;
  margin-left: 2.125rem;
}
@media screen and (max-width: 767px) {
  .c-tel-btn__wrapper {
    gap: 2.9333333333vw;
    margin-left: 4.9333333333vw;
  }
}

.c-tel-btn__small {
  font-size: 2rem;
  font-weight: 500;
  color: #a2a2a2;
}
@media screen and (max-width: 767px) {
  .c-tel-btn__small {
    font-size: 4.2666666667vw;
  }
}

.c-tel-btn__num {
  font-size: 3.25rem;
  font-weight: 700;
  letter-spacing: 0.02em;
}
@media screen and (max-width: 767px) {
  .c-tel-btn__num {
    font-size: 6.9333333333vw;
  }
}

.c-tel-btn__info {
  width: 100%;
  text-align: center;
  font-size: 1.25rem;
  letter-spacing: 0.02em;
  margin-top: 0.3125rem;
  line-height: 1.45;
}
@media screen and (max-width: 767px) {
  .c-tel-btn__info {
    font-size: 2.6666666667vw;
  }
}

.c-ttl {
  font-size: 1.375rem;
  font-weight: 700;
  letter-spacing: 0.08em;
  line-height: 1.8181818182;
}
@media screen and (max-width: 767px) {
  .c-ttl {
    font-size: 3.7333333333vw;
    line-height: 1.2142857143;
    letter-spacing: 0.1em;
  }
}

.c-wide-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2.1875rem;
  background: #ff5362;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
  padding: 1.6875rem 2rem;
  gap: 0.875rem;
  letter-spacing: 0.04em;
  position: relative;
}
@media screen and (max-width: 767px) {
  .c-wide-btn {
    max-width: unset;
    padding: 4.9333333333vw 2.1333333333vw 4.9333333333vw 12vw;
    gap: 2.8vw;
    border-radius: 6.6666666667vw;
    justify-content: flex-start;
    position: relative;
    z-index: 1;
  }
}

.c-wide-btn::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  right: 1.9375rem;
  transform: translateY(-50%);
  width: 0.875rem;
  height: 0.875rem;
  background-image: url(../img/icon/icon_plus.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 5;
}
@media screen and (max-width: 767px) {
  .c-wide-btn::after {
    width: 3.4666666667vw;
    height: 3.4666666667vw;
    right: 5.6vw;
  }
}

.c-wide-btn__icon {
  width: 0.16875rem;
  height: 0.945625rem;
}
@media screen and (max-width: 767px) {
  .c-wide-btn__icon {
    width: 0.628vw;
    height: 3.52vw;
  }
}

.c-wide-btn__icon svg {
  width: 100%;
  height: 100%;
}

.c-wide-btn__ttl {
  letter-spacing: 0.04em;
  font-size: var(--fz16);
  font-weight: 700;
  font-family: "Helvetica", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, sans-serif;
  margin-right: 0.4375rem;
  display: inline-block;
}
@media screen and (max-width: 767px) {
  .c-wide-btn__ttl {
    letter-spacing: 0.1em;
    font-size: 3.4666666667vw;
    margin-right: 0;
  }
}

@media (any-hover: hover) {
  .c-wide-btn:hover {
    scale: 1.025;
  }
}
.p-about {
  background-color: #fff;
  padding-top: 2.5rem;
}
@media screen and (max-width: 767px) {
  .p-about {
    padding-top: 8vw;
    padding-bottom: 5.3333333333vw;
  }
}
@media screen and (max-width: 600px) {
  .p-about {
    padding-bottom: 13.3333333333vw;
  }
}

@media screen and (max-width: 767px) {
  .p-about__bg.l-bg {
    margin-top: 8.1333333333vw;
  }
}

.p-about__bg::before {
  height: 131.5625rem;
  top: -7.1527777778vw;
  background-size: 100% 131.5625rem;
}
@media screen and (max-width: 767px) {
  .p-about__bg::before {
    height: 686.6666666667vw;
    top: 1.7333333333vw;
    background-size: 100% 686.6666666667vw;
  }
}
@media screen and (max-width: 600px) {
  .p-about__bg::before {
    height: 706.6666666667vw;
    top: 0;
    background-size: 100% 693.3333333333vw;
  }
}

.p-about-top-txt {
  position: relative;
  z-index: 1;
  top: -4.6527777778vw;
  font-size: 1.625rem;
  line-height: 1.7692307692;
  font-weight: 700;
  letter-spacing: 0.11em;
}
@media screen and (max-width: 767px) {
  .p-about-top-txt {
    top: -3.8666666667vw;
    letter-spacing: 0.06em;
    font-size: 5.6vw;
    line-height: 1.5714285714;
  }
}

.p-about-top-txt span {
  color: #e60013;
}

.p-about__top-img {
  margin-top: -2.4375rem;
  width: 100%;
  height: auto;
}
@media screen and (max-width: 767px) {
  .p-about__top-img {
    margin-top: 2.6666666667vw;
  }
}

.p-about__top-img img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.p-about__top-wrapper {
  display: flex;
  margin-top: 3.9375rem;
  gap: 4.8125rem;
}
@media screen and (max-width: 767px) {
  .p-about__top-wrapper {
    flex-direction: column;
    margin-top: 6.9333333333vw;
    gap: 5.2vw;
  }
}

.p-about__img {
  max-width: 32.625rem;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .p-about__img {
    max-width: unset;
    width: 100%;
  }
}

.p-about__txt-box {
  max-width: 28.4375rem;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .p-about__txt-box {
    max-width: unset;
    width: 100%;
  }
}

.p-about__txt {
  font-size: max(1rem, 10px);
  line-height: 2.25;
  letter-spacing: 0.09em;
}
@media screen and (max-width: 767px) {
  .p-about__txt {
    font-size: 3.4666666667vw;
    line-height: 2.5384615385;
  }
}

.p-about__txt span {
  color: #e60013;
  font-weight: 700;
}

.p-access {
  background-color: #fff;
  padding-top: 6rem;
  padding-bottom: 7.25rem;
}
@media screen and (max-width: 767px) {
  .p-access {
    padding-top: 12.6666666667vw;
    padding-bottom: 12.5333333333vw;
  }
}

.p-access__ttl::after {
  margin-top: 0;
}

.p-access__map {
  height: 25rem;
  margin-top: 1.625rem;
  border: 0.0625rem solid #151515;
  border-radius: 1.875rem;
  position: relative;
}
@media screen and (max-width: 767px) {
  .p-access__map {
    margin-top: 6vw;
    height: 95.7333333333vw;
  }
}

.p-access__map iframe {
  display: block;
  border-radius: 1.875rem;
  width: 100%;
  height: 100%;
  aspect-ratio: 1100/400;
  pointer-events: none;
}
@media screen and (max-width: 767px) {
  .p-access__map iframe {
    aspect-ratio: 689/718;
  }
}

.p-access__btn {
  position: absolute;
  bottom: 1.0625rem;
  right: 1.3125rem;
  z-index: 100;
  width: 13.875rem;
}
@media screen and (max-width: 767px) {
  .p-access__btn {
    bottom: 2.2666666667vw;
    right: 2.1333333333vw;
    width: 45.3333333333vw;
  }
}

.p-access__btn a {
  position: relative;
  z-index: 100;
  font-weight: 700;
}

.p-access__info {
  margin-top: 1.96875rem;
  display: flex;
  gap: 4.375rem;
}
@media screen and (max-width: 767px) {
  .p-access__info {
    flex-direction: column;
    margin-top: 6.7866666667vw;
    gap: 3.7333333333vw;
  }
}

.p-access__info-wrapper {
  max-width: 17.75rem;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .p-access__info-wrapper {
    display: contents;
  }
}

.p-access__info-wrapper-item {
  padding-bottom: 0.875rem;
  border-bottom: 0.0625rem dashed #D1D1D1;
}
@media screen and (max-width: 767px) {
  .p-access__info-wrapper-item {
    padding-bottom: 1.2vw;
    border-bottom: 0.2666666667vw dashed #D1D1D1;
  }
}

.p-access__info-wrapper-item + .p-access__info-wrapper-item {
  margin-top: 1.09375rem;
}
@media screen and (max-width: 767px) {
  .p-access__info-wrapper-item + .p-access__info-wrapper-item {
    margin-top: 0;
  }
}

.p-access__info-wrapper-item dt {
  color: #e60013;
  font-weight: 700;
  letter-spacing: 0.06em;
  font-size: max(1rem, 10px);
}
@media screen and (max-width: 767px) {
  .p-access__info-wrapper-item dt {
    font-size: 3.2vw;
  }
}

.p-access__info-wrapper-item dd {
  font-weight: 500;
  letter-spacing: 0.06em;
  font-size: max(1rem, 10px);
  margin-top: 0.625rem;
}
@media screen and (max-width: 767px) {
  .p-access__info-wrapper-item dd {
    font-size: 3.2vw;
    margin-top: 1.0666666667vw;
    white-space: nowrap;
  }
}

.p-access__info-wrapper-item dd > a {
  pointer-events: none;
}
@media screen and (max-width: 767px) {
  .p-access__info-wrapper-item dd > a {
    pointer-events: auto;
  }
}

/*SP並び順*/
@media screen and (max-width: 767px) {
  .p-access__info-wrapper-item--address {
    order: 1;
  }
}

@media screen and (max-width: 767px) {
  .p-access__info-wrapper-item--tel {
    order: 4;
  }
}

@media screen and (max-width: 767px) {
  .p-access__info-wrapper-item--open {
    order: 2;
  }
}

@media screen and (max-width: 767px) {
  .p-access__info-wrapper-item--holiday {
    order: 5;
  }
}

@media screen and (max-width: 767px) {
  .p-access__info-wrapper-item--traffic {
    order: 6;
  }
}

@media screen and (max-width: 767px) {
  .p-access__info-wrapper-item--parking {
    order: 3;
  }
}

.p-application {
  background-color: #fff;
  padding-top: 2.1875rem;
  padding-bottom: 10.75rem;
}
@media screen and (max-width: 767px) {
  .p-application {
    padding-top: 5.7333333333vw;
    padding-bottom: 20.6666666667vw;
  }
}

.p-application__bg {
  background-position: top left;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  padding-bottom: 4.6875rem;
  position: relative;
  background-color: #F9E9EA;
  margin-top: 6.25rem;
}
@media screen and (max-width: 767px) {
  .p-application__bg {
    margin-top: 25.0666666667vw;
    padding-bottom: 12.5333333333vw;
  }
}

.p-application__bg::before,
.p-application__bg::after {
  content: "";
  display: block;
  width: 100%;
  height: 7rem;
  position: absolute;
  left: 0;
  background-size: 100% 100%;
  background-position: top left;
  background-repeat: no-repeat;
}
@media screen and (max-width: 767px) {
  .p-application__bg::before,
  .p-application__bg::after {
    height: 15.6vw;
  }
}

.p-application__bg::before {
  top: -5.8125rem;
  background-image: url(../img/bg_top.png);
}
@media screen and (max-width: 767px) {
  .p-application__bg::before {
    top: -15.4666666667vw;
    background-image: url(../img/bg_top-sp.png);
  }
}

.p-application__bg::after {
  bottom: -6.875rem;
  background-position: bottom left;
  background-image: url(../img/bg_bottom.png);
}
@media screen and (max-width: 767px) {
  .p-application__bg::after {
    background-image: url(../img/bg_bottom-sp.png);
    bottom: -14.6666666667vw;
  }
}

.p-application__container {
  background-color: #fff;
  padding: 3.75rem 3.5rem 5.125rem 3.6875rem;
  border-radius: 2.25rem;
  margin-top: -1.125rem;
}
@media screen and (max-width: 767px) {
  .p-application__container {
    margin-top: -10.9333333333vw;
    padding: 9.0666666667vw 4.6vw 12vw 4.7333333333vw;
  }
}

.p-application__wrapper {
  max-width: 61.625rem;
  width: 100%;
}

.p-application__top-ttl.c-sec-ttl.c-sec-ttl--small {
  position: relative;
  top: -3.375rem;
}
@media screen and (max-width: 767px) {
  .p-application__top-ttl.c-sec-ttl.c-sec-ttl--small {
    top: -19.7333333333vw;
    letter-spacing: 0.06em;
  }
}

.p-application__top-ttl.c-sec-ttl.c-sec-ttl--small::after {
  font-size: 1.375rem;
  margin-top: -0.25rem;
  letter-spacing: 0.12em;
}
@media screen and (max-width: 767px) {
  .p-application__top-ttl.c-sec-ttl.c-sec-ttl--small::after {
    font-size: 3.4666666667vw;
    margin-top: -0.5333333333vw;
    letter-spacing: 0.06em;
  }
}

.p-application__sub-ttl.c-sec-ttl.c-sec-ttl--small {
  text-align: center;
}
@media screen and (max-width: 767px) {
  .p-application__sub-ttl.c-sec-ttl.c-sec-ttl--small {
    font-size: 5.6vw;
    letter-spacing: 0.06em;
  }
}

@media screen and (max-width: 767px) {
  .p-application__sub-ttl.c-sec-ttl.c-sec-ttl--small::after {
    font-size: 3.4666666667vw;
    margin-top: -0.5333333333vw;
    letter-spacing: 0.06em;
  }
}

.p-application__note-list {
  margin: 2.3125rem auto 0;
  max-width: 59.875rem;
  width: 100%;
  margin-left: auto;
}
@media screen and (max-width: 767px) {
  .p-application__note-list {
    margin: 9.3333333333vw auto 0;
  }
}

.p-application__note-item {
  font-size: var(--fz16);
  letter-spacing: 0.06em;
  line-height: 1.5;
  font-weight: 500;
  padding-bottom: 0.84375rem;
  border-bottom: 0.0625rem dashed #d1d1d1;
}
@media screen and (max-width: 767px) {
  .p-application__note-item {
    padding-bottom: 5.6vw;
    font-size: 3.2vw;
    line-height: 1.4583333333;
    border-bottom: 0.2666666667vw dashed #d1d1d1;
  }
}

.p-application__note-item + .p-application__note-item {
  margin-top: 1.375rem;
}
@media screen and (max-width: 767px) {
  .p-application__note-item + .p-application__note-item {
    margin-top: 5.3333333333vw;
  }
}

.p-application__note-item::before {
  content: "・";
  display: inline-block;
}

.p-application__note-txt {
  font-size: var(--fz16);
  letter-spacing: 0.04em;
  line-height: 1.5;
  font-weight: 500;
  margin-top: 0.84375rem;
  text-align: center;
  margin-top: 2.25rem;
}
@media screen and (max-width: 767px) {
  .p-application__note-txt {
    font-size: 3.2vw;
    letter-spacing: 0.06em;
    line-height: 1.9166666667;
    margin-top: 6.2666666667vw;
    text-align: left;
  }
}

.p-application__note-txt-link {
  color: #e60013;
  transition: color 0.3s;
}

.p-application__note-txt-link-ttl {
  text-decoration: underline;
}

@media (any-hover: hover) {
  .p-application__note-txt-link:hover {
    color: #ff5362;
  }
}
.p-application__check-wrapper {
  margin-top: 1.375rem;
  background: #ff5362;
  border-radius: 0.625rem;
  color: #fff;
  font-size: 1.375rem;
  letter-spacing: 0.04em;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  cursor: pointer;
}
@media screen and (max-width: 767px) {
  .p-application__check-wrapper {
    margin-top: 6.9333333333vw;
    font-size: 3.4666666667vw;
    letter-spacing: 0.1em;
    border-radius: 2.6666666667vw;
  }
}

.p-application__check-btn {
  appearance: none;
  border: 1px solid #d1d1d1;
  background-color: #fff;
  border-radius: 0.25rem;
  width: 1.875rem;
  height: 1.875rem;
  position: relative;
  flex-shrink: 0;
  margin: 0;
  margin-right: 1.1875rem;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
}
@media screen and (max-width: 767px) {
  .p-application__check-btn {
    width: 6.4vw;
    height: 6.4vw;
    margin-right: 2.5333333333vw;
  }
}

.p-application__check-btn:checked {
  background-color: #FFA2AA;
  border: 1px solid #FFA2AA;
}

.p-application__check-btn:checked::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1rem;
  height: 0.5rem;
  border-left: 0.1875rem solid #fff;
  border-bottom: 0.1875rem solid #fff;
  transform: translate(-50%, -50%) rotate(-50deg);
}
@media screen and (max-width: 767px) {
  .p-application__check-btn:checked::before {
    width: 3.2vw;
    height: 1.8666666667vw;
    border-left: 0.6666666667vw solid #fff;
    border-bottom: 0.6666666667vw solid #fff;
  }
}

.p-application__check-label {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  text-align: center;
  cursor: pointer;
  width: 100%;
  min-height: 5.625rem;
}
@media screen and (max-width: 767px) {
  .p-application__check-label {
    min-height: 13.3333333333vw;
    justify-content: flex-start;
    padding-left: 12vw;
    gap: 1.3333333333vw;
  }
}

.p-application__check-txt {
  transition: all 0.3s ease-in-out;
}

@media (any-hover: hover) {
  .p-application__check-label:hover .p-application__check-txt {
    opacity: 0.8;
  }
}
.p-application__list {
  margin-top: 2.1875rem;
  pointer-events: none; /* デフォルトはクリック不可 */
}
@media screen and (max-width: 767px) {
  .p-application__list {
    margin-top: 9.3333333333vw;
  }
}

.p-application__item + .p-application__item {
  margin-top: 1.5rem;
}
@media screen and (max-width: 767px) {
  .p-application__item + .p-application__item {
    margin-top: 5.3333333333vw;
  }
}

.p-application__link {
  background-color: rgba(77, 77, 77, 0.4);
  color: #fff;
  font-size: var(--fz18);
  letter-spacing: 0.04em;
  font-weight: 700;
  line-height: 1;
  display: flex;
  align-items: center;
  padding: 1.1875rem 2.25rem;
  position: relative;
  border-radius: 0.625rem;
  transition: background-color 0.3s, opacity 0.3s;
}
@media screen and (max-width: 767px) {
  .p-application__link {
    border-radius: 1.3333333333vw;
    font-size: 2.9333333333vw;
    padding: 4.9333333333vw 5.3333333333vw;
  }
}

.p-application__icon {
  position: absolute;
  top: 50%;
  right: 2.0625rem;
  transform: translateY(-50%);
  width: 0.9375rem;
}
@media screen and (max-width: 767px) {
  .p-application__icon {
    width: 2.6666666667vw;
    right: 4.4vw;
  }
}

.p-application__icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.p-application__contact-area {
  margin-top: 2.5rem;
}
@media screen and (max-width: 767px) {
  .p-application__contact-area {
    margin-top: 12.2666666667vw;
  }
}

@media (any-hover: hover) {
  .p-application__link:hover {
    opacity: 0.4;
  }
}
.p-arena {
  background-color: #fff;
  margin-top: 3.3125rem;
}
@media screen and (max-width: 767px) {
  .p-arena {
    margin-top: 11.6vw;
  }
}

.p-arena__bg {
  border-radius: 1.875rem;
  padding: 2.5rem 5.25rem 5.625rem;
  background-position: top left;
}
@media screen and (max-width: 767px) {
  .p-arena__bg {
    border-radius: 0;
    padding: 11.2vw 4vw 14.4vw;
  }
}

.p-arena__list {
  margin-top: 1.375rem;
}
@media screen and (max-width: 767px) {
  .p-arena__list {
    margin-top: 6.2666666667vw;
  }
}

.p-charge-info.p-charge__info--second {
  margin-top: 5rem;
  max-width: 60.5rem;
  width: 100%;
  margin-inline: auto;
}
@media screen and (max-width: 767px) {
  .p-charge-info.p-charge__info--second {
    margin-top: 16vw;
  }
}

.p-charge-info.p-charge-info--rental {
  margin-top: 0;
}

.p-charge-info__link {
  color: #e60013;
  font-weight: 700;
  transition: opacity 0.3s ease-in-out;
}
@media screen and (max-width: 767px) {
  .p-charge-info__link {
    font-weight: 500;
  }
}

@media (any-hover: hover) {
  .p-charge-info__link:hover {
    opacity: 0.7;
  }
}
.p-charge-info__container {
  margin-top: 3.125rem;
}
@media screen and (max-width: 767px) {
  .p-charge-info__container {
    margin-top: 8vw;
  }
}

.p-charge__info .p-charge-info__container {
  max-width: 60.5rem;
  width: 100%;
  margin-left: auto;
  margin-top: 1.625rem;
}
@media screen and (max-width: 767px) {
  .p-charge__info .p-charge-info__container {
    margin-top: 0;
  }
}

/*施設使用料に関する留意事項*/
.p-charge-info__container.p-charge-info__container--second {
  margin-top: 0;
}

.p-charge-info__container.p-charge-info__container--second .p-charge-info__list:first-child {
  margin-top: 1.1875rem;
  padding-left: 0.4em;
}
@media screen and (max-width: 767px) {
  .p-charge-info__container.p-charge-info__container--second .p-charge-info__list:first-child {
    margin-top: 5.6vw;
  }
}

.p-charge-info.p-charge-info--rental .p-charge-info__container {
  margin-top: 2.125rem;
}
@media screen and (max-width: 767px) {
  .p-charge-info.p-charge-info--rental .p-charge-info__container {
    margin-top: 8vw;
  }
}

.p-charge-info__list {
  padding-bottom: 0.8125rem;
  border-bottom: 1px dashed #D1D1D1;
}
@media screen and (max-width: 767px) {
  .p-charge-info__list {
    padding-bottom: 5.3333333333vw;
    border-bottom: 0.2666666667vw dashed #D1D1D1;
  }
}

.p-charge-info__list:first-child {
  margin-top: 1.625rem;
}
@media screen and (max-width: 767px) {
  .p-charge-info__list:first-child {
    margin-top: 6.1333333333vw;
  }
}

.p-charge-info__list + .p-charge-info__list {
  margin-top: 1.5rem;
}
@media screen and (max-width: 767px) {
  .p-charge-info__list + .p-charge-info__list {
    margin-top: 5.6vw;
  }
}

.p-charge-info__ttl {
  color: #e60013;
  font-size: var(--fz16);
  font-weight: 700;
  letter-spacing: 0.06em;
  line-height: 1.875;
}
@media screen and (max-width: 767px) {
  .p-charge-info__ttl {
    font-size: 3.2vw;
    line-height: 1.4583333333;
  }
}

.p-charge-info__txt {
  font-weight: 500;
  font-size: var(--fz16);
  line-height: 1.5;
  letter-spacing: 0.06em;
  margin-top: 0.625rem;
}
@media screen and (max-width: 767px) {
  .p-charge-info__txt {
    font-size: 3.2vw;
    line-height: 1.4583333333;
    margin-top: 2.4vw;
  }
}

.p-charge-info__bold {
  font-weight: 700;
}
@media screen and (max-width: 767px) {
  .p-charge-info__bold {
    font-weight: 500;
  }
}

.p-charge-info__txt.p-charge-info__txt--space {
  max-width: 57.8125rem;
  margin-left: auto;
  margin-right: 0.1875rem;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .p-charge-info__txt.p-charge-info__txt--space {
    padding-left: 0;
  }
}

.p-charge-info__note {
  margin-top: 0.875rem;
  font-weight: 400;
  font-size: var(--fz16);
  line-height: 1.5;
  letter-spacing: 0.06em;
}
@media screen and (max-width: 767px) {
  .p-charge-info__note {
    font-size: 3.2vw;
    line-height: 1.9166666667;
    margin-top: 4.4vw;
  }
}

.p-charge-info__item {
  padding-bottom: 0.8125rem;
  border-bottom: 1px dashed #D1D1D1;
}
@media screen and (max-width: 767px) {
  .p-charge-info__item {
    padding-bottom: 4vw;
    border-bottom: 0.2666666667vw dashed #D1D1D1;
  }
}

.p-charge-info__heading {
  position: relative;
}

.p-charge-info__item-ttl {
  font-size: var(--fz16);
  line-height: 1.5;
  letter-spacing: 0.06em;
  color: #e60013;
  font-weight: 700;
  margin-top: 2rem;
  cursor: pointer;
  padding-left: 0.4em;
}
@media screen and (max-width: 767px) {
  .p-charge-info__item-ttl {
    font-size: 3.2vw;
    line-height: 1.4583333333;
    margin-top: 13.3333333333vw;
    padding-left: 0;
  }
}

.p-charge-info__item-btn {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.p-charge-info__note-list {
  color: #4d4d4d;
  margin-top: 1.6875rem;
}
@media screen and (max-width: 767px) {
  .p-charge-info__note-list {
    margin-top: 10.1333333333vw;
  }
}

.p-charge-info__note-item {
  padding-left: 2.8125rem;
  font-size: var(--fz16);
  line-height: 1.5;
  letter-spacing: 0.06em;
  font-weight: 500;
  counter-increment: number;
  display: flex;
}
@media screen and (max-width: 767px) {
  .p-charge-info__note-item {
    padding-left: 0;
    font-size: 3.2vw;
    line-height: 1.4583333333;
    font-weight: 400;
  }
}

.p-charge-info__note-item.p-charge-info__note-item--wide {
  max-width: 57.8125rem;
  flex-wrap: wrap;
}

.p-charge-info__note-item::before {
  content: "(" counter(number) ")";
  margin-right: 2.1875rem;
  display: inline-block;
}
@media screen and (max-width: 767px) {
  .p-charge-info__note-item::before {
    margin-right: 4vw;
  }
}

.p-charge-info__note-item + .p-charge-info__note-item {
  margin-top: 0.5rem;
}
@media screen and (max-width: 767px) {
  .p-charge-info__note-item + .p-charge-info__note-item {
    margin-top: 4.6666666667vw;
  }
}

.p-charge-info__sub-list {
  width: 100%;
  margin-top: 0.5rem;
}
@media screen and (max-width: 767px) {
  .p-charge-info__sub-list {
    max-width: 80vw;
    margin-top: 5.8666666667vw;
    margin-left: auto;
  }
}

.p-charge-info__sub-item {
  padding-left: 3.5rem;
  font-size: var(--fz16);
  line-height: 1.5;
  letter-spacing: 0.06em;
  font-weight: 500;
  counter-increment: sub-number;
  display: flex;
  max-width: 52.875rem;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .p-charge-info__sub-item {
    padding-left: 0;
    font-size: 3.2vw;
    line-height: 1.4583333333;
    font-weight: 400;
  }
}

.p-charge-info__sub-item + .p-charge-info__sub-item {
  margin-top: 0.1875rem;
}
@media screen and (max-width: 767px) {
  .p-charge-info__sub-item + .p-charge-info__sub-item {
    margin-top: 4.1333333333vw;
  }
}

.p-charge-info__sub-item::before {
  content: "(" counter(sub-number, lower-roman) ")";
  margin-right: 1.125rem;
}
@media screen and (max-width: 767px) {
  .p-charge-info__sub-item::before {
    margin-right: 3.4666666667vw;
  }
}

.p-charge-info__sub-item:last-child::before {
  margin-right: 0.8125rem;
}
@media screen and (max-width: 767px) {
  .p-charge-info__sub-item:last-child::before {
    margin-right: 1.6vw;
  }
}

/*施設使用料に関する留意事項*/
.p-charge-info.p-charge__info--second .p-charge-info__txt {
  color: #4d4d4d;
  margin-top: 0.25rem;
}
@media screen and (max-width: 767px) {
  .p-charge-info.p-charge__info--second .p-charge-info__txt {
    font-weight: 400;
    margin-top: 2vw;
  }
}

.p-charge-info__note-list {
  padding-left: 0.4em;
}

.p-charge-info__container.p-charge-info__container--second .p-charge-info__list {
  padding-left: 0.4em;
}
@media screen and (max-width: 767px) {
  .p-charge-info__container.p-charge-info__container--second .p-charge-info__list {
    padding-left: 0;
  }
}

.p-charge-info.p-charge__info--second .p-charge-info__list + .p-charge-info__list {
  margin-top: 0.875rem;
}
@media screen and (max-width: 767px) {
  .p-charge-info.p-charge__info--second .p-charge-info__list + .p-charge-info__list {
    margin-top: 5.3333333333vw;
  }
}

.p-charge {
  background-color: #fff;
  padding-top: 2.25rem;
  padding-bottom: 10.75rem;
}
@media screen and (max-width: 767px) {
  .p-charge {
    padding-top: 8vw;
    padding-bottom: 11.3333333333vw;
  }
}

.p-charge__bg {
  background-position: top left;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  padding-bottom: 4.6875rem;
  position: relative;
  background-color: #F9E9EA;
  margin-top: 6.25rem;
}
@media screen and (max-width: 767px) {
  .p-charge__bg {
    margin-top: 25.0666666667vw;
    padding-bottom: 12.6666666667vw;
  }
}

.p-charge__bg::before,
.p-charge__bg::after {
  content: "";
  display: block;
  width: 100%;
  height: 7rem;
  position: absolute;
  left: 0;
  background-size: 100% 100%;
  background-position: top left;
  background-repeat: no-repeat;
}
@media screen and (max-width: 767px) {
  .p-charge__bg::before,
  .p-charge__bg::after {
    height: 15.6vw;
  }
}

.p-charge__bg::before {
  top: -5.8125rem;
  background-image: url(../img/bg_top.png);
}
@media screen and (max-width: 767px) {
  .p-charge__bg::before {
    top: -15.4666666667vw;
    background-image: url(../img/bg_top-sp.png);
  }
}

.p-charge__bg::after {
  bottom: -6.875rem;
  background-position: bottom left;
  background-image: url(../img/bg_bottom.png);
}
@media screen and (max-width: 767px) {
  .p-charge__bg::after {
    background-image: url(../img/bg_bottom-sp.png);
    bottom: -14.6666666667vw;
  }
}

.p-charge__txt {
  font-weight: 500;
  font-size: var(--fz16);
  letter-spacing: 0.09em;
  max-width: 60rem;
  width: 100%;
  position: relative;
  top: -3.75rem;
  margin-inline: auto;
}
@media screen and (max-width: 767px) {
  .p-charge__txt {
    font-weight: 400;
    max-width: unset;
    width: 100%;
    font-size: 3.4666666667vw;
    line-height: 2.5384615385;
    top: -20vw;
  }
}

.p-charge__txt-link {
  color: #029595;
  font-weight: 700;
  transition: opacity 0.3s ease-in-out;
}
@media screen and (max-width: 767px) {
  .p-charge__txt-link {
    font-size: 3.4666666667vw;
    line-height: 2.5384615385;
  }
}

.p-charge__txt-link:first-of-type {
  pointer-events: none;
  cursor: default;
}
@media screen and (max-width: 767px) {
  .p-charge__txt-link:first-of-type {
    pointer-events: auto;
  }
}

@media (any-hover: hover) {
  .p-charge__txt-link:last-of-type:hover {
    opacity: 0.7;
  }
}
.p-charge__container {
  background-color: #fff;
  border-radius: 2.25rem;
  padding: 3.5rem 3.5625rem;
  max-width: 68.75rem;
  width: 100%;
  margin: 0 auto;
}
@media screen and (max-width: 767px) {
  .p-charge__container {
    width: 100vw;
    margin: -16.5333333333vw calc(50% - 50vw) 0;
    border-radius: 4vw;
    padding: 9.6vw 4vw 12vw;
  }
}

.p-charge__container.p-charge__container--rental {
  padding-top: 2.875rem;
  margin-top: 3.8125rem;
  padding-bottom: 4.375rem;
}
@media screen and (max-width: 767px) {
  .p-charge__container.p-charge__container--rental {
    padding-top: 17.3333333333vw;
    margin-top: 17.3333333333vw;
    padding-bottom: 12vw;
  }
}

.p-charge__heading {
  text-align: center;
}
@media screen and (max-width: 767px) {
  .p-charge__heading {
    text-align: left;
  }
}

.p-charge__img-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2.5625rem;
  margin-top: 3.5rem;
}
@media screen and (max-width: 767px) {
  .p-charge__img-wrapper {
    margin-top: 6.6666666667vw;
    gap: 2.9333333333vw;
  }
}

.p-charge__top-img img {
  aspect-ratio: 472/300;
  width: 100%;
  object-fit: contain;
}
@media screen and (max-width: 767px) {
  .p-charge__top-img img {
    aspect-ratio: 334/250;
  }
}

.p-charge__info {
  margin-top: 2.1875rem;
}
@media screen and (max-width: 767px) {
  .p-charge__info {
    margin-top: 8vw;
  }
}

.p-charge-info__link-btn {
  margin-top: 0.8125rem;
  transition: all 0.3s ease;
}
@media screen and (max-width: 767px) {
  .p-charge-info__link-btn {
    margin-top: 5.3333333333vw;
  }
}

.p-charge__price {
  margin-top: 4rem;
  max-width: 61.625rem;
  width: 100%;
  margin-inline: auto;
}
@media screen and (max-width: 767px) {
  .p-charge__price {
    margin-top: 12vw;
  }
}

.p-charge__exemption {
  margin-top: 4.625rem;
}
@media screen and (max-width: 767px) {
  .p-charge__exemption {
    margin-top: 12vw;
  }
}

.p-charge__info__heading {
  text-align: center;
}
@media screen and (max-width: 767px) {
  .p-charge__info__heading {
    text-align: left;
  }
}

.p-charge__link-wrapper {
  display: flex;
  gap: 3.75rem;
  margin-top: 3rem;
}
@media screen and (max-width: 767px) {
  .p-charge__link-wrapper {
    margin-top: 12vw;
    flex-direction: column;
    gap: 6.6666666667vw;
  }
}

.p-charge__link-item {
  max-width: 32.5rem;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .p-charge__link-item {
    max-width: unset;
  }
}

.p-charge__btn {
  font-size: 1.625rem;
  font-weight: 700;
  letter-spacing: 0.11em;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.2s ease-in-out;
  width: 100%;
  background: #fff;
  border-radius: 1.25rem;
  border: 0.0625rem solid #151515;
  min-height: 9.875rem;
  padding: 1.875rem 1.875rem 1.875rem 1.875rem;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
@media screen and (max-width: 767px) {
  .p-charge__btn {
    font-size: 4.5333333333vw;
    padding: 9.3333333333vw 4vw 9.3333333333vw 4vw;
    min-height: 27.7333333333vw;
    border-radius: 2.6666666667vw;
  }
}

.p-charge__btn:after {
  content: "";
  display: block;
  background-image: url(../img/icon/icon_arrow-right.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 2.5625rem;
  height: 2.5625rem;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
}
@media screen and (max-width: 767px) {
  .p-charge__btn:after {
    width: 8.8vw;
    height: 8.8vw;
    right: -0.1333333333vw;
    bottom: -0.1333333333vw;
  }
}

.p-charge__btn-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.p-charge__btn-icon svg {
  width: 100%;
  object-fit: contain;
}

.p-charge__btn.p-charge__btn--download .p-charge__btn-icon {
  width: 1.31rem;
  margin-right: 1.25rem;
}
@media screen and (max-width: 767px) {
  .p-charge__btn.p-charge__btn--download .p-charge__btn-icon {
    width: 3.708vw;
    margin-right: 3.5333333333vw;
  }
}

.p-charge__btn.p-charge__btn--external .p-charge__btn-icon {
  width: 1.478125rem;
  margin-right: 1.1875rem;
}
@media screen and (max-width: 767px) {
  .p-charge__btn.p-charge__btn--external .p-charge__btn-icon {
    width: 4.1853333333vw;
    margin-right: 3.36vw;
  }
}

.p-charge__btn-txt--small {
  font-size: var(--fz16);
  letter-spacing: 0.11em;
}
@media screen and (max-width: 767px) {
  .p-charge__btn-txt--small {
    font-size: 2.8vw;
  }
}

.p-charge__btn-icon svg path {
  transition: fill 0.3s ease-in-out;
}

@media (any-hover: hover) {
  .p-charge__btn:hover {
    color: #e60013;
  }
  .p-charge__btn:hover .p-charge__btn-icon svg path {
    fill: #FFA2AA;
  }
}
.p-charge__contact-area {
  margin-top: 2.375rem;
}
@media screen and (max-width: 767px) {
  .p-charge__contact-area {
    margin-top: 5.3333333333vw;
  }
}

.p-common-contact {
  background: #fff;
  border-radius: 2.25rem;
  padding: 2.75rem 1.875rem 3.9375rem 1.875rem;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}
@media screen and (max-width: 767px) {
  .p-common-contact {
    padding: 5.3333333333vw 4.9333333333vw 8.4vw 4.9333333333vw;
  }
}

.p-common-contact__ttl.c-sec-ttl.c-sec-ttl--small {
  text-align: center;
}
@media screen and (max-width: 767px) {
  .p-common-contact__ttl.c-sec-ttl.c-sec-ttl--small {
    letter-spacing: 0.06em;
  }
}

.p-common-contact__wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-top: 2.1875rem;
  gap: 3.75rem;
}
@media screen and (max-width: 767px) {
  .p-common-contact__wrapper {
    margin-top: 4.6666666667vw;
    gap: 3.7333333333vw;
    flex-direction: column;
    align-items: center;
  }
}

.p-common-contact__btn {
  max-width: 24.5rem;
  width: 100%;
  border-radius: 1.25rem;
  min-height: 6.875rem;
  padding: 1.0625rem 0.625rem 1.0625rem 0.625rem;
}
@media screen and (max-width: 767px) {
  .p-common-contact__btn {
    max-width: unset;
    padding: 3.8666666667vw 4vw 4vw 4vw;
    min-height: 22.9333333333vw;
  }
}

.p-common-contact__btn--tel {
  background: #f0f0f0;
  pointer-events: none;
}
@media screen and (max-width: 767px) {
  .p-common-contact__btn--tel {
    pointer-events: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 1.3333333333vw;
  }
}

.p-common-contact__btn-wrapper {
  display: flex;
  align-items: baseline;
  justify-content: center;
  gap: 0.75rem;
}
@media screen and (max-width: 767px) {
  .p-common-contact__btn-wrapper {
    justify-content: flex-start;
    gap: 4vw;
    max-width: 65.3333333333vw;
    width: 100%;
  }
}

.p-common-contact__small {
  font-size: 1.375rem;
  font-weight: 500;
  color: #a2a2a2;
}
@media screen and (max-width: 767px) {
  .p-common-contact__small {
    font-size: 4.2666666667vw;
  }
}

.p-common-contact__num {
  font-size: 2.25rem;
  font-weight: 700;
  letter-spacing: 0.02em;
}
@media screen and (max-width: 767px) {
  .p-common-contact__num {
    font-size: 6.9333333333vw;
  }
}

.p-common-contact__info {
  width: 100%;
  text-align: center;
  font-size: var(--fz14);
  letter-spacing: 0.02em;
  line-height: 1.4285714286;
  white-space: nowrap;
}
@media screen and (max-width: 767px) {
  .p-common-contact__info {
    font-size: 2.6666666667vw;
  }
}

.p-common-contact__btn--form {
  background: #e60013;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.875rem;
  color: #fff;
  border: 1px solid #151515;
  transition: 0.3s ease-in-out;
}
@media screen and (max-width: 767px) {
  .p-common-contact__btn--form {
    gap: 2.6666666667vw;
  }
}

.p-common-contact__btn-icon {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .p-common-contact__btn-icon {
    width: 4.4vw;
  }
}

.p-common-contact__btn-icon svg {
  width: 1.25rem;
}
@media screen and (max-width: 767px) {
  .p-common-contact__btn-icon svg {
    width: 4.4vw;
  }
}

.p-common-contact__txt {
  font-size: 1.375rem;
  font-weight: 700;
  letter-spacing: 0.02em;
}
@media screen and (max-width: 767px) {
  .p-common-contact__txt {
    font-size: 4.8vw;
  }
}

.p-common-contact__btn--form:hover {
  background-color: #ff5362;
  color: #fff;
}

.p-conference-room {
  background-color: #fff;
  padding-top: 3.125rem;
  padding-bottom: 5.625rem;
  position: relative;
  top: -0.0625rem;
}
@media screen and (max-width: 767px) {
  .p-conference-room {
    padding-top: 9.3333333333vw;
    padding-bottom: 11.7333333333vw;
  }
}

.p-conference-room__bg {
  background-position: top left;
  border-radius: 1.875rem;
  padding: 2.375rem 5.25rem;
  min-height: 25.375rem;
}
@media screen and (max-width: 767px) {
  .p-conference-room__bg {
    border-radius: 0;
    padding: 11.0666666667vw 4vw 14.4vw;
  }
}

.p-conference-room__list {
  margin-top: 1.375rem;
}
@media screen and (max-width: 767px) {
  .p-conference-room__list {
    margin-top: 5.6vw;
  }
}

.p-contact {
  background-color: #fff;
  padding-top: 2.5rem;
  padding-bottom: 10.75rem;
}
@media screen and (max-width: 767px) {
  .p-contact {
    padding-top: 8vw;
    padding-bottom: 16vw;
  }
}

.p-contact__bg {
  background-position: top left;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  padding-bottom: 4.375rem;
  position: relative;
  background-color: #F9E9EA;
  margin-top: 6.25rem;
}
@media screen and (max-width: 767px) {
  .p-contact__bg {
    margin-top: 24.9333333333vw;
    padding-bottom: 14.8vw;
  }
}

.p-contact__bg::before,
.p-contact__bg::after {
  content: "";
  display: block;
  width: 100%;
  height: 7rem;
  position: absolute;
  left: 0;
  background-size: 100% 100%;
  background-position: top left;
  background-repeat: no-repeat;
}
@media screen and (max-width: 767px) {
  .p-contact__bg::before,
  .p-contact__bg::after {
    height: 15.6vw;
  }
}

.p-contact__bg::before {
  top: -5.8125rem;
  background-image: url(../img/bg_top.png);
}
@media screen and (max-width: 767px) {
  .p-contact__bg::before {
    top: -15.4666666667vw;
    background-image: url(../img/bg_top-sp.png);
  }
}

.p-contact__bg::after {
  bottom: -6.875rem;
  background-position: bottom left;
  background-image: url(../img/bg_bottom.png);
}
@media screen and (max-width: 767px) {
  .p-contact__bg::after {
    background-image: url(../img/bg_bottom-sp.png);
    bottom: -14.8vw;
  }
}

.p-contact__container {
  background-color: #fff;
  padding: 1.5625rem 3.5rem 2.1875rem 4.5625rem;
  border-radius: 2.25rem;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  margin-top: 0.75rem;
}
@media screen and (max-width: 767px) {
  .p-contact__container {
    margin-top: -15.2vw;
    padding: 6.1333333333vw 4.9333333333vw 8.4vw 4.9333333333vw;
  }
}

.p-contact__top-txt {
  font-size: var(--fz16);
  letter-spacing: 0.09em;
  line-height: 1.875;
  font-weight: 500;
  margin-top: 2.25rem;
  position: relative;
  top: -4.0625rem;
  padding-left: 4.375rem;
}
@media screen and (max-width: 767px) {
  .p-contact__top-txt {
    font-size: 3.4666666667vw;
    line-height: 2.5384615385;
    top: -20.4vw;
    text-align: left;
    padding-left: 0;
  }
}

.p-contact__sub-ttl.c-sec-ttl.c-sec-ttl--small {
  text-align: center;
}
@media screen and (max-width: 767px) {
  .p-contact__sub-ttl.c-sec-ttl.c-sec-ttl--small {
    letter-spacing: 0.06em;
  }
}

.p-contact__tel-btn {
  margin-top: 1.25rem;
}
@media screen and (max-width: 767px) {
  .p-contact__tel-btn {
    margin-top: 4vw;
  }
}

.p-contact__form-container {
  margin-top: 4.4375rem;
  background-color: #fff;
  padding: 1.5rem 3.5rem 2.9375rem 4.5625rem;
  border-radius: 2.25rem;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}
@media screen and (max-width: 767px) {
  .p-contact__form-container {
    margin-top: 7.2vw;
    padding: 9.0666666667vw 4.6666666667vw 11.2vw 4.6666666667vw;
  }
}

.p-contact__form-top-txt {
  font-size: var(--fz16);
  letter-spacing: 0.09em;
  line-height: 1.875;
  font-weight: 500;
  text-align: center;
  margin-top: 1.625rem;
}
@media screen and (max-width: 767px) {
  .p-contact__form-top-txt {
    margin-top: 9.3333333333vw;
    font-size: 3.2vw;
    letter-spacing: 0.06em;
    text-align: left;
    line-height: 1.4583333333;
  }
}

.p-contact__wrapper {
  max-width: 50rem;
  width: 100%;
  margin-inline: auto;
}

.p-contact__form {
  margin-top: 2.5rem;
}
@media screen and (max-width: 767px) {
  .p-contact__form {
    margin-top: 8.2666666667vw;
  }
}

.p-contact__content:not(:first-child) {
  margin-top: 1.875rem;
}
@media screen and (max-width: 767px) {
  .p-contact__content:not(:first-child) {
    margin-top: 6.6666666667vw;
  }
}

.p-contact__head > label {
  display: flex;
  align-items: center;
  gap: 0.9375rem;
  font-size: var(--fz16);
  letter-spacing: 0.09em;
  line-height: 1.875;
  font-weight: 500;
}
@media screen and (max-width: 767px) {
  .p-contact__head > label {
    font-size: 3.7333333333vw;
    gap: 3.6vw;
  }
}

.p-contact__required {
  background: #db5f6a;
  color: #fff;
  border-radius: 0.1875rem;
  font-size: var(--fz14);
  display: block;
  padding: 0.125rem 0.625rem;
  width: 4.5rem;
  text-align: center;
  letter-spacing: 0.09em;
}
@media screen and (max-width: 767px) {
  .p-contact__required {
    font-size: 3.2vw;
    width: 17.4666666667vw;
    padding: 0.7333333333vw 1.3333333333vw;
  }
}

.p-contact__item {
  margin-top: 0.5625rem;
}
@media screen and (max-width: 767px) {
  .p-contact__item {
    margin-top: 2.6666666667vw;
  }
}

.p-contact__item-wrapper {
  width: 100%;
  min-height: 4.375rem;
}

.p-contact__item-wrapper > input {
  width: 100%;
  min-height: 4.375rem;
  border: 0.0625rem solid #d1d1d1;
  border-radius: 0.625rem;
  padding: 0.625rem 1.625rem;
}
@media screen and (max-width: 767px) {
  .p-contact__item-wrapper > input {
    padding: 1.3333333333vw 3.4666666667vw;
    min-height: 13.3333333333vw;
    font-size: 3.2vw;
    border-radius: 1.3333333333vw;
  }
}

.p-contact__item-text {
  width: 100%;
  border: 0.0625rem solid #d1d1d1;
  border-radius: 0.625rem;
  padding: 1.4375rem 1.625rem;
  font-size: var(--fz16);
  letter-spacing: 0.09em;
  line-height: 1.875;
  font-weight: 500;
}
@media screen and (max-width: 767px) {
  .p-contact__item-text {
    font-size: 3.2vw;
    border-radius: 1.3333333333vw;
    padding: 4.5333333333vw 3.6vw;
  }
}

.p-contact__item-textarea {
  width: 100%;
  border: 0.0625rem solid #d1d1d1;
  border-radius: 0.625rem;
  padding: 1.5625rem 1.625rem;
  font-size: var(--fz16);
  letter-spacing: 0.09em;
  line-height: 1.875;
  font-weight: 500;
  height: 28.6875rem;
}
@media screen and (max-width: 767px) {
  .p-contact__item-textarea {
    height: 99.3333333333vw;
    font-size: 3.2vw;
    border-radius: 1.3333333333vw;
    padding: 4.5333333333vw 3.6vw;
  }
}

.p-contact__item-text::placeholder,
.p-contact__item-textarea::placeholder {
  color: #d1d1d1;
  font-size: var(--fz16);
  font-weight: 400;
  letter-spacing: 0.09em;
}
@media screen and (max-width: 767px) {
  .p-contact__item-text::placeholder,
  .p-contact__item-textarea::placeholder {
    font-size: 3.2vw;
  }
}

.p-contact__bottom-txt {
  text-align: center;
  margin-top: 1.5625rem;
  font-size: var(--fz16);
  letter-spacing: 0.09em;
  line-height: 1.875;
  font-weight: 500;
}
@media screen and (max-width: 767px) {
  .p-contact__bottom-txt {
    font-size: 3.2vw;
    margin-top: 6.8vw;
    text-align: left;
  }
}

.p-contact__bottom-link {
  color: #029595;
  text-underline-offset: 0.1875rem;
  text-decoration: underline;
  transition: opacity 0.3s;
}

@media (any-hover: hover) {
  .p-contact__bottom-link:hover {
    opacity: 0.7;
  }
}
.p-contact__check-wrapper {
  margin-top: 1.25rem;
  border-radius: 0.625rem;
  font-size: 1.375rem;
  letter-spacing: 0.09em;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 767px) {
  .p-contact__check-wrapper {
    margin-top: 7.2vw;
    font-size: 4vw;
    letter-spacing: 0.1em;
  }
}

.p-contact__check-btn {
  appearance: none;
  border: 1px solid #d1d1d1;
  background-color: #fff;
  border-radius: 0.25rem;
  width: 1.875rem;
  height: 1.875rem;
  position: relative;
  flex-shrink: 0;
  margin-right: 1.1875rem;
  transition: background-color 0.3s, border-color 0.3s;
}
@media screen and (max-width: 767px) {
  .p-contact__check-btn {
    width: 6.4vw;
    height: 6.4vw;
    margin-right: 3.8666666667vw;
  }
}

.p-contact__check-btn:checked {
  background-color: #5FBEBE;
  border: 1px solid #5FBEBE;
}

.p-contact__check-btn:checked::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1rem;
  height: 0.5rem;
  border-left: 0.1875rem solid #fff;
  border-bottom: 0.1875rem solid #fff;
  transform: translate(-50%, -50%) rotate(-50deg);
}
@media screen and (max-width: 767px) {
  .p-contact__check-btn:checked::before {
    width: 3.2vw;
    height: 1.8666666667vw;
    border-left: 0.6666666667vw solid #fff;
    border-bottom: 0.6666666667vw solid #fff;
  }
}

.p-contact__check-label {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
}

.p-contact__btn-wrapper {
  display: block;
  margin-top: 1.875rem;
  width: 100%;
  position: relative;
  z-index: 1;
}
@media screen and (max-width: 767px) {
  .p-contact__btn-wrapper {
    margin-top: 9.3333333333vw;
  }
}

.p-contact__btn {
  border-radius: 2.1875rem;
  background-color: #5FBEBE;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.25rem 0;
  max-width: unset;
  gap: 1.25rem;
}
@media screen and (max-width: 767px) {
  .p-contact__btn {
    gap: 2.2666666667vw;
    border-radius: 6.6666666667vw;
    justify-content: flex-start;
    padding: 4.0666666667vw 1.3333333333vw 4.0666666667vw 32.2666666667vw;
  }
}

.p-contact__btn::after {
  width: 1.125rem;
  height: 1.125rem;
}
@media screen and (max-width: 767px) {
  .p-contact__btn::after {
    width: 4.6666666667vw;
    height: 4.6666666667vw;
    right: 4.8vw;
  }
}

.p-contact__btn__icon {
  width: 1.375rem;
  height: 1.375rem;
  scale: 1;
  transition: all 3s ease-out;
}
@media screen and (max-width: 767px) {
  .p-contact__btn__icon {
    width: max(2.9333333333vw, 14px);
    height: max(2.9333333333vw, 14px);
  }
}

.p-contact__btn__icon svg {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.p-contact__btn__txt {
  font-weight: 700;
  font-size: 1.25rem;
  letter-spacing: 0.04em;
  color: #fff;
}
@media screen and (max-width: 767px) {
  .p-contact__btn__txt {
    font-size: 3.4666666667vw;
    letter-spacing: 0.1em;
  }
}

@media (any-hover: hover) {
  .p-contact__btn:hover,
  .p-contact__btn:hover .p-contact__btn__icon {
    scale: 1.025;
  }
}
.p-contact__error {
  font-size: var(--fz12);
  font-weight: 500;
  line-height: 1.2;
  margin-top: 0.625rem;
  color: #e60013;
}
@media screen and (max-width: 767px) {
  .p-contact__error {
    margin-top: 1.3333333333vw;
    font-size: max(2.6666666667vw, 10px);
  }
}

input:focus-visible,
textarea:focus-visible {
  outline: none;
  border: 1px solid #5FBEBE;
}

.p-details {
  background-color: #eafbff;
  margin-top: 1.875rem;
  padding: 1.875rem 2.875rem 2.25rem 2.4375rem;
  margin-bottom: 3rem;
  max-width: 60.9375rem;
  width: 100%;
  margin-inline: auto;
}
@media screen and (max-width: 767px) {
  .p-details {
    margin-top: 4vw;
    padding: 5.7333333333vw 3.7333333333vw 5.6vw;
    margin-bottom: 4vw;
  }
}

.p-details.p-details--blue {
  background-color: #eafbff;
  margin-bottom: 1.75rem;
}
@media screen and (max-width: 767px) {
  .p-details.p-details--blue {
    margin-bottom: 4vw;
  }
}

.p-details.p-details--pink {
  background-color: #FCF0F0;
}

.p-details.p-details--gray {
  background-color: #F0F0F0;
}

.p-details__ttl {
  font-size: 1.25rem;
  letter-spacing: 0.06em;
  font-weight: 700;
  line-height: 1.45;
}
@media screen and (max-width: 767px) {
  .p-details__ttl {
    font-size: 3.2vw;
  }
}

.p-details__ttl.p-details__ttl--blue {
  color: #1bbae2;
}

.p-details__ttl.p-details__ttl--pink {
  color: #ffa2aa;
}

.p-details__item + .p-details__item {
  margin-top: 3.6875rem;
}
@media screen and (max-width: 767px) {
  .p-details__item + .p-details__item {
    margin-top: 4.1333333333vw;
  }
}

.p-details__item-ttl {
  font-size: var(--fz18);
  letter-spacing: 0.06em;
  font-weight: 700;
  line-height: 1.4444444444;
  margin-top: 1.375rem;
}
@media screen and (max-width: 767px) {
  .p-details__item-ttl {
    margin-top: 2.1333333333vw;
    font-size: 2.9333333333vw;
  }
}

.p-details__head-wrapper {
  margin-top: 0.8125rem;
  background-color: #fff;
  border-radius: 1.5rem;
  padding: 0.625rem 0.9375rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3.75rem;
  min-height: 4.875rem;
}
@media screen and (max-width: 767px) {
  .p-details__head-wrapper {
    gap: 9.6vw;
    padding: 2vw 4.2666666667vw;
    justify-content: flex-start;
    min-height: unset;
  }
}

.p-details__item-price-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10.5rem;
  gap: 5.4375rem;
}
@media screen and (max-width: 767px) {
  .p-details__item-price-wrapper {
    gap: 8.5333333333vw;
    margin-left: 1.0666666667vw;
  }
}

.p-details__item-price {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.625rem;
  max-width: 11.25rem;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .p-details__item-price {
    max-width: 29.8666666667vw;
    width: 100%;
    gap: 1.8666666667vw;
    font-size: 2.9333333333vw;
  }
}

.p-details__item-container .p-details__item-price {
  gap: 1rem;
}
@media screen and (max-width: 767px) {
  .p-details__item-container .p-details__item-price {
    gap: 1.8666666667vw;
  }
}

.p-details__item-price-ttl {
  background: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 5.1875rem;
  width: 100%;
  font-size: var(--fz16);
  font-weight: 500;
  letter-spacing: 0.06em;
  line-height: 1;
  padding: 0.625rem 0.9375rem;
  border-radius: 2.5rem;
}
@media screen and (max-width: 767px) {
  .p-details__item-price-ttl {
    min-width: 15.7333333333vw;
    max-width: unset;
    width: 100%;
    font-size: 2.9333333333vw;
    border-radius: 3.2vw;
    white-space: nowrap;
    padding: 1.7333333333vw 2vw;
  }
}

.p-details__item-price-txt {
  font-weight: 500;
  font-size: var(--fz16);
  letter-spacing: 0.06em;
  line-height: 1;
  flex-shrink: 0;
  max-width: 5.625rem;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .p-details__item-price-txt {
    max-width: 13.2vw;
    width: 100%;
    font-size: 2.9333333333vw;
    white-space: nowrap;
  }
}

.p-details__item-container {
  background-color: #fff;
  border-radius: 1.5rem;
  padding: 1.875rem 2.40625rem 2.375rem 2.4375rem;
  margin-top: 0.8125rem;
}
@media screen and (max-width: 767px) {
  .p-details__item-container {
    padding: 5.7333333333vw 4.2vw 5.8666666667vw 4.3333333333vw;
    margin-top: 2.1333333333vw;
  }
}

.p-details__item-wrapper {
  display: flex;
  padding-bottom: 0.875rem;
  border-bottom: 0.0625rem dashed #d1d1d1;
  max-width: 50.75rem;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .p-details__item-wrapper {
    flex-direction: column;
    align-items: flex-start;
    gap: 4vw;
    padding-bottom: 2.6666666667vw;
    border-bottom: 0.2666666667vw dashed #d1d1d1;
  }
}

.p-details__item-wrapper:last-child {
  border-bottom: none;
}

.p-details__item-price-head {
  max-width: 13.875rem;
  width: 100%;
  font-size: var(--fz18);
  font-weight: 700;
  letter-spacing: 0.06em;
  line-height: 1.4444444444;
  flex-shrink: 0;
}
@media screen and (max-width: 767px) {
  .p-details__item-price-head {
    max-width: none;
    font-size: 2.9333333333vw;
  }
}

.p-details__item-wrapper > .p-details__item-price-wrapper {
  flex: 1;
}

.p-details__item-wrapper + .p-details__item-wrapper {
  margin-top: 0.8125rem;
}
@media screen and (max-width: 767px) {
  .p-details__item-wrapper + .p-details__item-wrapper {
    margin-top: 3.8666666667vw;
  }
}

.p-details__item-wrapper.p-details__item-wrapper--wide {
  gap: 6.9375rem;
}
@media screen and (max-width: 767px) {
  .p-details__item-wrapper.p-details__item-wrapper--wide {
    gap: 4.4vw;
  }
}

.p-details__item-head {
  width: 16.5%;
  font-size: var(--fz18);
  letter-spacing: 0.06em;
  font-weight: 700;
  line-height: 1.5;
}
@media screen and (max-width: 767px) {
  .p-details__item-head {
    width: 100%;
    font-size: 2.9333333333vw;
  }
}

.p-details__item-head.p-details__item-head--wide {
  width: 38.1%;
}
@media screen and (max-width: 767px) {
  .p-details__item-head.p-details__item-head--wide {
    width: 100%;
  }
}

.p-details__item-head span {
  font-size: var(--fz14);
  letter-spacing: 0.06em;
  line-height: 1.5;
}
@media screen and (max-width: 767px) {
  .p-details__item-head span {
    font-size: 2.4vw;
  }
}

.p-details__item-content {
  width: 55.5%;
  font-size: var(--fz16);
  letter-spacing: 0.06em;
  font-weight: 500;
  line-height: 1.5;
  white-space: nowrap;
}
@media screen and (max-width: 767px) {
  .p-details__item-content {
    width: 100%;
    font-size: 2.9333333333vw;
    padding-left: 1.2em;
    white-space: normal;
  }
}

@media screen and (max-width: 767px) {
  .p-charge-info__note-ttl {
    max-width: 81.3333333333vw;
    width: 100%;
  }
}

/*会議室料金表*/
@media screen and (max-width: 767px) {
  .p-price.p-price--rental .p-details__item-wrapper {
    border-bottom: 0.2666666667vw dashed #d1d1d1;
  }
}

.p-price.p-price--rental .p-details__item-head.p-details__item-head--wide {
  font-size: var(--fz16);
}
@media screen and (max-width: 767px) {
  .p-price.p-price--rental .p-details__item-head.p-details__item-head--wide {
    font-size: 2.9333333333vw;
  }
}

.p-price.p-price--rental .p-price__top-txt {
  padding-left: 2.9375rem;
  margin-top: 0.9375rem;
}
@media screen and (max-width: 767px) {
  .p-price.p-price--rental .p-price__top-txt {
    padding-left: 0;
    margin-top: 2.9333333333vw;
  }
}

.p-price.p-price--rental .p-price__top-txt + .p-price__top-txt {
  margin-top: 0.125rem;
}
@media screen and (max-width: 767px) {
  .p-price.p-price--rental .p-price__top-txt + .p-price__top-txt {
    margin-top: 0.2666666667vw;
  }
}

.p-exemption {
  max-width: 60.5rem;
  width: 100%;
  margin-inline: auto;
}

.p-exemption__txt {
  margin-top: 0.5rem;
  font-size: var(--fz16);
  line-height: 1.875;
  letter-spacing: 0.09em;
  font-weight: 500;
}
@media screen and (max-width: 767px) {
  .p-exemption__txt {
    letter-spacing: 0.06em;
    margin-top: 5.3333333333vw;
    font-size: 3.2vw;
    line-height: 1.4583333333;
  }
}

.p-exemption__list {
  margin-top: 2.5rem;
}
@media screen and (max-width: 767px) {
  .p-exemption__list {
    margin-top: 8vw;
  }
}

.p-exemption__item {
  padding-bottom: 0.8125rem;
  border-bottom: 1px dashed #D1D1D1;
  color: #4d4d4d;
  font-size: var(--fz16);
  line-height: 1.5;
  letter-spacing: 0.06em;
  font-weight: 500;
  counter-increment: item;
  display: flex;
  padding-left: 0.9375rem;
}
@media screen and (max-width: 767px) {
  .p-exemption__item {
    padding-bottom: 2.9333333333vw;
    border-bottom: 0.2666666667vw dashed #D1D1D1;
    font-size: 3.2vw;
    line-height: 1.4583333333;
    font-weight: 400;
    padding-left: 2.6666666667vw;
  }
}

.p-exemption__item::before {
  content: "(" counter(item) ")";
  margin-right: 0.625rem;
}
@media screen and (max-width: 767px) {
  .p-exemption__item::before {
    margin-right: 2.6666666667vw;
  }
}

.p-exemption__item + .p-exemption__item {
  margin-top: 0.875rem;
}
@media screen and (max-width: 767px) {
  .p-exemption__item + .p-exemption__item {
    margin-top: 2.9333333333vw;
  }
}

.p-exemption__note {
  margin-top: 0.9375rem;
  font-size: var(--fz16);
  line-height: 1.875;
  letter-spacing: 0.09em;
  font-weight: 700;
  color: #e60013;
}
@media screen and (max-width: 767px) {
  .p-exemption__note {
    margin-top: 5.3333333333vw;
    font-size: 3.2vw;
    line-height: 1.4583333333;
    letter-spacing: 0.06em;
  }
}

.p-facilities {
  background-color: #fff;
  padding-top: 2.1875rem;
  padding-bottom: 12.5rem;
}
@media screen and (max-width: 767px) {
  .p-facilities {
    padding-top: 8vw;
    padding-bottom: 5.3333333333vw;
  }
}
@media screen and (max-width: 600px) {
  .p-facilities {
    padding-bottom: 13.3333333333vw;
  }
}

@media screen and (max-width: 767px) {
  .p-facilities__bg {
    margin-top: 6.6666666667vw;
  }
}

.p-facilities__bg::before {
  height: 145.5rem;
  top: -7.6388888889vw;
  background-size: 100% 145.5rem;
}
@media screen and (max-width: 767px) {
  .p-facilities__bg::before {
    height: 709.6vw;
    top: 1.7333333333vw;
    background-size: 100% 709.6vw;
  }
}
@media screen and (max-width: 600px) {
  .p-facilities__bg::before {
    height: 706.6666666667vw;
    top: 0;
    background-size: 100% 693.3333333333vw;
  }
}

.p-facilities-top-ttl.c-sec-ttl.c-sec-ttl--small {
  position: relative;
  top: -4.6527777778vw;
}
@media screen and (max-width: 767px) {
  .p-facilities-top-ttl.c-sec-ttl.c-sec-ttl--small {
    letter-spacing: 0.06em;
    top: -1.4666666667vw;
  }
}

.p-facilities__top-txt {
  margin-top: -1.4583333333vw;
  max-width: 60rem;
  width: 100%;
  margin-inline: auto;
  font-weight: 500;
  font-size: max(1rem, 10px);
  letter-spacing: 0.09em;
  line-height: 1.875;
}
@media screen and (max-width: 767px) {
  .p-facilities__top-txt {
    margin-top: 4.6666666667vw;
    font-size: 3.4666666667vw;
    line-height: 2.5384615385;
  }
}

.p-facilities__img-modal-btn.p-top-facility__img-modal-link {
  max-width: 62.5rem;
  width: 100%;
  margin-inline: auto;
  margin-top: 2.6875rem;
  overflow: hidden;
  border: 0.0625rem solid #4d4d4d;
  max-height: 25.75rem;
}
@media screen and (max-width: 767px) {
  .p-facilities__img-modal-btn.p-top-facility__img-modal-link {
    border-radius: 4.8vw;
    margin-top: 4vw;
    border: 0.1333333333vw solid #4d4d4d;
  }
}

.p-facilities__img-modal-btn.p-top-facility__img-modal-link img {
  object-fit: contain;
  background-color: #fff;
  aspect-ratio: 1000/420;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .p-facilities__img-modal-btn.p-top-facility__img-modal-link img {
    aspect-ratio: 690/412;
  }
}

.p-facility__modal-img img {
  border-radius: 2.25rem;
}
@media screen and (max-width: 767px) {
  .p-facility__modal-img img {
    aspect-ratio: 690/412;
    border-radius: 4.8vw;
  }
}

.p-facilities__wrapper {
  margin-top: 3.25rem;
}
@media screen and (max-width: 767px) {
  .p-facilities__wrapper {
    margin-top: 8.6666666667vw;
  }
}

.p-facilities__table {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 0 auto;
  gap: 1.125rem 3.125rem;
  max-width: 59.625rem;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .p-facilities__table {
    display: flex;
    flex-direction: column;
    gap: 3.7333333333vw;
  }
}

.p-facilities__cell {
  padding-bottom: 0.8125rem;
  border-bottom: 0.0625rem dashed #f4a5ac;
}
@media screen and (max-width: 767px) {
  .p-facilities__cell {
    padding-bottom: 1.6vw;
    border-bottom: 0.2666666667vw dashed #d1d1d1;
  }
}

.p-facilities__cell-ttl {
  color: #e60013;
  font-weight: 700;
  font-size: max(1rem, 10px);
  line-height: 1.5;
  letter-spacing: 0.06em;
}
@media screen and (max-width: 767px) {
  .p-facilities__cell-ttl {
    font-size: 3.2vw;
    line-height: 1.4583333333;
  }
}

.p-facilities__cell-txt {
  margin-top: 0.625rem;
  font-weight: 500;
  font-size: max(1rem, 10px);
  line-height: 1.5;
  letter-spacing: 0.03em;
  white-space: nowrap;
}
@media screen and (max-width: 767px) {
  .p-facilities__cell-txt {
    margin-top: 1.0666666667vw;
    font-size: 3.2vw;
    line-height: 1.4583333333;
    white-space: normal;
  }
}

.p-facilities__cell--wide {
  grid-column: span 2;
}
@media screen and (max-width: 767px) {
  .p-facilities__cell--wide {
    grid-column: span 1;
  }
}

.p-facilities__img-list {
  margin-top: 9.25rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 2.3125rem;
  row-gap: 2.75rem;
}
@media screen and (max-width: 767px) {
  .p-facilities__img-list {
    margin-top: 13.6vw;
    gap: 12.2666666667vw;
  }
}

.p-facilities__img-item {
  max-width: 28.75rem;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .p-facilities__img-item {
    max-width: unset;
  }
}

.p-facilities__img-item:nth-child(1) {
  margin-top: -2.8125rem;
}
@media screen and (max-width: 767px) {
  .p-facilities__img-item:nth-child(1) {
    margin-top: 0;
  }
}

.p-facilities__img-item:nth-child(3) {
  margin-top: -1rem;
}
@media screen and (max-width: 767px) {
  .p-facilities__img-item:nth-child(3) {
    margin-top: 0;
  }
}

.p-facilities__img-box {
  position: relative;
}

.p-facilities__img-wrapper {
  width: 100%;
}

.p-facilities__img-wrapper img {
  border-radius: 2.5rem;
  object-fit: contain;
  aspect-ratio: 460/280;
}
@media screen and (max-width: 767px) {
  .p-facilities__img-wrapper img {
    aspect-ratio: 690/428;
  }
}

.p-facilities__txt-wrapper {
  position: absolute;
  top: 0;
  left: 0;
}

.p-facilities__img-item:nth-child(1) .p-facilities__txt-wrapper {
  top: -3.1875rem;
  left: -1.875rem;
  width: 10.375rem;
}
@media screen and (max-width: 767px) {
  .p-facilities__img-item:nth-child(1) .p-facilities__txt-wrapper {
    top: -13.4666666667vw;
    left: -4vw;
    width: 43.7333333333vw;
  }
}

.p-facilities__img-item:nth-child(2) .p-facilities__txt-wrapper {
  top: -2rem;
  left: -1.875rem;
  width: 10.375rem;
}
@media screen and (max-width: 767px) {
  .p-facilities__img-item:nth-child(2) .p-facilities__txt-wrapper {
    top: -13.3333333333vw;
    left: -4vw;
    width: 43.7333333333vw;
  }
}

.p-facilities__img-item:nth-child(3) .p-facilities__txt-wrapper {
  top: -1.75rem;
  left: -1.9375rem;
  width: 15rem;
}
@media screen and (max-width: 767px) {
  .p-facilities__img-item:nth-child(3) .p-facilities__txt-wrapper {
    top: -14vw;
    left: -4vw;
    width: 43.7333333333vw;
  }
}

.p-facilities__img-item:nth-child(4) .p-facilities__txt-wrapper {
  top: -1.375rem;
  left: -1.9375rem;
  width: 12.3125rem;
}
@media screen and (max-width: 767px) {
  .p-facilities__img-item:nth-child(4) .p-facilities__txt-wrapper {
    top: -14vw;
    left: -4vw;
    width: 43.7333333333vw;
  }
}

.p-facilities__img-ttl {
  font-weight: 700;
  font-size: max(1rem, 10px);
  line-height: 2.25;
  letter-spacing: 0.09em;
  margin-top: 0.625rem;
}
@media screen and (max-width: 767px) {
  .p-facilities__img-ttl {
    position: relative;
    z-index: 1;
    margin-top: 1.7333333333vw;
    font-size: 3.4666666667vw;
    line-height: 2.5384615385;
  }
}

.p-facilities__bottom-wrapper {
  max-width: 59.75rem;
  width: 100%;
  margin-top: 2.5rem;
  background-color: #fff;
  padding: 2.125rem;
  border-radius: 1.25rem;
  margin-inline: auto;
}
@media screen and (max-width: 767px) {
  .p-facilities__bottom-wrapper {
    margin-top: 10.5333333333vw;
    padding: 8vw 6vw 5.4666666667vw;
  }
}

.p-facilities__bottom-txt {
  max-width: 51.125rem;
  width: 100%;
  margin-inline: auto;
  font-size: max(1rem, 10px);
  line-height: 1.875;
  letter-spacing: 0.09em;
}
@media screen and (max-width: 767px) {
  .p-facilities__bottom-txt {
    max-width: 82.6666666667vw;
    font-size: 3.4666666667vw;
    line-height: 2.1538461538;
  }
}

.p-facilities__btn-wrapper {
  max-width: 59.75rem;
  width: 100%;
  margin-top: 3.125rem;
  margin-inline: auto;
  display: flex;
  justify-content: flex-end;
}
@media screen and (max-width: 767px) {
  .p-facilities__btn-wrapper {
    margin-top: 12vw;
    justify-content: center;
  }
}

.p-facilities__btn {
  max-width: 21.25rem;
  font-size: max(0.875rem, 10px);
}
@media screen and (max-width: 767px) {
  .p-facilities__btn {
    max-width: 80vw;
    font-size: 3.2vw;
    letter-spacing: 0.04em;
    min-height: 13.3333333333vw;
    border-radius: 6.6666666667vw;
    padding-left: 8vw;
  }
}

@media screen and (max-width: 767px) {
  .p-facilities__btn::after {
    width: 4.5333333333vw;
    height: 4.5333333333vw;
    right: 3.7333333333vw;
  }
}

.p-footer {
  position: relative;
  overflow: hidden;
  margin-top: -0.0625rem;
}
@media screen and (max-width: 767px) {
  .p-footer {
    margin-top: -0.1333333333vw;
  }
}

.p-footer__container {
  position: relative;
  z-index: 2;
  background-color: #fff;
  margin-bottom: 20.8125rem;
}
@media screen and (max-width: 767px) {
  .p-footer__container {
    margin-bottom: 48.5333333333vw;
  }
}

.p-footer__bg {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 25.5625rem;
  z-index: -1;
}
@media (min-width: 1441px) {
  .p-footer__bg {
    height: 28.125rem;
  }
}
@media screen and (max-width: 767px) {
  .p-footer__bg {
    height: 56.4vw;
    bottom: 12.8vw;
  }
}

.p-footer__bg::before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(0.00390625px);
  top: 0;
  left: 0;
  z-index: 1;
}

.p-footer__container::after {
  content: "";
  position: absolute;
  bottom: -7.1527777778vw;
  left: 0;
  width: 100%;
  background-image: url(../img/bg_wave.png);
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  height: 8.0555555556vw;
  z-index: 1;
}
@media screen and (max-width: 767px) {
  .p-footer__container::after {
    background-image: url(../img/bg_wave-sp.png);
    height: 14.9333333333vw;
    bottom: -13.6vw;
  }
}

.p-footer__bg-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.p-footer__inner {
  max-width: 62.8125rem;
  width: 100%;
  margin-inline: auto;
  padding-inline: 1.5625rem;
  display: flex;
  gap: 6.8125rem;
  padding-top: 3.5rem;
  padding-bottom: 3.125rem;
  z-index: 2;
}
@media (max-width: 1024px) {
  .p-footer__inner {
    gap: 5rem;
  }
}
@media screen and (max-width: 767px) {
  .p-footer__inner {
    flex-direction: column;
    gap: 10.9333333333vw;
    padding-top: 19.7333333333vw;
    padding-right: 4vw;
    padding-bottom: 5.3333333333vw;
    padding-left: 6.6666666667vw;
  }
}

.p-footer__info {
  max-width: 24.375rem;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .p-footer__info {
    max-width: 76vw;
    margin-inline: auto;
  }
}

.p-footer__info-wrapper {
  padding-left: 2.6875rem;
}
@media screen and (max-width: 767px) {
  .p-footer__info-wrapper {
    padding-left: 1.6vw;
  }
}

.p-footer__logo {
  max-width: 23.6875rem;
  width: 100%;
  display: block;
}
@media screen and (max-width: 767px) {
  .p-footer__logo {
    max-width: 76vw;
  }
}

.p-footer__logo img {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.p-footer__info-txt {
  margin-top: 1rem;
  font-style: normal;
  font-size: max(1rem, 10px);
  letter-spacing: 0.14em;
  font-weight: 500;
}
@media screen and (max-width: 767px) {
  .p-footer__info-txt {
    margin-top: 2.1333333333vw;
    font-size: 3.2vw;
  }
}

.p-footer__info-tel-wrapper {
  display: flex;
  align-items: center;
  gap: 0.9375rem;
  margin-top: 0.625rem;
}
@media screen and (max-width: 767px) {
  .p-footer__info-tel-wrapper {
    margin-top: 4.2666666667vw;
    gap: 3.6666666667vw;
    align-items: flex-end;
  }
}

.p-footer__icon-tel {
  width: 1.3125rem;
  height: 1.3125rem;
}
@media screen and (max-width: 767px) {
  .p-footer__icon-tel {
    width: 5.2vw;
    height: 5.2vw;
  }
}

.p-footer__icon-tel img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.p-footer__info-tel {
  color: #e60013;
  pointer-events: none;
  font-size: 1.3125rem;
  letter-spacing: 0.06em;
  font-weight: 700;
  line-height: 1.4285714286;
}
@media screen and (max-width: 767px) {
  .p-footer__info-tel {
    pointer-events: auto;
    font-size: 4.6666666667vw;
    line-height: 1.4571428571;
  }
}

.p-footer__info-fax {
  margin-top: 0.875rem;
  font-size: max(1rem, 10px);
  font-weight: 500;
}
@media screen and (max-width: 767px) {
  .p-footer__info-fax {
    margin-top: 4.9333333333vw;
    font-size: 3.2vw;
    letter-spacing: 0.14em;
  }
}

.p-footer__info-mail {
  margin-top: 0.625rem;
  font-size: max(1rem, 10px);
  font-weight: 500;
  white-space: nowrap;
}
@media screen and (max-width: 767px) {
  .p-footer__info-mail {
    margin-top: 2.2666666667vw;
    font-size: 3.2vw;
  }
}

.p-footer__map-btn {
  font-size: max(0.875rem, 10px);
  margin-top: 1.625rem;
  margin-inline: auto;
  font-weight: 700;
}
@media screen and (max-width: 767px) {
  .p-footer__map-btn {
    margin-top: 8.6666666667vw;
    font-size: 2.6666666667vw;
    letter-spacing: 0.04em;
  }
}

.p-footer__nav {
  display: flex;
  gap: 5.9375rem;
}
@media screen and (max-width: 767px) {
  .p-footer__nav {
    justify-content: space-between;
    gap: 11.3333333333vw;
  }
}

.p-footer__nav-list {
  max-width: 9.0625rem;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .p-footer__nav-list {
    max-width: 33.3333333333vw;
  }
}

.p-footer__nav-item {
  padding: 0.6875rem 0;
}
@media screen and (max-width: 767px) {
  .p-footer__nav-item {
    padding: 4.4vw 0;
  }
}

.p-footer__nav-item:first-child {
  padding-top: 0;
}
.p-footer__nav-link {
  font-size: max(1rem, 10px);
  font-weight: 700;
  display: flex;
  align-items: center;
  word-break: keep-all;
  transition: color 0.3s ease;
}
@media screen and (max-width: 767px) {
  .p-footer__nav-link {
    font-size: 3.4666666667vw;
  }
}

.p-footer__nav-link--about {
  gap: 0.75rem;
}
@media screen and (max-width: 767px) {
  .p-footer__nav-link--about {
    gap: 2.9333333333vw;
  }
}

.p-footer__nav-link--facility {
  gap: 0.9375rem;
}
@media screen and (max-width: 767px) {
  .p-footer__nav-link--facility {
    gap: 4.1333333333vw;
  }
}

.p-footer__nav-link--facility span {
  white-space: nowrap;
}

.p-footer__nav-link--charge {
  gap: 0.875rem;
}
@media screen and (max-width: 767px) {
  .p-footer__nav-link--charge {
    gap: 3.8666666667vw;
  }
}

.p-footer__nav-link--application {
  gap: 1.1375rem;
}
@media screen and (max-width: 767px) {
  .p-footer__nav-link--application {
    gap: 4.8vw;
  }
}

.p-footer__nav-link--access {
  gap: 1rem;
}
@media screen and (max-width: 767px) {
  .p-footer__nav-link--access {
    gap: 4.4vw;
  }
}

.p-footer__nav-icon {
  display: flex;
  align-items: center;
}

.p-footer__nav-icon svg path {
  transition: all 0.3s ease;
}

@media (any-hover: hover) {
  .p-footer__nav-link:hover {
    color: #e60013;
  }
  .p-footer__nav-link:hover svg path {
    fill: #FFA2AA;
  }
}
.p-footer__nav-link--about svg {
  width: 1.35375rem;
  height: 0.9325rem;
}
@media screen and (max-width: 767px) {
  .p-footer__nav-link--about svg {
    width: 6.376vw;
    height: 4.392vw;
  }
}

.p-footer__nav-link--facility svg {
  width: 1.185625rem;
  height: 0.786875rem;
}
@media screen and (max-width: 767px) {
  .p-footer__nav-link--facility svg {
    width: 5.264vw;
    height: 3.4933333333vw;
  }
}

.p-footer__nav-link--charge svg {
  width: 1.185625rem;
  height: 1.219375rem;
}
@media screen and (max-width: 767px) {
  .p-footer__nav-link--charge svg {
    width: 5.264vw;
    height: 5.4146666667vw;
  }
}

.p-footer__nav-link--application svg {
  width: 0.765rem;
  height: 0.8925rem;
}
@media screen and (max-width: 767px) {
  .p-footer__nav-link--application svg {
    width: 4.576vw;
    height: 5.3386666667vw;
  }
}

.p-footer__nav-link--access svg {
  width: 1.00125rem;
  height: 1.325rem;
}
@media screen and (max-width: 767px) {
  .p-footer__nav-link--access svg {
    width: 4.4453333333vw;
    height: 5.8826666667vw;
  }
}

.p-footer__nav-sub-list {
  padding-left: 3.25rem;
  position: relative;
}
@media screen and (max-width: 767px) {
  .p-footer__nav-sub-list {
    padding-left: 14.6666666667vw;
  }
}

.p-footer__nav-sub-list::before {
  content: "";
  position: absolute;
  transform: translateY(-50%);
  left: 2.125rem;
}
@media screen and (max-width: 767px) {
  .p-footer__nav-sub-list::before {
    left: 10vw;
    top: 55%;
  }
}

@media screen and (max-width: 767px) {
  .p-footer__nav-item:nth-child(3) {
    padding-top: 1.0666666667vw;
  }
}

.p-footer__nav-item:nth-child(2) .p-footer__nav-sub-list::before {
  top: 55%;
  width: 0.125rem;
  height: 5.621875rem;
  display: block;
  border-left: 0.0625rem dashed #d1d1d1;
}
@media screen and (max-width: 767px) {
  .p-footer__nav-item:nth-child(2) .p-footer__nav-sub-list::before {
    top: 55%;
    border-left: 0.2666666667vw dashed #d1d1d1;
    width: 0.2666666667vw;
    height: 26.6vw;
  }
}

.p-footer__nav-item:nth-child(3) .p-footer__nav-sub-list::before {
  top: 55%;
  width: 0.125rem;
  height: 2.48125rem;
  display: block;
  border-left: 0.0625rem dashed #d1d1d1;
}
@media screen and (max-width: 767px) {
  .p-footer__nav-item:nth-child(3) .p-footer__nav-sub-list::before {
    border-left: 0.2666666667vw dashed #d1d1d1;
    height: 11.1986666667vw;
    top: 88%;
  }
}

.p-footer__nav-item:nth-child(3) .p-footer__nav-sub-item:last-child .p-footer__nav-sub-link {
  padding-bottom: 1.125rem;
}
@media screen and (max-width: 767px) {
  .p-footer__nav-item:nth-child(3) .p-footer__nav-sub-item:last-child .p-footer__nav-sub-link {
    padding-bottom: 0;
    padding-top: 10.1333333333vw;
  }
}

.p-footer__nav-sub-item:first-child .p-footer__nav-sub-link {
  padding-top: 1.25rem;
}
@media screen and (max-width: 767px) {
  .p-footer__nav-sub-item:first-child .p-footer__nav-sub-link {
    padding-top: 6.2666666667vw;
  }
}

.p-footer__nav-sub-item:last-child .p-footer__nav-sub-link {
  padding-bottom: 0.25rem;
}

.p-footer__nav-sub-link {
  color: #4d4d4d;
  font-size: max(0.875rem, 9px);
  font-weight: 700;
  padding: 0.625rem 0;
  display: block;
  transition: color 0.3s ease;
}
@media screen and (max-width: 767px) {
  .p-footer__nav-sub-link {
    font-size: 2.9333333333vw;
    padding: 3.7333333333vw 0;
  }
}

@media (any-hover: hover) {
  .p-footer__nav-sub-link:hover {
    color: #e60013;
  }
}
.p-footer__nav-btn-wrapper {
  max-width: 13.5rem;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .p-footer__nav-btn-wrapper {
    max-width: 44.6666666667vw;
  }
}

.p-footer__nav-btn {
  display: block;
}

.p-footer__nav-btn {
  width: 100%;
  min-height: 3.5rem;
  border-radius: 0.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.125rem;
  padding-left: 1.125rem;
  font-size: max(1rem, 10px);
  font-weight: 700;
}
@media screen and (max-width: 767px) {
  .p-footer__nav-btn {
    font-size: 3.2vw;
    min-height: 11.7333333333vw;
    border-radius: 1.3333333333vw;
  }
}

.p-footer__nav-btn--availability {
  color: #4d4d4d;
  border: 0.0625rem solid #151515;
  transition: all 0.3s ease;
}
@media screen and (max-width: 600px) {
  .p-footer__nav-btn--availability {
    padding-left: 0.625rem;
  }
}

.p-footer__nav-btn--contact {
  background-color: #e60013;
  color: #fff;
  margin-top: 1.25rem;
  transition: all 0.3s ease;
}
@media screen and (max-width: 767px) {
  .p-footer__nav-btn--contact {
    margin-top: 4.6666666667vw;
  }
}

.p-footer__nav-btn-icon {
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.p-footer__nav-btn--availability svg {
  width: 0.83875rem;
  height: 0.95875rem;
}
@media screen and (max-width: 767px) {
  .p-footer__nav-btn--availability svg {
    width: 2.816vw;
    height: 3.2186666667vw;
  }
}

.p-footer__nav-btn--availability svg path {
  transition: all 0.3s ease;
}

.p-footer__nav-btn--contact svg {
  width: 1.059375rem;
  height: 1.246875rem;
}
@media screen and (max-width: 767px) {
  .p-footer__nav-btn--contact svg {
    width: 3.512vw;
    height: 3.5146666667vw;
  }
}

.p-footer__nav-btn-ttl {
  margin-left: 0.475rem;
  display: flex;
  align-items: flex-end;
  white-space: nowrap;
}
@media screen and (max-width: 767px) {
  .p-footer__nav-btn-ttl {
    flex-wrap: wrap;
    margin-left: 1.3333333333vw;
    display: block;
  }
}

.p-footer__nav-btn--contact .p-footer__nav-btn-ttl {
  margin-left: 0.5625rem;
}
@media screen and (max-width: 767px) {
  .p-footer__nav-btn--contact .p-footer__nav-btn-ttl {
    margin-left: 1.8666666667vw;
  }
}

.p-footer__nav-btn--bnr {
  margin-top: 1.25rem;
  width: 100%;
  padding: 0;
  transition: all 0.3s ease;
}
@media screen and (max-width: 767px) {
  .p-footer__nav-btn--bnr {
    margin-top: 4.6666666667vw;
    display: block;
  }
}

.p-footer__nav-btn--bnr img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.p-footer__nav-btn-sub {
  font-size: max(0.625rem, 8px);
  font-weight: 400;
}
@media screen and (max-width: 767px) {
  .p-footer__nav-btn-sub {
    font-size: 2.1333333333vw;
  }
}

@media (any-hover: hover) {
  .p-footer__nav-btn:hover {
    scale: 1.025;
  }
  .p-footer__nav-btn--availability:hover {
    color: #e60013;
    scale: 1;
  }
  .p-footer__nav-btn--availability:hover svg path {
    fill: #FFA2AA;
  }
  .p-footer__nav-btn--contact:hover {
    background-color: #ff5362;
    scale: 1;
  }
}
.p-footer__nav-center-wrapper {
  margin-top: 1.75rem;
  display: flex;
  gap: 2.4375rem;
}
@media screen and (max-width: 767px) {
  .p-footer__nav-center-wrapper {
    margin-top: 4.6666666667vw;
  }
}

.p-footer__nav-center-link {
  display: flex;
  align-items: center;
  gap: 0.55rem;
  color: #4d4d4d;
  transition: color 0.3s ease;
}
@media screen and (max-width: 767px) {
  .p-footer__nav-center-link {
    gap: 2.4vw;
  }
}

@media (any-hover: hover) {
  .p-footer__nav-center-link:hover {
    color: #e60013;
  }
  .p-footer__nav-center-link:hover svg path {
    fill: #FFA2AA;
  }
  .p-footer__nav-center-link:hover svg rect {
    fill: #fff;
  }
}
.p-footer__nav-center-icon {
  display: flex;
  align-items: center;
  width: 1.259375rem;
}
@media screen and (max-width: 767px) {
  .p-footer__nav-center-icon {
    width: max(3.8066666667vw, 15px);
  }
}

.p-footer__nav-center-icon svg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.p-footer__nav-center-icon svg path {
  transition: all 0.3s ease;
}

.p-footer__nav-center-ttl {
  font-size: 1rem;
  font-weight: 700;
}
@media screen and (max-width: 767px) {
  .p-footer__nav-center-ttl {
    font-size: 2.4vw;
  }
}

.p-footer__nav-bottom-link {
  display: block;
  margin-top: 1.75rem;
  font-size: max(0.75rem, 10px);
  font-weight: 500;
  letter-spacing: 0.14em;
  text-decoration: underline;
  text-underline-offset: 0.1875rem;
  color: #959595;
  transition: color 0.3s ease;
}
@media screen and (max-width: 767px) {
  .p-footer__nav-bottom-link {
    font-size: 2.4vw;
    margin-top: 4.6666666667vw;
  }
}

.p-footer__nav-bottom-link + .p-footer__nav-bottom-link {
  margin-top: 1.25rem;
}

@media (any-hover: hover) {
  .p-footer__nav-bottom-link:hover {
    color: #e60013;
  }
}
.p-footer__copyright {
  text-align: center;
  font-size: max(0.75rem, 10px);
  font-weight: 700;
  letter-spacing: 0.14em;
  color: #fff;
  margin-inline: auto;
  display: block;
  padding-bottom: 1.625rem;
  line-height: 1.4166666667;
}
@media screen and (max-width: 767px) {
  .p-footer__copyright {
    font-size: 2.6666666667vw;
    padding-bottom: 14.9333333333vw;
  }
}

.p-footer__fix-wrapper {
  display: none;
}
@media screen and (max-width: 767px) {
  .p-footer__fix-wrapper {
    position: fixed;
    bottom: -12.8vw;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    background: #fff;
    margin-bottom: 12.8vw;
  }
}

.p-footer__fix-btn {
  display: flex;
  align-items: center;
  width: 50%;
  border: 0.2666666667vw solid #151515;
  min-height: 12.8vw;
}

.p-footer__fix-btn--availability {
  border-right: none;
  color: #4d4d4d;
  font-weight: 700;
  font-size: 3.4666666667vw;
  gap: 2.5333333333vw;
  padding: 2vw 1.3333333333vw 2vw 4.5333333333vw;
}

.p-footer__fix-btn--availability svg {
  width: 2.732vw;
  height: 3.1213333333vw;
}

.p-footer__fix-btn-icon svg {
  display: flex;
  align-items: center;
}

.p-header__sp-btn-sub {
  font-weight: 400;
  font-size: 2.5333333333vw;
}

.p-footer__fix-btn--contact {
  background-color: #e60013;
  color: #fff;
  padding: 2vw 1.3333333333vw 2vw 6.3466666667vw;
  letter-spacing: 0.06em;
  font-size: 4.5333333333vw;
  gap: 2.5333333333vw;
}

.p-footer__fix-btn--contact svg {
  width: 4.9826666667vw;
  height: 4.9853333333vw;
}

@media screen and (max-width: 767px) {
  .p-footer__fix-btn-sub {
    font-size: 2.5333333333vw;
    font-weight: 400;
  }
}

@media screen and (max-width: 767px) {
  .p-footer__fix-btn {
    font-size: 3.4666666667vw;
  }
}

@media screen and (max-width: 767px) {
  .p-footer__fix-btn--contact {
    font-size: 4.5333333333vw;
    font-weight: 900;
    letter-spacing: 0.06em;
    gap: 2.5333333333vw;
    display: flex;
    align-items: center;
  }
}

@media screen and (max-width: 767px) {
  .p-footer__fix-btn--contact .p-footer__fix-btn-icon svg {
    width: 4.9826666667vw;
    height: 4.9853333333vw;
    margin-top: 1.6vw;
  }
}

.p-header {
  height: 6.5rem;
  background-color: #fff;
  transition: height 0.4s ease-in-out 0.1s;
}
@media screen and (max-width: 767px) {
  .p-header {
    height: 12.5333333333vw;
  }
}

.p-header.is-scrolled {
  height: 4rem;
}

.p-header__inner {
  padding: 0 0 0 2.4375rem;
  height: inherit;
  display: flex;
  position: relative;
  justify-content: space-between;
}
@media screen and (max-width: 767px) {
  .p-header__inner {
    padding-left: 4vw;
    padding-right: 0;
  }
}

.p-header__logo {
  max-width: 13.125rem;
  width: 100%;
  margin-right: 1.25rem;
  height: inherit;
}
@media screen and (max-width: 767px) {
  .p-header__logo {
    max-width: 41.7333333333vw;
    flex-shrink: 0;
    margin-right: 0;
  }
}

.p-header__logo > a {
  height: inherit;
  display: flex;
  align-items: center;
}

.p-header__logo img {
  max-width: 13.125rem;
  width: 100%;
  height: 1.3125rem;
  object-fit: contain;
}
@media screen and (max-width: 767px) {
  .p-header__logo img {
    max-width: 41.7333333333vw;
    height: 1.9375rem;
  }
}

.p-header__nav-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: inherit;
  transition: height 0.2s ease-in-out;
}

.p-header__nav {
  display: block;
  height: inherit;
}
@media screen and (max-width: 767px) {
  .p-header__nav {
    display: none;
  }
}

.p-header__nav-items {
  display: flex;
  height: inherit;
  gap: 2.375rem;
}
@media (max-width: 1330px) {
  .p-header__nav-items {
    gap: 1.5rem;
  }
}
@media (max-width: 1080px) {
  .p-header__nav-items {
    gap: 1rem;
  }
}

.p-header__nav-item {
  height: inherit;
}

.p-header__nav-item-link {
  height: inherit;
  display: flex;
  align-items: center;
  font-size: max(1rem, 11px);
  font-weight: 700;
  line-height: 1;
  color: #151515;
  position: relative;
  cursor: pointer;
  transition: 0.3s;
}

.p-header__nav-item-link--about {
  gap: 0.75rem;
}
@media (max-width: 1024px) {
  .p-header__nav-item-link--about {
    gap: 0.625rem;
  }
}

.p-header__nav-item-link--facility {
  gap: 0.9375rem;
}
@media (max-width: 1024px) {
  .p-header__nav-item-link--facility {
    gap: 0.625rem;
  }
}

.p-header__nav-item-link--charge {
  gap: 0.875rem;
}
@media (max-width: 1024px) {
  .p-header__nav-item-link--charge {
    gap: 0.625rem;
  }
}

.p-header__nav-item-link--application {
  gap: 0.6875rem;
}
@media (max-width: 1024px) {
  .p-header__nav-item-link--application {
    gap: 0.625rem;
  }
}

.p-header__nav-item-link--access {
  gap: 0.875rem;
}
@media (max-width: 1024px) {
  .p-header__nav-item-link--access {
    gap: 0.625rem;
  }
}

.p-header__nav-item-icon svg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.p-header__nav-item-link--about svg {
  width: 1.3125rem;
  height: 0.875rem;
}

.p-header__nav-item-link--facility svg {
  width: 1.125rem;
  height: 0.75rem;
}

.p-header__nav-item-link--charge svg {
  width: 1.125rem;
  height: 1.1875rem;
}

.p-header__nav-item-link--application svg {
  width: 0.6875rem;
  height: 0.8125rem;
}

.p-header__nav-item-link--access svg {
  width: 1rem;
  height: 1.3125rem;
}

.p-header__nav-item-icon svg path {
  transition: all 0.3s ease;
}

@media (any-hover: hover) {
  .p-header__nav-item-link:hover {
    color: #e60013;
  }
  .p-header__nav-item-link:hover .p-header__nav-item-icon path {
    fill: #FFA2AA;
  }
}
.p-header__nav-right {
  margin-left: 0.9375rem;
}
@media screen and (max-width: 767px) {
  .p-header__nav-right {
    display: none;
  }
}

.p-header__nav-right-items {
  width: 16.0625rem;
}

.p-header__nav-right-item:first-child {
  border-left: 0.0625rem solid #151515;
  border-right: 0.0625rem solid #151515;
  background-color: #fff;
  color: #4D4D4D;
  font-size: max(1.125rem, 12px);
  font-weight: 700;
  transition: all 0.3s ease;
}

.p-header__nav-right-item-sub {
  font-size: max(0.625rem, 7px);
  font-weight: 400;
  color: #4D4D4D;
  transition: all 0.3s ease;
}

.p-header__nav-right-item:first-child .p-header__nav-right-item-icon path {
  transition: all 0.3s ease;
}

@media (any-hover: hover) {
  .p-header__nav-right-item:first-child:hover {
    color: #e60013;
  }
  .p-header__nav-right-item:first-child:hover .p-header__nav-right-item-sub {
    color: #e60013;
  }
  .p-header__nav-right-item:first-child:hover .p-header__nav-right-item-icon path {
    fill: #FFA2AA;
  }
}
.p-header__nav-right-item:nth-child(2) {
  border: 0.0625rem solid #151515;
  background-color: #e60013;
  border-radius: 0px 0px 0px 20px;
  color: #fff;
  font-size: max(1.125rem, 12px);
  letter-spacing: 0.06em;
  font-weight: 900;
  line-height: 1;
  transition: all 0.3s ease;
}

@media (any-hover: hover) {
  .p-header__nav-right-item:nth-child(2):hover {
    background-color: #ff5362;
  }
}
.p-header__nav-right-item-link {
  width: 100%;
  min-height: 4rem;
  gap: 0.59375rem;
  display: flex;
  align-items: center;
  padding-left: 2.53125rem;
  padding-right: 0.9375rem;
}
@media (max-width: 1024px) {
  .p-header__nav-right-item-link {
    padding-left: 1.5625rem;
  }
}

.p-header__nav-right-item-icon svg {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: all 0.3s ease;
}

.p-header__nav-right-item:nth-child(2) .p-header__nav-right-item-icon {
  width: 0.99625rem;
  height: 1.138125rem;
}

.p-header__nav-right-item:first-child .p-header__nav-right-item-icon {
  width: 1.193125rem;
  height: 1.194375rem;
  flex-shrink: 0;
}

.p-header__nav-right-item-ttl {
  display: flex;
  align-items: baseline;
  white-space: nowrap;
}

/* spここから */
.p-header__hamburger {
  display: none;
  margin: 0;
  padding: 0;
  outline: none;
  border: none;
  position: relative;
  z-index: 999;
  width: 14.9333333333vw;
  height: inherit;
  background: #e60013;
  cursor: pointer;
  transition: 0.3s;
}
@media screen and (max-width: 767px) {
  .p-header__hamburger {
    display: block;
  }
}

.p-header__hamburger.is-open {
  background-color: #e60013;
}

.p-header__hamburger span {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  width: 7.6vw;
  height: 0.5333333333vw;
  background: #fff;
  transition: 0.3s;
  border-radius: 0.2666666667vw;
}

.p-header__hamburger span:nth-of-type(1) {
  top: -2vw;
}

.p-header__hamburger span:nth-of-type(2) {
  top: 0;
}

.p-header__hamburger span:nth-of-type(3) {
  top: 2vw;
}

.p-header__hamburger.is-open span:nth-of-type(1) {
  top: 0.5333333333vw;
  transform: translateX(-50%) rotate(45deg);
}

.p-header__hamburger.is-open span:nth-of-type(2) {
  opacity: 0;
}

.p-header__hamburger.is-open span:nth-of-type(3) {
  top: -0.5333333333vw;
  transform: translateX(-50%) rotate(-45deg);
}

.p-header__sp {
  display: none;
  position: absolute;
  z-index: 900;
  top: 12.5333333333vw;
  right: 0;
  bottom: 0;
  width: 100%;
  min-height: 100vh;
  background-color: #fff;
  overflow-y: scroll;
  scrollbar-width: none;
  transform: translateX(0);
}

.p-header__sp::-webkit-scrollbar {
  display: none;
}

.p-header__sp-nav {
  padding: 0 0.9333333333vw;
  border-top: 0.0625rem solid #d1d1d1;
}

.p-header__sp-items {
  padding-bottom: 5.3333333333vw;
}

.p-header__sp-item-link {
  padding: 3.8666666667vw 0 3.8666666667vw 4.6666666667vw;
  font-size: 4.2666666667vw;
  font-weight: 700;
  color: #151515;
  display: flex;
  align-items: center;
  position: relative;
}

.p-header__sp-item-link:after {
  content: "";
  display: block;
  width: 2.6666666667vw;
  height: 2.2666666667vw;
  background-image: url(../img/icon/icon_arrow.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  right: 4.9333333333vw;
  top: 54%;
  transform: translateY(-50%);
}

.p-header__sp-item-link--about {
  gap: 4.2666666667vw;
}

.p-header__sp-item-link--facility {
  gap: 4.6266666667vw;
}

.p-header__sp-item-link--charge {
  gap: 4.6266666667vw;
}

.p-header__sp-item-link--application {
  gap: 5.6vw;
}

.p-header__sp-item-link--access {
  gap: 5.0133333333vw;
}

.p-header__sp-item-icon {
  height: 100%;
  display: flex;
  align-items: center;
}

.p-header__sp-item-icon svg {
  object-fit: contain;
}

.p-header__sp-item-link--about svg {
  width: 5.832vw;
  height: 4.0173333333vw;
}

.p-header__sp-item-link--facility svg {
  width: 5.108vw;
  height: 3.3893333333vw;
}

.p-header__sp-item-link--charge svg {
  width: 5.108vw;
  height: 5.2546666667vw;
}

.p-header__sp-item-link--application svg {
  width: 3.1986666667vw;
  height: 3.7306666667vw;
}

.p-header__sp-item-link--access svg {
  width: 4.3133333333vw;
  height: 5.708vw;
}

.p-header__sp-lists {
  padding-top: 0.375rem;
  padding-bottom: 1rem;
}

.p-header__sp-sub-items {
  max-width: 83.3333333333vw;
  margin-left: auto;
}

.p-header__sp-sub-item-link {
  display: flex;
  align-items: center;
  gap: 4vw;
  font-size: 3.7333333333vw;
  font-weight: 700;
  color: #4d4d4d;
  line-height: 1.4285714286;
  padding: 2.8vw 0;
  border-bottom: 0.0625rem dashed #D1D1D1;
}

.p-header__sp-sub-item-link::before {
  content: "";
  display: block;
  width: 2.6666666667vw;
  height: 2.2666666667vw;
  background-image: url(../img/icon/icon_arrow-gy.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-left: 3.0666666667vw;
}

.p-header__sp-btn-wrapper {
  display: flex;
  gap: 2.4vw;
  padding: 0 2.9333333333vw;
}

.p-header__sp-btn-left {
  display: flex;
  gap: 2.4vw;
  flex-direction: column;
  width: 50%;
}

.p-header__sp-btn-link {
  max-width: 44.8vw;
  width: 100%;
  min-height: 13.0666666667vw;
  border-radius: 1.3333333333vw;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.3333333333vw;
  font-size: 3.2vw;
  font-weight: 700;
}

.p-header__sp-btn-link--availability {
  color: #4d4d4d;
  border: 0.2666666667vw solid #151515;
}

.p-header__sp-btn-link--contact {
  background-color: #e60013;
  color: #fff;
}

.p-header__sp-btn-icon {
  display: flex;
  align-items: center;
}

.p-header__sp-btn-icon svg {
  object-fit: contain;
}

.p-header__sp-btn-link--availability .p-header__sp-btn-icon svg {
  width: 2.8vw;
  height: 3.2vw;
}

.p-header__sp-btn-link--contact .p-header__sp-btn-icon svg {
  width: 3.4666666667vw;
  height: 3.4666666667vw;
}

.p-header__sp-btn-link--availability .p-header__sp-btn-ttl {
  margin-left: 1.3333333333vw;
}

.p-header__sp-btn-link--contact .p-header__sp-btn-ttl {
  margin-left: 1.8666666667vw;
}

.p-header__sp-btn-sub {
  font-size: 2.1333333333vw;
  font-weight: 400;
}

.p-header__sp-btn-right {
  width: 50%;
}

.p-header__sp-right-btn {
  max-width: 44.8vw;
  width: 100%;
  min-height: 28.5333333333vw;
  border-radius: 1.3333333333vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.p-header__sp-bottom-link {
  text-align: center;
  display: block;
  margin-top: 5.6vw;
  color: #959595;
  font-size: max(2.4vw, 10px);
  letter-spacing: 0.14em;
  font-weight: 500;
  text-decoration: underline;
  text-underline-offset: 2px;
}

.p-header__sp-img-wrapper {
  padding-bottom: 22vw;
}
@media screen and (max-width: 430px) {
  .p-header__sp-img-wrapper {
    padding-bottom: 37.3333333333vw;
  }
}

.p-layout {
  background-color: #fff;
  padding-top: 2.4375rem;
}
@media screen and (max-width: 767px) {
  .p-layout {
    padding-top: 21.2vw;
  }
}

@media screen and (max-width: 767px) {
  .p-layout__ttl.c-sec-ttl.c-sec-ttl--small {
    letter-spacing: 0.06em;
  }
}

.p-layout__img-list {
  margin-top: 1.875rem;
  max-width: 62.5rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 2.25rem;
  border: 1px solid #4d4d4d;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 1.5rem;
  padding: 2.875rem 0.75rem;
}
@media screen and (max-width: 767px) {
  .p-layout__img-list {
    margin-top: 8.4vw;
    border: none;
    padding: 0;
    gap: 3.3333333333vw 4vw;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

.p-layout__img-item {
  max-width: 13.75rem;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .p-layout__img-item {
    max-width: 44vw;
    border-radius: 4.8vw;
    border: 0.1333333333vw solid #151515;
    padding-bottom: 5.3333333333vw;
  }
}

@media screen and (max-width: 767px) {
  .p-layout__img-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 14.6875rem;
    padding-top: 6.6666666667vw;
    padding: 6.6666666667vw 5.3333333333vw 1.3333333333vw;
    margin-inline: auto;
  }
}
@media screen and (max-width: 600px) {
  .p-layout__img-btn {
    padding: 1.5625rem 0.9375rem;
  }
}

.p-layout__img-btn {
  width: 100%;
  display: block;
  position: relative;
}

.p-layout__img-btn img {
  transition: scale 0.3s ease-in-out;
}
@media screen and (max-width: 767px) {
  .p-layout__img-btn img {
    width: 100%;
    object-fit: contain;
    aspect-ratio: 235/321;
    height: 42.8vw;
  }
}

.p-layout__btn-icon {
  position: absolute;
  right: 0.625rem;
  bottom: 0;
  width: 1.125rem;
  height: 1.125rem;
  transition: scale 0.2s linear;
}
@media screen and (max-width: 767px) {
  .p-layout__btn-icon {
    display: none;
  }
}

@media (any-hover: hover) {
  .p-layout__img-btn:hover .p-layout__btn-icon {
    scale: 1.2;
  }
}
.p-layout__pdf-link {
  max-width: 11.25rem;
  width: 100%;
  margin-top: 1.25rem;
  font-size: max(0.875rem, 10px);
  margin-inline: auto;
}
@media screen and (max-width: 767px) {
  .p-layout__pdf-link {
    margin-top: 2.6666666667vw;
    max-width: 37.3333333333vw;
    min-height: 6.6666666667vw;
    font-size: 2.6666666667vw;
  }
}

.p-layout__btn-wrapper {
  display: flex;
  justify-content: flex-end;
  max-width: 60rem;
  width: 100%;
  margin-inline: auto;
  margin-top: 3.125rem;
}
@media screen and (max-width: 767px) {
  .p-layout__btn-wrapper {
    margin-top: 9.0666666667vw;
    justify-content: center;
  }
}

.p-layout__bottom-btn {
  max-width: 21.25rem;
  width: 100%;
  font-size: var(--fz14);
  letter-spacing: 0.04em;
}
@media screen and (max-width: 767px) {
  .p-layout__bottom-btn {
    max-width: 80vw;
    min-height: 13.3333333333vw;
    font-size: 3.2vw;
    border-radius: 6.6666666667vw;
    padding-left: 8vw;
  }
}

@media screen and (max-width: 767px) {
  .p-layout__bottom-btn::after {
    width: 4.5333333333vw;
    height: 4.5333333333vw;
    right: 3.7333333333vw;
  }
}

/* modal */
.p-layout__modal {
  max-width: 550px;
  width: 100%;
  margin-inline: auto;
  padding: 0.625rem 0;
}
@media screen and (max-width: 767px) {
  .p-layout__modal {
    max-height: 100vh;
  }
}

.p-layout__modal-container {
  max-width: 550px;
  width: 100%;
  margin-inline: auto;
}
@media screen and (max-width: 767px) {
  .p-layout__modal-container {
    max-height: 100vh;
  }
}

.p-layout__modal-btn-wrapper {
  top: -3.125rem;
}
@media screen and (max-width: 767px) {
  .p-layout__modal-btn-wrapper {
    top: -6.6666666667vw;
  }
}

.p-layout__modal-img {
  max-width: 500px;
  overflow-y: auto;
  margin-inline: auto;
}
@media screen and (max-width: 767px) {
  .p-layout__modal-img {
    max-width: 90%;
  }
}

.p-layout__modal-img img {
  aspect-ratio: 176/250;
  width: 100%;
  object-fit: contain;
}

.p-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1000;
  display: none;
  transform: translate(-50%, -50%);
  transition: transform 0.5s, opacity 0.1s;
}

.p-modal__container {
  margin: 0 auto;
  position: relative;
}

.p-modal__btn-wrapper {
  position: absolute;
  right: 3rem;
  top: -0.75rem;
  z-index: 2;
}
@media screen and (max-width: 767px) {
  .p-modal__btn-wrapper {
    right: 6.4vw;
    top: -2.5%;
    z-index: 1000;
  }
}

.p-modal__closed-btn {
  max-width: 3.5rem;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .p-modal__closed-btn {
    max-width: 10.6666666667vw;
  }
}

.js-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  opacity: 1;
  z-index: 999;
  display: none;
}

.js-overlay.l-overlay--news {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 1;
  z-index: 999;
  display: none;
}

.p-more-info {
  background-color: #fff;
  padding-top: 1.3125rem;
  padding-bottom: 4.25rem;
}
@media screen and (max-width: 767px) {
  .p-more-info {
    padding-top: 10.6666666667vw;
    padding-bottom: 2.2666666667vw;
  }
}

.p-more-info__ttl {
  letter-spacing: 0.01em;
}

.p-more-info__ttl::after {
  margin-top: -0.1875rem;
  letter-spacing: 0.1em;
}
@media screen and (max-width: 767px) {
  .p-more-info__ttl::after {
    margin-top: -0.4vw;
    letter-spacing: normal;
  }
}

.p-more-info__list {
  margin-top: 5rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  column-gap: 3.75rem;
}
@media screen and (max-width: 767px) {
  .p-more-info__list {
    margin-top: 11.3333333333vw;
    gap: 6.8vw;
  }
}

.p-more-info__item {
  max-width: 32.5rem;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .p-more-info__item {
    max-width: unset;
  }
}

.p-more-info__item:last-child {
  margin-top: 4.25rem;
}
@media screen and (max-width: 767px) {
  .p-more-info__item:last-child {
    margin-top: 0;
  }
}

.p-more-info__img {
  width: 100%;
  border-radius: 1.375rem;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
@media screen and (max-width: 767px) {
  .p-more-info__img {
    border-radius: 2.6666666667vw;
  }
}

.p-more-info__img::before {
  content: "";
  display: block;
  width: 2.5625rem;
  height: 2.5625rem;
  background-image: url(../img/icon/icon_arrow-right.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  bottom: -0.0625rem;
  right: -0.0625rem;
  z-index: 1;
}
@media screen and (max-width: 767px) {
  .p-more-info__img::before {
    width: 8.9333333333vw;
    height: 9.4666666667vw;
    bottom: -0.2666666667vw;
    right: -0.2666666667vw;
  }
}

.p-more-info__img img {
  width: 100%;
  object-fit: cover;
  aspect-ratio: 520/208;
}
@media screen and (max-width: 767px) {
  .p-more-info__img img {
    aspect-ratio: 690/208;
  }
}

.p-more-info__item-ttl {
  font-size: max(1.625rem, 10px);
  line-height: 1.4230769231;
  letter-spacing: 0.11em;
  font-weight: 700;
  margin-top: 0.6875rem;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .p-more-info__item-ttl {
    font-size: 3.4666666667vw;
    line-height: 1.4230769231;
    margin-top: 1.0666666667vw;
  }
}

.p-naming {
  background-color: #fff;
  position: relative;
  z-index: 1;
  padding-top: 5rem;
  padding-bottom: 3.75rem;
  margin-top: -0.0625rem;
}
@media screen and (max-width: 767px) {
  .p-naming {
    padding-top: 6.4vw;
    padding-bottom: 4.4vw;
  }
}

.p-naming__wrapper {
  margin-top: 1.5625rem;
  display: flex;
  align-items: center;
  gap: 8.0625rem;
  border: 1px solid #D8D8D8;
  border-radius: 1.25rem;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  padding: 2.8125rem 4.1875rem 2.8125rem 2.9375rem;
}
@media screen and (max-width: 767px) {
  .p-naming__wrapper {
    margin-top: 5.3333333333vw;
    flex-direction: column;
    gap: 11.4666666667vw;
    justify-content: center;
    border-radius: 2.6666666667vw;
    padding: 13.6vw 9.3333333333vw 12.6666666667vw 9.3333333333vw;
  }
}

.p-naming__img {
  max-width: 29.1875rem;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .p-naming__img {
    max-width: 73.3333333333vw;
  }
}

.p-naming__img img {
  width: 100%;
  object-fit: contain;
}

.p-naming__txt {
  max-width: 24.3125rem;
  width: 100%;
  font-size: max(1rem, 10px);
  line-height: 2.25;
  letter-spacing: 0.09em;
}
@media screen and (max-width: 767px) {
  .p-naming__txt {
    max-width: 73.4666666667vw;
    font-size: 3.4666666667vw;
    line-height: 2.5384615385;
  }
}

.p-news {
  background-color: #fff;
  padding-top: 3.75rem;
}
@media screen and (max-width: 767px) {
  .p-news {
    padding-top: 8.5333333333vw;
  }
}

.p-news__wrapper {
  display: flex;
  gap: 6.4375rem;
}
@media screen and (max-width: 767px) {
  .p-news__wrapper {
    flex-direction: column;
    gap: 5.6vw;
  }
}

.p-news__box {
  max-width: 53.75rem;
  width: 100%;
  border: 1px solid #151515;
  padding: 2.625rem 4.4375rem 3.8rem 4.55rem;
  border-radius: 1.875rem;
  margin-top: -0.375rem;
}
@media screen and (max-width: 767px) {
  .p-news__box {
    margin-top: 0;
    padding: 7.8666666667vw 4.8533333333vw 9.64vw 4.84vw;
  }
}

.p-news__list {
  max-width: 44.6875rem;
  width: 100%;
}

.p-news__item {
  border-bottom: 1px dashed #D1D1D1;
  position: relative;
}

.p-news__item::after {
  content: "";
  display: block;
  background-image: url(../img/icon/icon_arrow-bk.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 0.75rem;
  height: 0.61375rem;
  margin-left: 0.625rem;
  position: absolute;
  right: 2.125rem;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s ease-in-out;
}
@media screen and (max-width: 767px) {
  .p-news__item::after {
    width: 2.1293333333vw;
    height: 1.7426666667vw;
    margin-left: 1.3333333333vw;
    right: 1.6vw;
    top: 60%;
  }
}

.p-news__item.p-news__item--note {
  border-bottom: 1px dashed #f4a5ac;
}
@media screen and (max-width: 767px) {
  .p-news__item.p-news__item--note {
    border-bottom: 0.2666666667vw dashed #f4a5ac;
  }
}

.p-news__item.p-news__item--note::after {
  background-image: url(../img/icon/icon_arrow.svg);
}

.p-news__item-link {
  display: block;
  padding-top: 0.96875rem;
  transition: all 0.3s ease-in-out;
  padding-bottom: 1.125rem;
}
@media screen and (max-width: 767px) {
  .p-news__item-link {
    padding-top: 2.6666666667vw;
    max-width: 73.6vw;
    padding-bottom: 3.4533333333vw;
  }
}

.p-news__item.p-news__item--note .p-news__item-link {
  color: #e60013;
}

.p-news__item-tag-wrapper {
  max-width: max(6.375rem, 80px);
  width: 100%;
}
@media screen and (max-width: 767px) {
  .p-news__item-tag-wrapper {
    max-width: max(18.1333333333vw, 80px);
  }
}

.p-news__item-tag {
  min-width: 6.375rem;
  width: 100%;
  border-radius: 0.375rem;
  padding: max(0.4375rem, 5px) 0.3125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.375rem;
  background-color: #4d4d4d;
  color: #fff;
  font-size: max(0.75rem, 9px);
  line-height: 1;
}
@media screen and (max-width: 767px) {
  .p-news__item-tag {
    min-width: unset;
    font-size: max(2.4vw, 9px);
    letter-spacing: -0.04em;
  }
}

.p-news__item-tag.p-news__item-tag--red {
  background-color: #e60013;
}

.p-news__item-ttl {
  display: inline-block;
  margin-top: 0.125rem;
  letter-spacing: 0.06em;
  font-weight: 500;
  color: #151515;
  font-size: var(--fz16);
  transition: color 0.3s ease-in-out;
}
@media screen and (max-width: 767px) {
  .p-news__item-ttl {
    margin-top: 0.8vw;
    font-size: max(2.9333333333vw, 10px);
    letter-spacing: 0.04em;
    text-align: left;
  }
}

.p-news__item-new {
  font-weight: 500;
  font-size: var(--fz16);
  letter-spacing: 0.06em;
  color: #5fbebe;
  display: inline-block;
  margin-top: 0.375rem;
  margin-left: 1.25rem;
}
@media screen and (max-width: 767px) {
  .p-news__item-new {
    margin-top: 0.8vw;
    margin-left: 2vw;
    font-size: max(2.9333333333vw, 10px);
  }
}

/* modal */
.p-news__modal {
  max-width: 53.75rem;
  width: 100%;
  border: 1px solid #707070;
  padding: 2.625rem 4.4375rem 2.4375rem 4.55rem;
  border-radius: 2.5rem;
  background: #fff;
}
@media screen and (max-width: 767px) {
  .p-news__modal {
    max-width: 92vw;
    padding: 7.8666666667vw 4.8533333333vw 8vw 4.84vw;
  }
}

.p-news__modal-container {
  background-color: #fff;
}
@media screen and (max-width: 767px) {
  .p-news__modal-container {
    max-height: 75vh;
    overflow-y: scroll;
  }
}

.p-news__modal-btn {
  max-width: 17.75rem;
  font-size: max(0.875rem, 10px);
}
@media screen and (max-width: 767px) {
  .p-news__modal-btn {
    max-width: 45.3333333333vw;
    font-size: max(2.6666666667vw, 10px);
  }
}

.p-news__modal-ttl {
  color: #029595;
  padding-bottom: 0.6875rem;
  line-height: 1.4583333333;
  font-size: 1.5rem;
  font-weight: 700;
  letter-spacing: 0.06em;
  border-bottom: 2px dashed #D1D1D1;
}
@media screen and (max-width: 767px) {
  .p-news__modal-ttl {
    padding-bottom: 1.4666666667vw;
    font-size: 4.2666666667vw;
  }
}

.p-news__modal-content {
  margin-top: 1.53125rem;
}
@media screen and (max-width: 767px) {
  .p-news__modal-content {
    margin-top: 6vw;
  }
}

.p-news__modal-txt {
  font-size: max(1rem, 10px);
  letter-spacing: 0.06em;
  font-weight: 500;
}
@media screen and (max-width: 767px) {
  .p-news__modal-txt {
    font-size: 3.2vw;
  }
}

.p-news__modal-txt + .p-news__modal-txt {
  margin-top: 1em;
}

.p-news__modal-btn-wrapper {
  margin-top: 1.5625rem;
}
@media screen and (max-width: 767px) {
  .p-news__modal-btn-wrapper {
    margin-top: 6vw;
  }
}

.p-news__modal-btn + .p-news__modal-btn {
  margin-top: 1rem;
  margin-bottom: 3.0625rem;
}
@media screen and (max-width: 767px) {
  .p-news__modal-btn + .p-news__modal-btn {
    margin-top: 3.7333333333vw;
    margin-bottom: 10.6666666667vw;
  }
}

@media (any-hover: hover) {
  .p-news__item:hover .p-news__item-ttl {
    color: #029595;
  }
  .p-news__item:hover::after {
    background-image: url(../img/icon/icon_arrow-gl.svg);
    right: 1.5rem;
  }
  .p-news__item.p-news__item--note:hover .p-news__item-ttl {
    color: #ff5362;
  }
  .p-news__item.p-news__item--note:hover::after {
    background-image: url(../img/icon/icon_arrow-pink.svg);
  }
}
.p-note {
  padding-top: 2.5rem;
  padding-bottom: 3.75rem;
}
@media screen and (max-width: 767px) {
  .p-note {
    padding-top: 8vw;
    padding-bottom: 8vw;
  }
}

.p-note__container {
  background-color: #f8eaea;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 2.25rem;
  padding: 3.75rem 4.125rem 3.4375rem;
}
@media screen and (max-width: 767px) {
  .p-note__container {
    width: 100vw;
    margin: 0 calc(50% - 50vw);
    border-radius: 4vw;
    padding: 8vw 4vw 16vw;
  }
}

.p-note__container:first-of-type {
  margin-top: 3.25rem;
}
@media screen and (max-width: 767px) {
  .p-note__container:first-of-type {
    margin-top: 6.4vw;
  }
}

.p-note__container + .p-note__container {
  margin-top: 3.125rem;
}
@media screen and (max-width: 767px) {
  .p-note__container + .p-note__container {
    margin-top: 10.6666666667vw;
  }
}

.p-note__top-img {
  width: 100%;
}

.p-note__top-img img {
  width: 100%;
  aspect-ratio: 968/200;
  object-fit: contain;
}
@media screen and (max-width: 767px) {
  .p-note__top-img img {
    aspect-ratio: 690/340;
  }
}

.p-note__heading {
  margin-top: 1.5625rem;
  font-size: 1.75rem;
  font-weight: 700;
  line-height: 1.4285714286;
  color: #e60013;
}
@media screen and (max-width: 767px) {
  .p-note__heading {
    margin-top: 3.6vw;
    font-size: 5.6vw;
    line-height: 1.380952381;
    letter-spacing: 0.06em;
  }
}

.p-note__container:last-child .p-note__heading {
  margin-top: 0;
}

.p-note__list {
  margin-top: 2.5625rem;
}
@media screen and (max-width: 767px) {
  .p-note__list {
    margin-top: 6vw;
  }
}

.p-note__item {
  padding-bottom: 1.125rem;
  border-bottom: 1px dashed #F4A5AC;
}
@media screen and (max-width: 767px) {
  .p-note__item {
    padding-bottom: 5.3333333333vw;
    border-bottom: 0.2666666667vw dashed #F4A5AC;
  }
}

.p-note__item + .p-note__item {
  margin-top: 1.65625rem;
}
@media screen and (max-width: 767px) {
  .p-note__item + .p-note__item {
    margin-top: 5.3333333333vw;
  }
}

.p-note__item-txt {
  font-size: var(--fz16);
  line-height: 1.5;
  letter-spacing: 0.06em;
  font-weight: 500;
}
@media screen and (max-width: 767px) {
  .p-note__item-txt {
    font-size: 3.2vw;
    line-height: 1.4583333333;
  }
}

.p-note__item-txt::before {
  content: "・";
  display: inline-block;
}

.p-note__item-detail-txt {
  margin-top: 0.6875rem;
  font-size: var(--fz16);
  line-height: 1.5;
  letter-spacing: 0.06em;
  font-weight: 400;
  max-width: 95.1%;
  width: 100%;
  margin-left: auto;
}
@media screen and (max-width: 767px) {
  .p-note__item-detail-txt {
    max-width: 90.7%;
    margin-top: 3.0666666667vw;
    font-size: 3.2vw;
    line-height: 1.4583333333;
  }
}

.p-note__item-sub-list {
  max-width: 95.1%;
  width: 100%;
  margin-left: auto;
  margin-top: 0.6875rem;
}
@media screen and (max-width: 767px) {
  .p-note__item-sub-list {
    max-width: 92.7%;
    margin-top: 2.6666666667vw;
  }
}

.p-note__item-sub-item + .p-note__item-sub-item {
  margin-top: 0.625rem;
}
@media screen and (max-width: 767px) {
  .p-note__item-sub-item + .p-note__item-sub-item {
    margin-top: 0vw;
  }
}

.p-note__item-sub-item {
  counter-increment: sub-item;
  font-size: var(--fz16);
  line-height: 1.5;
  letter-spacing: 0.06em;
  font-weight: 400;
}
@media screen and (max-width: 767px) {
  .p-note__item-sub-item {
    font-size: 3.2vw;
    line-height: 1.4583333333;
  }
}

.p-note__item-sub-txt::before {
  content: "(" counter(sub-item) ")";
  display: inline-block;
}

.p-point {
  padding-top: 5rem;
  padding-bottom: 3.125rem;
  position: relative;
  z-index: 1;
}
@media screen and (max-width: 767px) {
  .p-point {
    padding-top: 17.3333333333vw;
  }
}

.p-point__copy {
  font-size: max(1.625rem, 10px);
  line-height: 1.4230769231;
  font-weight: 700;
  letter-spacing: 0.11em;
}
@media screen and (max-width: 767px) {
  .p-point__copy {
    font-size: 5.6vw;
    letter-spacing: 0.06em;
    line-height: 1.5714285714;
  }
}

.p-point__copy span {
  color: #e60013;
}

.p-point__list {
  margin-top: 2.5rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2.5rem;
  margin-bottom: 0;
  position: relative;
}
@media screen and (max-width: 767px) {
  .p-point__list {
    grid-template-columns: repeat(1, 1fr);
    margin-top: 9.3333333333vw;
    gap: 8.8vw;
  }
}

.p-point__list + .p-point__list::after {
  position: absolute;
  content: "";
  display: block;
  width: 66.8125rem;
  height: 75.375rem;
  background-image: url(../img/icon/icon_line.png);
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  top: -3.2%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}
@media screen and (max-width: 767px) {
  .p-point__list + .p-point__list::after {
    display: none;
  }
}

.p-point__list + .p-point__list {
  margin-top: 5.5625rem;
}
@media screen and (max-width: 767px) {
  .p-point__list + .p-point__list {
    margin-top: 8.8vw;
  }
}

.p-point__item {
  max-width: 31.125rem;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .p-point__item {
    max-width: unset;
    width: 100%;
  }
}

.p-point__item-img-wrapper {
  position: relative;
}

.p-point__item:first-child .p-point__item-img {
  margin-left: 2.4375rem;
}
@media screen and (max-width: 767px) {
  .p-point__item:first-child .p-point__item-img {
    margin-left: 0;
  }
}

.p-point__item-img {
  margin-top: 4.0625rem;
  margin-left: 3.25rem;
  width: 28.75rem;
}
@media screen and (max-width: 767px) {
  .p-point__item-img {
    margin-top: 8.6666666667vw;
    margin-left: 0;
    width: 100%;
    border-radius: 5.3333333333vw;
  }
}

.p-point__item-img img {
  width: 100%;
  aspect-ratio: 460/280;
  object-fit: contain;
}
@media screen and (max-width: 767px) {
  .p-point__item-img img {
    aspect-ratio: 690/388;
  }
}

.p-point__item-num {
  position: absolute;
  top: -4.0625rem;
  left: 0rem;
  width: 100%;
  width: 7.5rem;
  height: 7.5rem;
}
@media screen and (max-width: 767px) {
  .p-point__item-num {
    top: -14vw;
    left: -4vw;
    width: 26.1333333333vw;
    height: 26.1333333333vw;
  }
}

.p-point__item-num img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.p-point__item-txt-wrapper {
  max-width: 28.75rem;
  width: 100%;
  margin-left: 3.25rem;
  margin-top: 2.3125rem;
}
@media screen and (max-width: 767px) {
  .p-point__item-txt-wrapper {
    max-width: unset;
    width: 100%;
    margin-left: 0;
    margin-top: 2.9333333333vw;
  }
}

.p-point__item:first-child .p-point__item-txt-wrapper {
  margin-left: 2.4375rem;
}
@media screen and (max-width: 767px) {
  .p-point__item:first-child .p-point__item-txt-wrapper {
    margin-left: 0;
  }
}

.p-point__item-ttl {
  font-size: max(1.625rem, 10px);
  color: #e60013;
  font-weight: 700;
  letter-spacing: 0.11em;
  line-height: 1;
}
@media screen and (max-width: 767px) {
  .p-point__item-ttl {
    font-size: 5.6vw;
    line-height: 1.4523809524;
  }
}

.p-point__item-txt {
  margin-top: 1.25rem;
  font-size: max(1rem, 10px);
  line-height: 2.25;
  letter-spacing: 0.09em;
}
@media screen and (max-width: 767px) {
  .p-point__item-txt {
    margin-top: 3.2vw;
    font-size: 3.4666666667vw;
    line-height: 2.5384615385;
  }
}

.p-point__item-txt span {
  font-weight: 700;
}

.p-price.p-price--rental {
  margin-top: 3.125rem;
}
@media screen and (max-width: 767px) {
  .p-price.p-price--rental {
    margin-top: 12vw;
  }
}

.p-price__top-txt {
  margin-top: 0.4375rem;
  letter-spacing: 0.09em;
  font-weight: 400;
  font-size: var(--fz16);
  line-height: 1.875;
}
@media screen and (max-width: 767px) {
  .p-price__top-txt {
    margin-top: 4.6666666667vw;
    font-size: 3.2vw;
    letter-spacing: 0.06em;
    line-height: 1.9166666667;
  }
}

.p-price__top-txt + .p-price__top-txt {
  margin-top: 0;
}

.p-price__container {
  margin-top: 1.375rem;
}
@media screen and (max-width: 767px) {
  .p-price__container {
    margin-top: 4.4vw;
  }
}

.p-price__item {
  cursor: pointer;
}

/*個人料金*/
.p-price__item:nth-child(3) .p-details {
  padding-bottom: 3.5625rem;
  margin-bottom: 0;
}
@media screen and (max-width: 767px) {
  .p-price__item:nth-child(3) .p-details {
    padding-bottom: 7.6vw;
    margin-bottom: 4vw;
  }
}

.p-charge__container.p-charge__container--rental .p-price__item:nth-child(3) .p-details__item-container {
  margin-top: 1.5rem;
  padding-bottom: 1rem;
}
@media screen and (max-width: 767px) {
  .p-charge__container.p-charge__container--rental .p-price__item:nth-child(3) .p-details__item-container {
    margin-top: 2.9333333333vw;
    padding-bottom: 6.1333333333vw;
  }
}

@media screen and (max-width: 767px) {
  .p-price__item:nth-child(3) .p-details__item-container {
    margin-top: 2.9333333333vw;
    padding-bottom: 2.1333333333vw;
  }
}

.p-price__item:nth-child(3) .p-details__item-wrapper + .p-details__item-wrapper {
  margin-top: 1.375rem;
}
@media screen and (max-width: 767px) {
  .p-price__item:nth-child(3) .p-details__item-wrapper + .p-details__item-wrapper {
    margin-top: 3.8666666667vw;
  }
}

.p-price__item:nth-child(3) .p-details__item-wrapper {
  padding-bottom: 1.3125rem;
}
@media screen and (max-width: 767px) {
  .p-price__item:nth-child(3) .p-details__item-wrapper {
    padding-bottom: 3.8666666667vw;
  }
}

/*設備使用料*/
.p-price__item:nth-child(4) .p-details {
  padding-bottom: 3.5625rem;
  margin-top: 2.125rem;
}
@media screen and (max-width: 767px) {
  .p-price__item:nth-child(4) .p-details {
    padding-bottom: 6.1333333333vw;
    margin-top: 4.5333333333vw;
  }
}

.p-price__item:nth-child(4) .p-details__item-container {
  margin-top: 0;
  padding: 2.8125rem 2.40625rem 2.375rem 2rem;
}
@media screen and (max-width: 767px) {
  .p-price__item:nth-child(4) .p-details__item-container {
    margin-top: 0;
    padding: 6vw 5.1333333333vw 1.4666666667vw 4.2666666667vw;
  }
}

.p-price__item:nth-child(4) .p-details__item-wrapper {
  padding-bottom: 1.6875rem;
  gap: 2.8125rem;
}
@media screen and (max-width: 767px) {
  .p-price__item:nth-child(4) .p-details__item-wrapper {
    padding-bottom: 3.4666666667vw;
    gap: 4.9333333333vw;
  }
}

.p-price__item:not(:first-child) {
  margin-top: 1.5rem;
}
@media screen and (max-width: 767px) {
  .p-price__item:not(:first-child) {
    margin-top: 6.1333333333vw;
  }
}

.p-price__item-ttl-wrapper {
  position: relative;
  padding: 0.8125rem 1.75rem 0.8125rem 1.5625rem;
  background-color: #4d4d4d;
  border-radius: 0.5rem;
  min-height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media screen and (max-width: 767px) {
  .p-price__item-ttl-wrapper {
    min-height: 12.8vw;
    padding: 4.2666666667vw 4vw 4.2666666667vw 3.3333333333vw;
  }
}

.p-price__item-ttl {
  font-size: var(--fz18);
  line-height: 1.4444444444;
  font-weight: 700;
  letter-spacing: 0.04em;
  color: #fff;
  display: flex;
  align-items: center;
  height: inherit;
}
@media screen and (max-width: 767px) {
  .p-price__item-ttl {
    font-size: 2.9333333333vw;
    line-height: 1.4545454545;
  }
}

.p-price__item-ttl-icon {
  display: block;
}

.p-price__item-ttl-icon svg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.p-price__item-ttl-icon--miyako {
  width: 2.2025rem;
  margin-right: 1rem;
}
@media screen and (max-width: 767px) {
  .p-price__item-ttl-icon--miyako {
    width: 4.6986666667vw;
    margin-right: 2.1333333333vw;
  }
}

.p-price__item-ttl-icon--ppl {
  width: 1.91875rem;
  margin-right: 0.9375rem;
  margin-left: 0.3125rem;
}
@media screen and (max-width: 767px) {
  .p-price__item-ttl-icon--ppl {
    width: 4.0933333333vw;
    margin-right: 2.2666666667vw;
    margin-left: 0.5333333333vw;
  }
}

.p-price__item-ttl-icon--person {
  width: 1.169375rem;
  margin-right: 1.4375rem;
  margin-left: 0.625rem;
}
@media screen and (max-width: 767px) {
  .p-price__item-ttl-icon--person {
    width: 2.4946666667vw;
    margin-right: 3.0666666667vw;
    margin-left: 1.3333333333vw;
  }
}

.p-price__item-ttl-icon--speaker {
  width: 1.090625rem;
  margin-right: 1.375rem;
  margin-left: 0.75rem;
}
@media screen and (max-width: 767px) {
  .p-price__item-ttl-icon--speaker {
    width: 2.3266666667vw;
    margin-right: 3.0666666667vw;
    margin-left: 1.7333333333vw;
  }
}

.p-price__item-ttl-icon--table {
  width: 1.561875rem;
  margin-right: 1.3125rem;
  margin-left: 0.3125rem;
}
@media screen and (max-width: 767px) {
  .p-price__item-ttl-icon--table {
    width: 3.332vw;
    margin-right: 2.9333333333vw;
    margin-left: 0.5333333333vw;
  }
}

.p-price__item-ttl-icon--sport {
  width: 1.535rem;
  margin-right: 1.375rem;
  margin-left: 0.3125rem;
}
@media screen and (max-width: 767px) {
  .p-price__item-ttl-icon--sport {
    width: 3.468vw;
    margin-right: 2.8vw;
    margin-left: 0.5333333333vw;
  }
}

.p-price__item-ttl-icon--other {
  width: 0.968125rem;
  margin-right: 1.9375rem;
  margin-left: 0.3125rem;
}
@media screen and (max-width: 767px) {
  .p-price__item-ttl-icon--other {
    width: 2.5026666667vw;
    margin-right: 2.9333333333vw;
    margin-left: 1.4666666667vw;
  }
}

.p-price__btn {
  display: flex;
  align-items: center;
  height: inherit;
}
@media screen and (max-width: 767px) {
  .p-price__btn {
    right: 4vw;
  }
}

/*会議室*/
.p-price.p-price--rental .p-details__item-wrapper {
  margin-top: 1.1875rem;
  padding-bottom: 1.1875rem;
  gap: 2.8125rem;
}
@media screen and (max-width: 767px) {
  .p-price.p-price--rental .p-details__item-wrapper {
    gap: 3.6vw;
    margin-top: 4vw;
    padding-bottom: 4vw;
  }
}

.p-price.p-price--rental .p-details__item-container {
  margin-top: 0;
  padding: 2.0625rem 2.40625rem 1.1875rem 2rem;
}
@media screen and (max-width: 767px) {
  .p-price.p-price--rental .p-details__item-container {
    padding: 2vw 5.1333333333vw 6.2666666667vw 5.0666666667vw;
  }
}

.p-program {
  padding-top: 2.5rem;
  padding-bottom: 3.75rem;
}
@media screen and (max-width: 767px) {
  .p-program {
    padding-top: 8vw;
    padding-bottom: 0;
  }
}

.p-program__ttl.c-sec-ttl.c-sec-ttl--head::after {
  letter-spacing: 0.1em;
}
@media screen and (max-width: 767px) {
  .p-program__ttl.c-sec-ttl.c-sec-ttl--head::after {
    letter-spacing: 0;
  }
}

.p-program__bg {
  background-position: top left;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #F9E9EA;
  margin-top: 6.25rem;
  padding-bottom: 4.125rem;
}
@media screen and (max-width: 767px) {
  .p-program__bg {
    margin-top: 25.0666666667vw;
    padding-bottom: 23.3333333333vw;
  }
}

.p-program__bg::before,
.p-program__bg::after {
  content: "";
  display: block;
  width: 100%;
  height: 7rem;
  position: absolute;
  left: 0;
  background-size: 100% 100%;
  background-position: top left;
  background-repeat: no-repeat;
}
@media screen and (max-width: 767px) {
  .p-program__bg::before,
  .p-program__bg::after {
    height: 15.6vw;
  }
}

.p-program__bg::before {
  top: -5.8125rem;
  background-image: url(../img/bg_top.png);
}
@media screen and (max-width: 767px) {
  .p-program__bg::before {
    top: -15.4666666667vw;
    background-image: url(../img/bg_top-sp.png);
  }
}

.p-program__bg::after {
  bottom: -6.875rem;
  background-position: bottom left;
  background-image: url(../img/bg_bottom.png);
}
@media screen and (max-width: 767px) {
  .p-program__bg::after {
    background-image: url(../img/bg_bottom-sp.png);
    bottom: -14.6666666667vw;
  }
}

.p-program__container {
  background-color: #fff;
  padding: 3.75rem 3.5rem 5.125rem 4.5625rem;
  border-radius: 2.25rem;
}
@media screen and (max-width: 767px) {
  .p-program__container {
    margin-top: -12vw;
    padding: 9.0666666667vw 4.6vw 12vw 4.7333333333vw;
  }
}

.p-program__sub-ttl {
  font-size: 1.625rem;
  font-weight: 700;
  line-height: 1.7692307692;
  letter-spacing: 0.11em;
  position: relative;
  z-index: 1;
  top: -3.625rem;
}
@media screen and (max-width: 767px) {
  .p-program__sub-ttl {
    font-size: 5.6vw;
    line-height: 1.5714285714;
    letter-spacing: 0.06em;
    top: -20.8vw;
  }
}

.p-program__sub-ttl span {
  display: block;
  color: #e60013;
}

.p-program__top-img {
  margin-top: -1.1875rem;
  max-width: 68.6875rem;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .p-program__top-img {
    margin-top: -15.0666666667vw;
  }
}

.p-program__top-img img {
  width: 100%;
  object-fit: contain;
  aspect-ratio: 1100/400;
}
@media screen and (max-width: 767px) {
  .p-program__top-img img {
    aspect-ratio: 690/462;
  }
}

.p-program__wrapper {
  margin-top: 3.5rem;
  display: flex;
  max-width: 68.75rem;
  width: 100%;
  gap: 4.8125rem;
}
@media screen and (max-width: 767px) {
  .p-program__wrapper {
    margin-top: 6.9333333333vw;
    flex-direction: column;
    gap: 5.0666666667vw;
  }
}

.p-program__wrapper-img {
  max-width: 32.625rem;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .p-program__wrapper-img {
    max-width: unset;
  }
}

.p-program__wrapper-img img {
  aspect-ratio: 522/206;
  width: 100%;
  object-fit: contain;
}
@media screen and (max-width: 767px) {
  .p-program__wrapper-img img {
    aspect-ratio: 690/274;
    border-radius: 4vw;
  }
}

.p-program__wrapper-txt {
  max-width: 28.4375rem;
  width: 100%;
  font-size: var(--fz16);
  font-weight: 400;
  line-height: 2.25;
  letter-spacing: 0.09em;
}
@media screen and (max-width: 767px) {
  .p-program__wrapper-txt {
    font-size: 3.4666666667vw;
    line-height: 2.5384615385;
    max-width: unset;
  }
}

.p-program__wrapper-txt span {
  font-weight: 700;
}

.p-program__detail {
  margin-top: 3.625rem;
  background-color: #fff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  padding: 1.5625rem 1.25rem;
  border-radius: 2.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .p-program__detail {
    margin-top: 9.2vw;
    padding: 4vw 2vw;
    border-radius: 4.8vw;
  }
}

.p-program__detail-img {
  max-width: 42.75rem;
  width: 100%;
}

.p-program__detail-img img {
  width: 100%;
  object-fit: contain;
  aspect-ratio: 684/304;
}
@media screen and (max-width: 767px) {
  .p-program__detail-img img {
    aspect-ratio: 661/294;
  }
}

.p-program__detail-txt {
  max-width: 42.75rem;
  width: 100%;
  margin-top: 3.1875rem;
  font-size: var(--fz16);
  font-weight: 400;
  line-height: 2.25;
  letter-spacing: 0.09em;
  margin-inline: auto;
}
@media screen and (max-width: 767px) {
  .p-program__detail-txt {
    margin-top: 4.8vw;
    font-size: 3.4666666667vw;
    line-height: 2.5384615385;
  }
}

.p-program__detail-btn {
  display: flex;
  justify-content: center;
  margin-top: 2.375rem;
  max-width: 43.125rem;
  width: 100%;
  margin-inline: auto;
}
@media screen and (max-width: 767px) {
  .p-program__detail-btn {
    margin-top: 4.8vw;
  }
}

.p-program__detail-btn img {
  width: 100%;
  object-fit: contain;
  aspect-ratio: 690/282;
}
@media screen and (max-width: 767px) {
  .p-program__detail-btn img {
    aspect-ratio: 690/282;
  }
}

@media (any-hover: hover) {
  .p-program__detail-btn:hover {
    scale: 1.025;
  }
}
.p-program__bottom-wrapper {
  margin-top: 3rem;
}
@media screen and (max-width: 767px) {
  .p-program__bottom-wrapper {
    margin-top: 6.4vw;
  }
}

@media screen and (max-width: 767px) {
  .p-program__bottom-inner {
    padding: 0;
  }
}

.p-program__bottom-txt {
  font-size: var(--fz16);
  font-weight: 500;
  line-height: 2.25;
  letter-spacing: 0.09em;
  margin-top: 11.625rem;
  margin-left: 4.375rem;
  margin-right: auto;
}
@media screen and (max-width: 767px) {
  .p-program__bottom-txt {
    margin-top: 26.9333333333vw;
    font-size: 3.4666666667vw;
    line-height: 2.5384615385;
    padding: 0 4vw;
    margin-left: 0;
  }
}

@media screen and (max-width: 767px) {
  .p-program__heading {
    padding: 0 4vw;
  }
}

.p-program__bottom-container {
  background-image: url(../img/program/bg_usage.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: auto;
  padding: 3.125rem 5.25rem 5.625rem;
  border-radius: 2.25rem;
  margin-top: 1.5625rem;
}
@media screen and (max-width: 767px) {
  .p-program__bottom-container {
    background-image: url(../img/program/bg_usage-sp.png);
    padding: 11.6vw 0 14.1333333333vw;
    width: 100vw;
    background-size: 100% 100%;
    border-radius: 0;
    margin: 8.6666666667vw calc(50% - 50vw) 0;
  }
}

.p-program__list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.75rem 2.875rem;
  margin-top: 1.75rem;
}
@media screen and (max-width: 767px) {
  .p-program__list {
    grid-template-columns: repeat(2, 1fr);
    margin-top: 5.6vw;
    gap: 9.0666666667vw 4vw;
    padding: 0 4vw;
  }
}

.p-program__item-img img {
  width: 100%;
  object-fit: contain;
}

.p-program__item-txt {
  margin-top: 0.375rem;
  font-size: var(--fz16);
  font-weight: 700;
  line-height: 2.25;
  letter-spacing: 0.09em;
}
@media screen and (max-width: 767px) {
  .p-program__item-txt {
    font-size: 2.9333333333vw;
    line-height: 1.5454545455;
    margin-top: 2vw;
  }
}

.p-room {
  background-color: #fff;
  margin-top: 2.8125rem;
}
@media screen and (max-width: 767px) {
  .p-room {
    margin-top: 25.3333333333vw;
  }
}

@media screen and (max-width: 767px) {
  .p-room__ttl.c-sec-ttl.c-sec-ttl--small {
    letter-spacing: 0.06em;
  }
}

.p-room__txt {
  max-width: 60rem;
  width: 100%;
  margin-left: 4.375rem;
  font-size: max(1rem, 10px);
  line-height: 1.875;
  letter-spacing: 0.09em;
  margin-top: 2.875rem;
}
@media screen and (max-width: 767px) {
  .p-room__txt {
    margin-left: 0;
    margin-top: 6.1333333333vw;
    font-size: 3.4666666667vw;
    line-height: 2.5384615385;
  }
}

.p-room__list {
  display: flex;
  justify-content: center;
  column-gap: 2.25rem;
  margin-top: 4.25rem;
}
@media screen and (max-width: 767px) {
  .p-room__list {
    flex-direction: column;
    margin-top: 14.6666666667vw;
    gap: 11.0666666667vw;
  }
}

.p-room__item {
  max-width: 28.75rem;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .p-room__item {
    max-width: 100%;
  }
}

.p-room__item:nth-child(2) {
  margin-top: 2.5rem;
}
@media screen and (max-width: 767px) {
  .p-room__item:nth-child(2) {
    margin-top: 0;
  }
}

.p-room__item:nth-child(2) .p-room__item-room {
  top: -2.625rem;
}
@media screen and (max-width: 767px) {
  .p-room__item:nth-child(2) .p-room__item-room {
    top: -6.6666666667vw;
  }
}

.p-room__item-img-wrapper {
  width: 100%;
  position: relative;
}

.p-room__item-img {
  width: 100%;
  aspect-ratio: 460/280;
  object-fit: contain;
}
@media screen and (max-width: 767px) {
  .p-room__item-img {
    aspect-ratio: 690/428;
  }
}

.p-room__item-room {
  margin-top: 0.625rem;
  font-weight: 700;
  font-size: max(1.25rem, 10px);
  line-height: 1.8125;
  display: block;
  background-color: #fff;
  border-radius: 1.875rem;
  position: absolute;
  top: -4.375rem;
  left: -1.875rem;
  width: 10.375rem;
  color: #e60013;
  height: 5rem;
  padding-left: 3.5625rem;
  padding-top: 1.9375rem;
  padding-bottom: 1.25rem;
}
@media screen and (max-width: 767px) {
  .p-room__item-room {
    font-size: 3.7333333333vw;
    left: -4vw;
    width: 43.7333333333vw;
    height: unset;
    padding-top: 6.6666666667vw;
    padding-bottom: 4.1333333333vw;
    padding-left: 14vw;
    top: -6.6666666667vw;
    border-radius: 4vw;
  }
}

.p-room__item-txt-wrapper {
  margin-top: 0.75rem;
}
@media screen and (max-width: 767px) {
  .p-room__item-txt-wrapper {
    margin-top: 4vw;
    position: relative;
    z-index: 1;
  }
}

.p-room__item-outline {
  font-weight: 700;
  font-size: max(1rem, 10px);
  line-height: 1.5;
  letter-spacing: 0.06em;
  color: #e60013;
  padding-bottom: 0.625rem;
  border-bottom: 1px dashed #f4a5ac;
}
@media screen and (max-width: 767px) {
  .p-room__item-outline {
    font-size: 3.4666666667vw;
    line-height: 1.4230769231;
  }
}

.p-room__item-txt {
  margin-top: 0.8125rem;
  font-size: max(1rem, 10px);
  line-height: 1.875;
  letter-spacing: 0.09em;
  font-weight: 500;
}
@media screen and (max-width: 767px) {
  .p-room__item-txt {
    font-size: 3.4666666667vw;
    line-height: 1.7692307692;
    margin-top: 1.3333333333vw;
  }
}

.p-top-about {
  background-color: #fff;
  padding-top: 4.1875rem;
}
@media screen and (max-width: 767px) {
  .p-top-about {
    padding-top: 6vw;
  }
}

.p-top-about__bg {
  background-image: url(../img/about_bg-pc.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  min-height: 55.5rem;
}
@media screen and (max-width: 767px) {
  .p-top-about__bg {
    background-image: url(../img/about_bg-sp.png);
    min-height: 237.6vw;
    background-size: cover;
  }
}

.p-top-about__ttl.c-sec-ttl {
  color: #151515;
  margin-left: 50%;
  margin-right: auto;
  padding-top: 0.4375rem;
}
@media screen and (max-width: 767px) {
  .p-top-about__ttl.c-sec-ttl {
    margin-left: 0;
    margin-right: 0;
    padding-top: 6.1333333333vw;
  }
}

.p-top-about__wrapper {
  display: flex;
  justify-content: center;
  margin-top: 1.875rem;
}
@media screen and (max-width: 767px) {
  .p-top-about__wrapper {
    margin-top: -1.3333333333vw;
    flex-direction: column;
  }
}

.p-top-about__img-wrapper {
  max-width: 34.6875rem;
  width: 100%;
  margin-top: 0.875rem;
  margin-left: -1.125rem;
}
@media screen and (max-width: 767px) {
  .p-top-about__img-wrapper {
    max-width: 72.6666666667vw;
    order: 2;
    margin-inline: auto;
    margin-top: -7.3333333333vw;
  }
}

.p-top-about__img-wrapper img {
  width: 100%;
  aspect-ratio: 493/363;
  object-fit: contain;
}
@media screen and (max-width: 767px) {
  .p-top-about__img-wrapper img {
    aspect-ratio: 488/360;
  }
}

.p-top-about__content {
  max-width: 28.25rem;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .p-top-about__content {
    display: contents;
  }
}

.p-top-about__heading {
  margin-left: -2.6875rem;
  font-size: 1.625rem;
  letter-spacing: 0.06em;
  font-weight: 700;
  line-height: 1.4230769231;
  position: relative;
  z-index: 2;
}
@media screen and (max-width: 767px) {
  .p-top-about__heading {
    margin-left: 0;
    order: 1;
    font-size: 5.6vw;
    line-height: 2.0476190476;
    margin-top: 5.4666666667vw;
  }
}

.p-top-about__heading span {
  color: #e60013;
  display: inline-block;
  position: relative;
  z-index: 1;
  --clipValue: inset(0 100% 0 0);
}

.p-top-about__heading span:after {
  content: "";
  display: block;
  width: 97%;
  height: 0.625rem;
  background: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
  border-radius: 1.25rem;
  display: block;
  width: 97%;
  clip-path: var(--clipValue);
  transition: clip-path 0.3s ease-out;
}
@media screen and (max-width: 767px) {
  .p-top-about__heading span:after {
    height: 4.2666666667vw;
    border-radius: 1.3333333333vw;
    bottom: 2.1333333333vw;
  }
}

.p-top-about__txt-wrapper {
  margin-top: 2.6875rem;
  margin-left: 1.5625rem;
}
@media screen and (max-width: 767px) {
  .p-top-about__txt-wrapper {
    margin-left: 0;
    order: 3;
    max-width: 78vw;
    width: 100%;
    margin-inline: auto;
    margin-top: 0.8vw;
  }
}

.p-top-about__txt {
  font-size: max(1rem, 10px);
  letter-spacing: 0.09em;
  font-weight: 400;
  line-height: 1.875;
}
@media screen and (max-width: 767px) {
  .p-top-about__txt {
    font-size: 3.2vw;
    letter-spacing: 0.15em;
    line-height: 2.25;
  }
}

.p-top-about__txt span {
  color: #e60013;
  font-size: 1.375rem;
  letter-spacing: 0.09em;
  font-weight: 700;
  position: relative;
  z-index: 1;
  --clipValue: inset(0 100% 0 0);
}
@media screen and (max-width: 767px) {
  .p-top-about__txt span {
    font-size: 4.2666666667vw;
  }
}

.p-top-about__txt span:after {
  content: "";
  display: block;
  width: 100%;
  height: 0.625rem;
  background: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
  border-radius: 1.25rem;
  clip-path: var(--clipValue);
  transition: clip-path 0.3s ease-out;
}
@media screen and (max-width: 767px) {
  .p-top-about__txt span:after {
    height: 3.3333333333vw;
    border-radius: 0.9333333333vw;
  }
}

.p-top-about__txt + .p-top-about__txt {
  margin-top: 0.4375rem;
}
@media screen and (max-width: 767px) {
  .p-top-about__txt + .p-top-about__txt {
    margin-top: 2.8vw;
  }
}

.p-top-about__link {
  max-width: 17.75rem;
  width: 100%;
  margin-top: 3.875rem;
  font-size: max(0.875rem, 10px);
}
@media screen and (max-width: 767px) {
  .p-top-about__link {
    margin-top: 6.6666666667vw;
    max-width: 80vw;
    min-width: 80vw;
    font-size: 3.2vw;
    min-height: 13.3333333333vw;
    border-radius: 6.6666666667vw;
    padding-left: 8vw;
  }
}

@media screen and (max-width: 767px) {
  .p-top-about__link:after {
    width: 4.5333333333vw;
    height: 4.5333333333vw;
  }
}

.p-top-about__bottom-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 2.25rem;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .p-top-about__bottom-wrapper {
    margin-top: 5.4666666667vw;
  }
}

.p-top-facility {
  background-color: #fff;
  padding-top: 5.625rem;
}
@media screen and (max-width: 767px) {
  .p-top-facility {
    padding-top: 13.0666666667vw;
  }
}

.p-top-facility__wrapper {
  padding: 6.3125rem 6.25rem 4.03125rem;
}
@media screen and (max-width: 767px) {
  .p-top-facility__wrapper {
    padding: 12.1333333333vw 4vw 15.4666666667vw;
  }
}

.p-top-facility__img-modal-link {
  max-width: 56.25rem;
  width: 100%;
  position: relative;
  z-index: 1;
}

.p-top-facility__img-modal-link img {
  aspect-ratio: 900/464;
  width: 100%;
  object-fit: contain;
  border-radius: 2.25rem;
}
@media screen and (max-width: 767px) {
  .p-top-facility__img-modal-link img {
    aspect-ratio: 690/464;
    border-radius: 3.4666666667vw;
  }
}

.p-top-facility__icon {
  width: 1.875rem;
  height: 1.875rem;
  position: absolute;
  bottom: 1.75rem;
  right: 3.5rem;
  z-index: 1;
  transition: scale 0.3s ease-in-out;
}
@media screen and (max-width: 767px) {
  .p-top-facility__icon {
    width: 4vw;
    height: 4vw;
    right: 6.4vw;
    bottom: 3.7333333333vw;
  }
}

.p-top-facility__icon > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 0;
}

@media (any-hover: hover) {
  .p-top-facility__img-modal-link:hover .p-top-facility__icon {
    scale: 1.2;
  }
}
.p-top-facility__heading {
  margin-top: 1.21875rem;
  font-weight: 700;
  letter-spacing: 0.06em;
  font-size: 1.625rem;
  line-height: 1.4230769231;
}
@media screen and (max-width: 767px) {
  .p-top-facility__heading {
    margin-top: 5.4666666667vw;
    font-size: 5.6vw;
    line-height: 1.5714285714;
  }
}

.p-top-facility__img-modal-link {
  display: block;
  width: 100%;
  margin-top: 2.375rem;
  border-radius: 2.25rem;
  cursor: pointer;
  position: relative;
}
@media screen and (max-width: 767px) {
  .p-top-facility__img-modal-link {
    margin-top: 6.8vw;
  }
}

.p-top-facility__lists {
  margin-top: 2.5rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3.8125rem 0.5rem;
}
@media screen and (max-width: 767px) {
  .p-top-facility__lists {
    margin-top: 6.6666666667vw;
    gap: 6.6666666667vw 3.4666666667vw;
  }
}

.p-top-facility__btn {
  max-width: 21.25rem;
  width: 100%;
  margin: 3.4375rem auto 0;
  font-size: max(0.875rem, 10px);
  font-weight: 700;
}
@media screen and (max-width: 767px) {
  .p-top-facility__btn {
    max-width: 80vw;
    margin-top: 9.3333333333vw;
    font-size: 3.2vw;
    border-radius: 6.6666666667vw;
    min-height: 13.3333333333vw;
    padding-left: 8vw;
  }
}

@media screen and (max-width: 767px) {
  .p-top-facility__btn::after {
    right: 3.6vw;
    width: 4.5333333333vw;
    height: 4.5333333333vw;
  }
}

.p-top-facility__img-wrapper {
  max-width: 27.875rem;
  width: 100%;
  position: relative;
}
@media screen and (max-width: 767px) {
  .p-top-facility__img-wrapper {
    max-width: 44.2666666667vw;
    border-radius: 3.0666666667vw;
  }
}

.p-top-facility__img-wrapper::before {
  content: "";
  position: absolute;
  bottom: -0.125rem;
  right: -0.0625rem;
  width: 2.5625rem;
  height: 2.5625rem;
  background-image: url(../img/icon/icon_arrow-right.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 1;
}
@media screen and (max-width: 767px) {
  .p-top-facility__img-wrapper::before {
    width: 8.2666666667vw;
    height: 8.2666666667vw;
    bottom: -0.4vw;
    right: -0.4vw;
  }
}

.p-top-facility__img-wrapper img {
  aspect-ratio: 444/208;
  object-fit: cover;
}
@media screen and (max-width: 767px) {
  .p-top-facility__img-wrapper img {
    aspect-ratio: 332/276;
  }
}

.p-top-facility__img-ttl {
  color: #e60013;
  margin-top: 1.0625rem;
  font-family: "Helvetica", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, sans-serif;
  font-weight: 700;
  font-size: 1.625rem;
  line-height: 1;
}
@media screen and (max-width: 767px) {
  .p-top-facility__img-ttl {
    margin-top: 1.3333333333vw;
    font-size: 3.7333333333vw;
    line-height: 1.4285714286;
  }
}

.p-top-facility__txt {
  margin-top: 0.0625rem;
  font-size: max(1rem, 10px);
  font-weight: 700;
  line-height: 1.5;
  position: relative;
  z-index: 1;
  display: inline-block;
}
@media screen and (max-width: 767px) {
  .p-top-facility__txt {
    margin-top: 0.4vw;
    font-size: 2.9333333333vw;
    line-height: 1.8181818182;
    display: inline;
  }
}

.p-top-facility__txt::before {
  content: "";
  position: absolute;
  bottom: -0.25rem;
  left: 0;
  width: calc(100% + 0.625rem);
  height: 50%;
  background: #fff;
  border-radius: 0.625rem;
  z-index: 0;
  display: inline-block;
}
@media screen and (max-width: 767px) {
  .p-top-facility__txt::before {
    display: none;
  }
}

.p-top-facility__txt span {
  display: inline;
  position: relative;
  z-index: 1;
}
@media screen and (max-width: 767px) {
  .p-top-facility__txt span {
    display: none;
  }
}

.p-top-facility__bottom-list {
  margin-top: 5rem;
  display: flex;
  gap: 3.125rem;
}
@media screen and (max-width: 767px) {
  .p-top-facility__bottom-list {
    margin-top: 12vw;
    gap: 3.4666666667vw;
  }
}

.p-top-facility__bottom-item {
  max-width: 32.25rem;
  width: 100%;
}

.p-top-facility__bottom-link {
  display: flex;
  align-items: center;
  gap: 1.25rem;
}
@media screen and (max-width: 767px) {
  .p-top-facility__bottom-link {
    gap: 2vw;
    flex-direction: column;
    align-items: flex-start;
  }
}

.p-top-facility__bottom-img-wrapper {
  max-width: 19rem;
  width: 100%;
  border-radius: 1.25rem;
  position: relative;
}
@media screen and (max-width: 767px) {
  .p-top-facility__bottom-img-wrapper {
    max-width: 44.2666666667vw;
    border-radius: 2.9333333333vw;
  }
}

.p-top-facility__bottom-img-wrapper::before {
  content: "";
  position: absolute;
  bottom: -0.125rem;
  right: -0.0625rem;
  width: 2.5625rem;
  height: 2.5625rem;
  background-image: url(../img/icon/icon_arrow-right.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 1;
}
@media screen and (max-width: 767px) {
  .p-top-facility__bottom-img-wrapper::before {
    width: 8.5333333333vw;
    height: 8.5333333333vw;
    bottom: -0.2666666667vw;
    right: -0.5333333333vw;
  }
}

.p-top-facility__bottom-img-wrapper img {
  border-radius: 1.25rem;
  aspect-ratio: 304/187;
  object-fit: cover;
  min-width: 19rem;
}
@media screen and (max-width: 767px) {
  .p-top-facility__bottom-img-wrapper img {
    aspect-ratio: 332/200;
    border-radius: 2.6666666667vw;
    min-width: unset;
  }
}

/* modal */
.p-top-facility__modal {
  max-width: 90rem;
  width: 100%;
  padding: 0.625rem 0;
  background: none;
}

.p-top-facility__modal-btn-wrapper.p-modal__btn-wrapper {
  right: 4%;
  top: -3%;
}
@media screen and (max-width: 767px) {
  .p-top-facility__modal-btn-wrapper.p-modal__btn-wrapper {
    right: 6.5%;
    top: -5%;
  }
}

.p-top-facility__modal-container {
  padding: 0 1.5625rem;
  max-width: 90rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .p-top-facility__modal-container {
    padding: 0 4vw;
  }
}

.p-top-facility__modal-img-wrapper {
  max-width: 90rem;
  width: 100%;
}

.p-top-facility__modal-img-wrapper img {
  width: 100%;
  object-fit: cover;
}

.p-top-mv {
  position: relative;
  background-color: #fff;
}

.p-top-mv__img-wrapper {
  position: relative;
}
@media screen and (max-width: 767px) {
  .p-top-mv__img-wrapper {
    max-height: 110vw;
  }
}

.p-top-mv__ttl {
  position: absolute;
  left: 19%;
  top: 9%;
  max-width: 8.5rem;
  width: 100%;
  z-index: 10;
}
@media screen and (max-width: 767px) {
  .p-top-mv__ttl {
    max-width: 27.8666666667vw;
    left: 5.6vw;
    top: 5.4666666667vw;
  }
}

.p-top-mv__ttl img {
  width: 100%;
  height: auto;
}

.p-top-mv__ttl-sub01 {
  width: 3.9375rem;
  position: absolute;
  top: 0;
  left: 55%;
  clip-path: inset(0 0 100% 0);
}
@media screen and (max-width: 767px) {
  .p-top-mv__ttl-sub01 {
    width: 12.8vw;
  }
}

.p-top-mv__ttl-sub02 {
  width: 3.9375rem;
  position: absolute;
  top: 2.9375rem;
  left: 0;
  clip-path: inset(0 0 100% 0);
}
@media screen and (max-width: 767px) {
  .p-top-mv__ttl-sub02 {
    width: 12.8vw;
    top: 9.7333333333vw;
  }
}

.p-top-mv__bnr {
  position: absolute;
  bottom: 3.625rem;
  right: 11rem;
  max-width: 19rem;
  width: 100%;
  z-index: 10;
  display: block;
  opacity: 0;
  transform: scale(1);
}
@media screen and (max-width: 767px) {
  .p-top-mv__bnr {
    position: static;
    max-width: unset;
    margin-top: 3.2vw;
    padding: 0 4vw;
  }
}

@media (any-hover: hover) {
  .p-top-mv__bnr:hover {
    transform: scale(1.025);
  }
}
.p-usage {
  background-color: #fff;
  margin-top: 8.125rem;
  padding-bottom: 0.625rem;
}
@media screen and (max-width: 767px) {
  .p-usage {
    margin-top: 21.3333333333vw;
    padding-bottom: 0;
  }
}

.p-usage__bg-wrapper {
  position: relative;
}

.p-usage__bg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.p-usage__ttl {
  color: #029595;
  text-align: center;
}

.p-usage__ttl::after {
  color: #fff;
}

.c-sec-ttl.p-usage__ttl {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media screen and (max-width: 767px) {
  .c-sec-ttl.p-usage__ttl {
    top: 64%;
  }
}

.p-usage__txt {
  font-size: max(1rem, 10px);
  letter-spacing: 0.09em;
  text-align: center;
  margin-top: 3.75rem;
}
@media screen and (max-width: 767px) {
  .p-usage__txt {
    margin-top: 8.4vw;
    font-size: 3.4666666667vw;
    line-height: 2.5384615385;
    text-align: left;
    padding-inline: 4vw;
  }
}

@media screen and (max-width: 767px) {
  .u-pc {
    display: none;
  }
}

.u-sp {
  display: none;
}
@media screen and (max-width: 767px) {
  .u-sp {
    display: block;
  }
}