@use "global" as *;

.p-news {
  background-color: $white;
  padding-top: rem(60);
  @include mq(md) {
    padding-top: vw-m(64);
  }
}

.p-news__wrapper {
  display: flex;
  gap: rem(103);
  @include mq(md) {
    flex-direction: column;
    gap: vw-m(42);
  }
}

.p-news__box {
  max-width: rem(860);
  width: 100%;
  border: 1px solid $black;
  padding: rem(42) rem(71) rem(60.8) rem(72.8);
  border-radius: rem(30);
  margin-top: rem(-6);
  @include mq(md) {
    margin-top: 0;
    padding: vw-m(59) vw-m(36.4) vw-m(72.3) vw-m(36.3);
  }
}

.p-news__list {
  max-width: rem(715);
  width: 100%;
}

.p-news__item {
  border-bottom: 1px dashed #D1D1D1;
  position: relative;

}

.p-news__item::after {
  content: "";
  display: block;
  background-image: url(../img/icon/icon_arrow-bk.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: rem(12);
  height: rem(9.82);
  margin-left: rem(10);
  position: absolute;
  right: rem(34);
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s ease-in-out;
  @include mq(md) {
    width: vw-m(15.97);
    height: vw-m(13.07);
    margin-left: vw-m(10);
    right: vw-m(12);
    top: 60%;
  }
}

.p-news__item.p-news__item--note {
  border-bottom: 1px dashed #f4a5ac;
  @include mq(md) {
    border-bottom: vw-m(2) dashed #f4a5ac;
  }
}

.p-news__item.p-news__item--note::after {
  background-image: url(../img/icon/icon_arrow.svg);
}

.p-news__item-link {
  display: block;
  padding-top: rem(15.5);
  transition: all 0.3s ease-in-out;
  padding-bottom: rem(18);
  @include mq(md) {
    padding-top: vw-m(20);
    max-width: vw-m(552);
    padding-bottom: vw-m(25.9);
  }
}

.p-news__item.p-news__item--note .p-news__item-link {
  color: $red;
}

.p-news__item-tag-wrapper {
  max-width: max(rem(102), 80px);
  width: 100%;
  @include mq(md) {
    max-width: max(vw-m(136), 80px);
  }
}

.p-news__item-tag {
  min-width: rem(102);
  width: 100%;
  border-radius: rem(6);
  padding: max(rem(7), 5px) rem(5);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: rem(6);
  background-color: #4d4d4d;
  color: $white;
  font-size: max(rem(12), 9px);
  line-height: 1;
  @include mq(md) {
    min-width: unset;
    font-size: max(vw-m(18), 9px);
    letter-spacing: -0.04em;
  }
}

.p-news__item-tag.p-news__item-tag--red {
  background-color: $red;
}

.p-news__item-ttl {
  display: inline-block;
  margin-top: rem(2);
  letter-spacing: 0.06em;
  font-weight: 500;
  color: $black;
  font-size: var(--fz16);
  transition: color 0.3s ease-in-out;
  @include mq(md) {
    margin-top: vw-m(6);
    font-size: max(vw-m(22), 10px);
    letter-spacing: 0.04em;
    text-align: left;
  }
}

.p-news__item-new {
  font-weight: 500;
  font-size: var(--fz16);
  letter-spacing: 0.06em;
  color: #5fbebe;
  display: inline-block;
  margin-top: rem(6);
  margin-left: rem(20);
  @include mq(md) {
    margin-top: vw-m(6);
    margin-left: vw-m(15);
    font-size: max(vw-m(22), 10px);
  }
}


/* modal */
.p-news__modal {
  max-width: rem(860);
  width: 100%;
  border: 1px solid #707070;
  padding: rem(42) rem(71) rem(39) rem(72.8);
  border-radius: rem(40);
  background: $white;
  @include mq(md) {
    max-width: vw-m(690);
    padding: vw-m(59) vw-m(36.4) vw-m(60) vw-m(36.3);
  }
}

.p-news__modal-container {
  background-color: $white;
  @include mq(md) {
    max-height: 75vh;
    overflow-y: scroll;
  }
}

.p-news__modal-btn {
  max-width: rem(284);
  font-size: max(rem(14), 10px);
  @include mq(md) {
    max-width: vw-m(340);
    font-size:  max(vw-m(20), 10px);
  }
}

.p-news__modal-ttl {
  color: $green;
  padding-bottom: rem(11);
  line-height: calc(35/24);
  font-size: rem(24);
  font-weight: 700;
  letter-spacing: 0.06em;
  border-bottom: 2px dashed #D1D1D1;
  @include mq(md) {
    padding-bottom: vw-m(11);
    font-size: vw-m(32);
  }
}

.p-news__modal-content {
  margin-top: rem(24.5);
  @include mq(md) {
    margin-top: vw-m(45);
  }
}

.p-news__modal-txt {
  font-size: max(rem(16), 10px);
  letter-spacing: 0.06em;
  font-weight: 500;
  @include mq(md) {
    font-size: vw-m(24);
  }
}

.p-news__modal-txt + .p-news__modal-txt {
  margin-top: 1em;
}

.p-news__modal-btn-wrapper {
  margin-top: rem(25);
  @include mq(md) {
    margin-top: vw-m(45);
  }
}

.p-news__modal-btn + .p-news__modal-btn {
  margin-top: rem(16);
  margin-bottom: rem(49);
  @include mq(md) {
    margin-top: vw-m(28);
    margin-bottom: vw-m(80);
  }
}


@media (any-hover: hover) {
  .p-news__item:hover .p-news__item-ttl {
    color: #029595;
  }
  .p-news__item:hover::after {
    background-image: url(../img/icon/icon_arrow-gl.svg);
    right: rem(24);
  }
  .p-news__item.p-news__item--note:hover  .p-news__item-ttl {
    color: #ff5362;
  }
  .p-news__item.p-news__item--note:hover::after {
    background-image: url(../img/icon/icon_arrow-pink.svg);
  }
}