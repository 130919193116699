@use "global" as *;

.p-program {
  padding-top: rem(40);
  padding-bottom: rem(60);
  @include mq(md) {
    padding-top: vw-m(60);
    padding-bottom: 0;
  }
}

.p-program__ttl.c-sec-ttl.c-sec-ttl--head::after{
  letter-spacing: 0.1em;
  @include mq(md) {
    letter-spacing: 0;
  }
}

.p-program__bg {
  background-position: top left;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #F9E9EA;
  margin-top: rem(100);
  padding-bottom: rem(66);
  @include mq(md) {
    margin-top: vw-m(188);
    padding-bottom: vw-m(175);
  }
}

.p-program__bg::before,
.p-program__bg::after {
  content: "";
  display: block;
  width: 100%;
  height: rem(112);
  position: absolute;
  left: 0;
  background-size: 100% 100%;
  background-position: top left;
  background-repeat: no-repeat;
  @include mq(md) {
    height: vw-m(117);
  }
}

.p-program__bg::before {
  top: rem(-93);
  background-image: url(../img/bg_top.png);
  @include mq(md) {
    top: vw-m(-116);
    background-image: url(../img/bg_top-sp.png);
  }
}

.p-program__bg::after {
  bottom: rem(-110);
  background-position: bottom left;
  background-image: url(../img/bg_bottom.png);
  @include mq(md) {
    background-image: url(../img/bg_bottom-sp.png);
    bottom: vw-m(-110);
  }
}

.p-program__container {
  background-color: $white;
  padding: rem(60) rem(56) rem(82) rem(73);
  border-radius: rem(36);
  @include mq(md) {
    margin-top: vw-m(-90);
    padding: vw-m(68) vw-m(34.5) vw-m(90) vw-m(35.5);
  }
}

.p-program__sub-ttl {
  font-size: rem(26);
  font-weight: 700;
  line-height: calc(46 / 26);
  letter-spacing: 0.11em;
  position: relative;
  z-index: 1;
  top: rem(-58);
  @include mq(md) {
    font-size: vw-m(42);
    line-height: calc(66 / 42);
    letter-spacing: 0.06em;
    top: vw-m(-156);
  }
}

.p-program__sub-ttl span {
  display: block;
  color: $red;
}

.p-program__top-img {
  margin-top: rem(-19);
  max-width: rem(1099);
  width: 100%;
  @include mq(md) {
    margin-top: vw-m(-113);
  }
}

.p-program__top-img img {
  width: 100%;
  object-fit: contain;
  aspect-ratio: 1100 / 400;
  @include mq(md) {
    aspect-ratio: 690 / 462;
  }
}

.p-program__wrapper {
  margin-top: rem(56);
  display: flex;
  max-width: rem(1100);
  width: 100%;
  gap: rem(77);
  @include mq(md) {
    margin-top: vw-m(52);
    flex-direction: column;
    gap: vw-m(38);
  }
}

.p-program__wrapper-img {
  max-width: rem(522);
  width: 100%;
  @include mq(md) {
    max-width: unset;
  }
}

.p-program__wrapper-img img {
  aspect-ratio: 522 / 206;
  width: 100%;
  object-fit: contain;
  @include mq(md) {
    aspect-ratio: 690 / 274;
    border-radius: vw-m(30);
  }
}

.p-program__wrapper-txt {
  max-width: rem(455);
  width: 100%;
  font-size: var(--fz16);
  font-weight: 400;
  line-height: calc(36 / 16);
  letter-spacing: 0.09em;
  @include mq(md) {
    font-size: vw-m(26);
    line-height: calc(66/ 26);
    max-width: unset;
  }
}

.p-program__wrapper-txt span {
  font-weight: 700;
}

.p-program__detail {
  margin-top: rem(58);
  background-color: $white;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  padding: rem(25) rem(20);
  border-radius: rem(36);
  display: flex;
  justify-content: center;
  align-items: center;
  @include mq(md) {
    margin-top: vw-m(69);
    padding: vw-m(30) vw-m(15);
    border-radius: vw-m(36);
  }
}

.p-program__detail-img {
  max-width: rem(684);
  width: 100%;
}

.p-program__detail-img img {
  width: 100%;
  object-fit: contain;
  aspect-ratio: 684 / 304;
  @include mq(md) {
    aspect-ratio: 661 / 294;
  }
}

.p-program__detail-txt {
  max-width: rem(684);
  width: 100%;
  margin-top: rem(51);
  font-size: var(--fz16);
  font-weight: 400;
  line-height: calc(36 / 16);
  letter-spacing: 0.09em;
  margin-inline: auto;
  @include mq(md) {
    margin-top: vw-m(36);
    font-size: vw-m(26);
    line-height: calc(66 / 26);
  }
}

.p-program__detail-btn {
  display: flex;
  justify-content: center;
  margin-top: rem(38);
  max-width: rem(690);
  width: 100%;
  margin-inline: auto;
  @include mq(md) {
    margin-top: vw-m(36);
  }
}

.p-program__detail-btn img {
  width: 100%;
  object-fit: contain;
  aspect-ratio: 690 / 282;
  @include mq(md) {
    aspect-ratio: 690 / 282;
  }
}

@media (any-hover: hover) {
  .p-program__detail-btn:hover {
    scale: 1.025;
  }
}

.p-program__bottom-wrapper {
  margin-top: rem(48);
  @include mq(md) {
    margin-top: vw-m(48);
  }
}

.p-program__bottom-inner {
  @include mq(md) {
    padding: 0;
  }
}

.p-program__bottom-txt{
  font-size: var(--fz16);
  font-weight: 500;
  line-height: calc(36 / 16);
  letter-spacing: 0.09em;
  margin-top: rem(186);
  margin-left: rem(70);
  margin-right: auto;
  @include mq(md) {
    margin-top: vw-m(202);
    font-size: vw-m(26);
    line-height: calc(66 / 26);
    padding: 0 vw-m(30);
    margin-left: 0;
  }
}

.p-program__heading {
  @include mq(md) {
    padding: 0 vw-m(30);
  }
}

.p-program__bottom-container {
  background-image: url(../img/program/bg_usage.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: auto;
  padding: rem(50) rem(84) rem(90);
  border-radius: rem(36);
  margin-top: rem(25);
  @include mq(md) {
    background-image: url(../img/program/bg_usage-sp.png);
    padding: vw-m(87) 0 vw-m(106);
    width: 100vw;
    background-size: 100% 100%;
    border-radius: 0;
    margin: vw-m(65) calc(50% - 50vw) 0;
  }
}

.p-program__list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: rem(28) rem(46);
  margin-top: rem(28);
  @include mq(md) {
    grid-template-columns: repeat(2, 1fr);
    margin-top: vw-m(42);
    gap: vw-m(68) vw-m(30);
    padding: 0 vw-m(30);
  }
}

.p-program__item-img img {
  width: 100%;
  object-fit: contain;
}

.p-program__item-txt {
  margin-top: rem(6);
  font-size: var(--fz16);
  font-weight: 700;
  line-height: calc(36 / 16);
  letter-spacing: 0.09em;
  @include mq(md) {
    font-size: vw-m(22);
    line-height: calc(34 / 22);
    margin-top: vw-m(15);
  }
}