@use "global" as *;


.p-top-mv {
  position: relative;
  background-color: $white;
}

.p-top-mv__img-wrapper {
  position: relative;
  @include mq(md) {
    max-height: vw-m(825);
  }
}

.p-top-mv__ttl {
  position: absolute;
  left: 19%;
  top: 9%;
  max-width: rem(136);
  width: 100%;
  z-index: 10;
  @include mq(md) {
    max-width: vw-m(209);
    left: vw-m(42);
    top: vw-m(41);
  }
}

.p-top-mv__ttl img {
  width: 100%;
  height: auto;
}

.p-top-mv__ttl-sub01 {
  width: rem(63);
  position: absolute;
  top: 0;
  left: 55%;
  clip-path: inset(0 0 100% 0);
  @include mq(md) {
    width: vw-m(96);
  }
}

.p-top-mv__ttl-sub02 {
  width: rem(63);
  position: absolute;
  top: rem(47);
  left: 0;
  clip-path: inset(0 0 100% 0);
  @include mq(md) {
    width: vw-m(96);
    top: vw-m(73);
  }
}

.p-top-mv__bnr {
  position: absolute;
  bottom: rem(58);
  right: rem(176);
  max-width: rem(304);
  width: 100%;
  z-index: 10;
  display: block;
  opacity: 0;
  transform: scale(1);
  @include mq(md) {
    position: static;
    max-width: unset;
    margin-top: vw-m(24);
    padding: 0 vw-m(30);
  }
}

@media (any-hover: hover) {
  .p-top-mv__bnr:hover {
    transform: scale(1.025);
  }
}