@use "global" as *;


.p-point {
  padding-top: rem(80);
  padding-bottom: rem(50);
  position: relative;
  z-index: 1;
  @include mq(md) {
    padding-top: vw-m(130);
  }
}

.p-point__copy {
  font-size: max(rem(26), 10px);
  line-height: calc(37 / 26);
  font-weight: 700;
  letter-spacing: 0.11em;
  @include mq(md) {
    font-size: vw-m(42);
    letter-spacing: 0.06em;
    line-height: calc(66 / 42);
  }
}

.p-point__copy span {
  color: $red;
}

.p-point__list {
  margin-top: rem(40);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: rem(40);
  margin-bottom: 0;
  position: relative;
  @include mq(md) {
    grid-template-columns: repeat(1, 1fr);
    margin-top: vw-m(70);
    gap: vw-m(66);
  }
}

.p-point__list+.p-point__list::after {
  position: absolute;
  content: "";
  display: block;
  width: rem(1069);
  height: rem(1206);
  background-image: url(../img/icon/icon_line.png);
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  top: -3.2%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  @include mq(md) {
    display: none;
  }
}

.p-point__list + .p-point__list {
  margin-top: rem(89);
  @include mq(md) {
    margin-top: vw-m(66);
  }
}

.p-point__item {
  max-width: rem(498);
  width: 100%;
  @include mq(md) {
    max-width: unset;
    width: 100%;
  }
}

.p-point__item-img-wrapper {
  position: relative;
}

.p-point__item:first-child .p-point__item-img {
  margin-left: rem(39);
  @include mq(md) {
    margin-left: 0;
  }
}

.p-point__item-img {
  margin-top: rem(65);
  margin-left: rem(52);
  width: rem(460);
  @include mq(md) {
    margin-top: vw-m(65);
    margin-left:0;
    width: 100%;
    border-radius: vw-m(40);
  }
}

.p-point__item-img img {
  width: 100%;
  aspect-ratio: 460 / 280;
  object-fit: contain;
  @include mq(md) {
    aspect-ratio: 690/388;
  }
}

.p-point__item-num {
  position: absolute;
  top: rem(-65);
  left: rem(0);
  width: 100%;
  width: rem(120);
  height: rem(120);
  @include mq(md) {
    top: vw-m(-105);
    left: vw-m(-30);
    width: vw-m(196);
    height: vw-m(196);
  }
}

.p-point__item-num img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.p-point__item-txt-wrapper {
  max-width: rem(460);
  width: 100%;
  margin-left: rem(52);
  margin-top: rem(37);
  @include mq(md) {
    max-width: unset;
    width: 100%;
    margin-left: 0;
    margin-top: vw-m(22);
  }
}

.p-point__item:first-child .p-point__item-txt-wrapper {
  margin-left: rem(39);
  @include mq(md) {
    margin-left: 0;
  }
}

.p-point__item-ttl {
  font-size: max(rem(26), 10px);
  color: $red;
  font-weight: 700;
  letter-spacing: 0.11em;
  line-height: 1;
  @include mq(md) {
    font-size: vw-m(42);
    line-height: calc(61 / 42);
  }
}

.p-point__item-txt {
  margin-top: rem(20);
  font-size: max(rem(16), 10px);
  line-height: calc(36 / 16);
  letter-spacing: 0.09em;
  @include mq(md) {
    margin-top: vw-m(24);
    font-size: vw-m(26);
    line-height: calc(66 / 26);
  }
}

.p-point__item-txt span {
  font-weight: 700;
}