@use "global" as *;

.p-charge-info.p-charge__info--second {
  margin-top: rem(80);
  max-width: rem(968);
  width: 100%;
  margin-inline: auto;
  @include mq(md) {
    margin-top: vw-m(120);
  }
}

.p-charge-info.p-charge-info--rental {
  margin-top: 0;
}

.p-charge-info__link {
  color: $red;
  font-weight: 700;
  transition: opacity 0.3s ease-in-out;
  @include mq(md) {
    font-weight: 500;
  }
}

@media (any-hover: hover) {
  .p-charge-info__link:hover {
    opacity: 0.7;
  }
}

.p-charge-info__container {
  margin-top: rem(50);
  @include mq(md) {
    margin-top: vw-m(60);
  }
}

.p-charge__info .p-charge-info__container {
  max-width: rem(968);
  width: 100%;
  margin-left: auto;
  margin-top: rem(26);
  @include mq(md) {
    margin-top: 0;
  }
}

/*施設使用料に関する留意事項*/
.p-charge-info__container.p-charge-info__container--second {
  margin-top: 0;
}

.p-charge-info__container.p-charge-info__container--second .p-charge-info__list:first-child {
  margin-top: rem(19);
  padding-left: 0.4em;
  @include mq(md) {
    margin-top: vw-m(42);
  }
}

.p-charge-info.p-charge-info--rental .p-charge-info__container {
  margin-top: rem(34);
  @include mq(md) {
    margin-top: vw-m(60);
  }
}

.p-charge-info__list {
  padding-bottom: rem(13);
  border-bottom: 1px dashed #D1D1D1;
  @include mq(md) {
    padding-bottom: vw-m(40);
    border-bottom: vw-m(2) dashed #D1D1D1;
  }
}

.p-charge-info__list:first-child {
  margin-top: rem(26);
  @include mq(md) {
    margin-top: vw-m(46);
  }
}

.p-charge-info__list + .p-charge-info__list {
  margin-top: rem(24);
  @include mq(md) {
    margin-top: vw-m(42);
  }
}

.p-charge-info__ttl {
  color: $red;
  font-size: var(--fz16);
  font-weight: 700;
  letter-spacing: 0.06em;
  line-height: calc(30/16);
  @include mq(md) {
    font-size: vw-m(24);
    line-height: calc(35/24);
  }
}

.p-charge-info__txt {
  font-weight: 500;
  font-size: var(--fz16);
  line-height: calc(24/16);
  letter-spacing: 0.06em;
  margin-top: rem(10);
  @include mq(md) {
    font-size: vw-m(24);
    line-height: calc(35/24);
    margin-top: vw-m(18);
  }
}

.p-charge-info__bold {
  font-weight: 700;
  @include mq(md) {
    font-weight: 500;
  }
}

.p-charge-info__txt.p-charge-info__txt--space {
  max-width: rem(925);
  margin-left: auto;
  margin-right: rem(3);
  width: 100%;
  @include mq(md) {
    padding-left: 0;
  }
}

.p-charge-info__note {
  margin-top: rem(14);
  font-weight: 400;
  font-size: var(--fz16);
  line-height: calc(24/16);
  letter-spacing: 0.06em;
  @include mq(md) {
    font-size: vw-m(24);
    line-height: calc(46/24);
    margin-top: vw-m(33);
  }
}

.p-charge-info__item {
  padding-bottom: rem(13);
  border-bottom: 1px dashed #D1D1D1;
  @include mq(md) {
    padding-bottom: vw-m(30);
    border-bottom: vw-m(2) dashed #D1D1D1;
  }
}

.p-charge-info__heading {
  position: relative;
}

.p-charge-info__item-ttl {
  font-size: var(--fz16);
  line-height: calc(24/16);
  letter-spacing: 0.06em;
  color: $red;
  font-weight: 700;
  margin-top: rem(32);
  cursor: pointer;
  padding-left: 0.4em ;
  @include mq(md) {
    font-size: vw-m(24);
    line-height: calc(35/24);
    margin-top: vw-m(100);
    padding-left: 0;
  }
}

.p-charge-info__item-btn {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.p-charge-info__note-list {
  color: #4d4d4d;
  margin-top: rem(27);
  @include mq(md) {
    margin-top: vw-m(76);
  }
}

.p-charge-info__note-item {
  padding-left: rem(45);
  font-size: var(--fz16);
  line-height: calc(24/16);
  letter-spacing: 0.06em;
  font-weight: 500;
  counter-increment: number;
  display: flex;

  @include mq(md) {
    padding-left: 0;
    font-size: vw-m(24);
    line-height: calc(35/24);
    font-weight: 400;
  }
}

.p-charge-info__note-item.p-charge-info__note-item--wide {
  max-width: rem(925);
  flex-wrap: wrap;
}

.p-charge-info__note-item::before {
  content: "(" counter(number) ")";
  margin-right: rem(35);
  display: inline-block;
  @include mq(md) {
    margin-right: vw-m(30);
  }
}

.p-charge-info__note-item + .p-charge-info__note-item {
  margin-top: rem(8);
  @include mq(md) {
    margin-top: vw-m(35);
  }
}

.p-charge-info__sub-list {
  width: 100%;
  margin-top: rem(8);
  @include mq(md) {
    max-width: vw-m(600);
    margin-top: vw-m(44);
    margin-left: auto;
  }
}

.p-charge-info__sub-item {
  padding-left: rem(56);
  font-size: var(--fz16);
  line-height: calc(24/16);
  letter-spacing: 0.06em;
  font-weight: 500;
  counter-increment: sub-number;
  display: flex;
  max-width: rem(846);
  width: 100%;
  @include mq(md) {
    padding-left: 0;
    font-size: vw-m(24);
    line-height: calc(35/24);
    font-weight: 400;
  }
}

.p-charge-info__sub-item + .p-charge-info__sub-item {
  margin-top: rem(3);
  @include mq(md) {
    margin-top: vw-m(31);
  }
}

.p-charge-info__sub-item::before {
  content: "(" counter(sub-number,lower-roman) ")";
  margin-right: rem(18);
  @include mq(md) {
    margin-right: vw-m(26);
  }
}

.p-charge-info__sub-item:last-child::before {
  margin-right: rem(13);
  @include mq(md) {
    margin-right: vw-m(12);
  }
}
/*施設使用料に関する留意事項*/
.p-charge-info.p-charge__info--second .p-charge-info__txt{
  color: #4d4d4d;
  margin-top: rem(4);
  @include mq(md) {
    font-weight: 400;
    margin-top: vw-m(15);
  }
}

.p-charge-info__note-list{
  padding-left: 0.4em;
}

.p-charge-info__container.p-charge-info__container--second .p-charge-info__list {
  padding-left: 0.4em;
  @include mq(md) {
    padding-left: 0;
  }
}

.p-charge-info.p-charge__info--second .p-charge-info__list + .p-charge-info__list {
  margin-top: rem(14);
  @include mq(md) {
    margin-top: vw-m(40);
  }
}