@use "global" as *;

.l-main {
  width: 100%;
  padding-top: rem(104);
  background-color: $white;
  @include mq(md) {
    padding-top: vw-m(94);
  }
}
