@use "global" as *;


.p-details {
  background-color: $bgblue;
  margin-top: rem(30);
  padding: rem(30) rem(46) rem(36) rem(39);
  margin-bottom: rem(48);
  max-width: rem(975);
  width: 100%;
  margin-inline: auto;
  @include mq(md) {
    margin-top: vw-m(30);
    padding: vw-m(43) vw-m(28) vw-m(42);
    margin-bottom: vw-m(30);
  }
}

.p-details.p-details--blue {
  background-color: $bgblue;
  margin-bottom: rem(28);
  @include mq(md) {
    margin-bottom: vw-m(30);
  }
}

.p-details.p-details--pink {
  background-color: $bgpink;;
}

.p-details.p-details--gray {
  background-color: #F0F0F0;
}

.p-details__ttl {
  font-size: rem(20);
  letter-spacing: 0.06em;
  font-weight: 700;
  line-height: calc(29 / 20);
  @include mq(md) {
    font-size: vw-m(24);
  }
}

.p-details__ttl.p-details__ttl--blue {
  color: #1bbae2;
}

.p-details__ttl.p-details__ttl--pink {
  color: #ffa2aa;
}

.p-details__item + .p-details__item {
  margin-top: rem(59);
  @include mq(md) {
    margin-top: vw-m(31);
  }
}

.p-details__item-ttl {
  font-size: var(--fz18);
  letter-spacing: 0.06em;
  font-weight: 700;
  line-height: calc(26 / 18);
  margin-top: rem(22);
  @include mq(md) {
    margin-top: vw-m(16);
    font-size: vw-m(22);
  }
}

.p-details__head-wrapper {
  margin-top: rem(13);
  background-color: $white;
  border-radius: rem(24);
  padding: rem(10) rem(15);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: rem(60);
  min-height: rem(78);
  @include mq(md) {
    gap: vw-m(72);
    padding: vw-m(15) vw-m(32);
    justify-content: flex-start;
    min-height: unset;
  }
}

.p-details__item-price-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: rem(168);
  gap: rem(87);
  @include mq(md) {
    gap: vw-m(64);
    margin-left: vw-m(8);
  }
}

.p-details__item-price {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: rem(10);
  max-width: rem(180);
  width: 100%;
  @include mq(md) {
    max-width: vw-m(224);
    width: 100%;
    gap: vw-m(14);
    font-size: vw-m(22);
  }
}

.p-details__item-container .p-details__item-price {
  gap: rem(16);
  @include mq(md) {
    gap: vw-m(14);
  }
}

.p-details__item-price-ttl {
  background: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: rem(83);
  width: 100%;
  font-size: var(--fz16);
  font-weight: 500;
  letter-spacing: 0.06em;
  line-height: 1;
  padding: rem(10) rem(15);
  border-radius: rem(40);
  @include mq(md) {
    min-width: vw-m(118);
    max-width: unset;
    width: 100%;
    font-size: vw-m(22);
    border-radius: vw-m(24);
    white-space: nowrap;
    padding: vw-m(13) vw-m(15);
  }
}

.p-details__item-price-txt {
  font-weight: 500;
  font-size: var(--fz16);
  letter-spacing: 0.06em;
  line-height: 1;
  flex-shrink: 0;
  max-width: rem(90);
  width: 100%;
  @include mq(md) {
    max-width: vw-m(99);
    width: 100%;
    font-size: vw-m(22);
    white-space: nowrap;
  }
}

.p-details__item-container {
  background-color: $white;
  border-radius: rem(24);
  padding: rem(30) rem(38.5) rem(38) rem(39);
  margin-top: rem(13);
  @include mq(md) {
    padding: vw-m(43) vw-m(31.5) vw-m(44) vw-m(32.5);
    margin-top: vw-m(16);
  }
}

.p-details__item-wrapper {
  display: flex;
  padding-bottom: rem(14);
  border-bottom: rem(1) dashed #d1d1d1;
  max-width: rem(812);
  width: 100%;
  @include mq(md) {
    flex-direction: column;
    align-items: flex-start;
    gap: vw-m(30);
    padding-bottom: vw-m(20);
    border-bottom: vw-m(2) dashed #d1d1d1;
  }
}

.p-details__item-wrapper:last-child {
  border-bottom: none;
}

.p-details__item-price-head {
  max-width: rem(222);
  width: 100%;
  font-size: var(--fz18);
  font-weight: 700;
  letter-spacing: 0.06em;
  line-height: calc(26 / 18);
  flex-shrink: 0;
  @include mq(md) {
    max-width: none;
    font-size: vw-m(22);
  }
}

.p-details__item-wrapper > .p-details__item-price-wrapper {
  flex: 1;
}

.p-details__item-wrapper + .p-details__item-wrapper {
  margin-top: rem(13);
  @include mq(md) {
    margin-top: vw-m(29);
  }
}

.p-details__item-wrapper.p-details__item-wrapper--wide {
  gap: rem(111);
  @include mq(md) {
    gap: vw-m(33);
  }
}

.p-details__item-head {
  width: 16.5%;
  font-size: var(--fz18);
  letter-spacing: 0.06em;
  font-weight: 700;
  line-height: calc(24 / 16);
  @include mq(md) {
    width: 100%;
    font-size: vw-m(22);
  }
}

.p-details__item-head.p-details__item-head--wide {
  width: 38.1%;
  @include mq(md) {
    width: 100%;
  }
}

.p-details__item-head span {
  font-size: var(--fz14);
  letter-spacing: 0.06em;
  line-height: calc(24 / 16);
  @include mq(md) {
    font-size: vw-m(18);
  }
}

.p-details__item-content {
  width: 55.5%;
  font-size: var(--fz16);
  letter-spacing: 0.06em;
  font-weight: 500;
  line-height: calc(24 / 16);
  white-space: nowrap;
  @include mq(md) {
    width: 100%;
    font-size: vw-m(22);
    padding-left: 1.2em;
    white-space: normal;
  }
}

.p-charge-info__note-ttl{
  @include mq(md) {
    max-width: vw-m(610);
    width: 100%;
  }
}

/*会議室料金表*/

.p-price.p-price--rental .p-details__item-wrapper {
  @include mq(md) {
    border-bottom: vw-m(2) dashed #d1d1d1;
  }
}
.p-price.p-price--rental .p-details__item-head.p-details__item-head--wide {
  font-size: var(--fz16);
    @include mq(md) {
      font-size: vw-m(22);
    }
}

.p-price.p-price--rental .p-price__top-txt {
  padding-left: rem(47);
  margin-top: rem(15);
  @include mq(md) {
    padding-left: 0;
    margin-top: vw-m(22);
  }
}

.p-price.p-price--rental .p-price__top-txt + .p-price__top-txt {
  margin-top: rem(2);
  @include mq(md) {
    margin-top: vw-m(2);
  }
}