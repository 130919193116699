@use "global" as *;

.p-conference-room {
  background-color: $white;
  padding-top: rem(50);
  padding-bottom: rem(90);
  position: relative;
  top: rem(-1);
  @include mq(md) {
    padding-top: vw-m(70);
    padding-bottom: vw-m(88);
  }
}

.p-conference-room__bg {
  background-position: top left;
  border-radius: rem(30);
  padding: rem(38) rem(84);
  min-height: rem(406);
  @include mq(md) {
    border-radius: 0;
    padding: vw-m(83) vw-m(30) vw-m(108);
  }
}

.p-conference-room__list {
  margin-top: rem(22);
  @include mq(md) {
    margin-top: vw-m(42);
  }
}