@use "global" as *;

.p-contact {
  background-color: $white;
  padding-top: rem(40);
  padding-bottom: rem(172);
  @include mq(md) {
    padding-top: vw-m(60);
    padding-bottom: vw-m(120);
  }
}

.p-contact__bg {
  background-position: top left;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  padding-bottom: rem(70);
  position: relative;
  background-color: #F9E9EA;
  margin-top: rem(100);
  @include mq(md) {
    margin-top: vw-m(187);
    padding-bottom: vw-m(111);
  }
}

.p-contact__bg::before,
.p-contact__bg::after {
  content: "";
  display: block;
  width: 100%;
  height: rem(112);
  position: absolute;
  left: 0;
  background-size: 100% 100%;
  background-position: top left;
  background-repeat: no-repeat;
  @include mq(md) {
    height: vw-m(117);
  }
}

.p-contact__bg::before {
  top: rem(-93);
  background-image: url(../img/bg_top.png);
  @include mq(md) {
    top: vw-m(-116);
    background-image: url(../img/bg_top-sp.png);
  }
}

.p-contact__bg::after {
  bottom: rem(-110);
  background-position: bottom left;
  background-image: url(../img/bg_bottom.png);
  @include mq(md) {
    background-image: url(../img/bg_bottom-sp.png);
    bottom: vw-m(-111);
  }
}

.p-contact__container {
  background-color: $white;
  padding: rem(25) rem(56) rem(35) rem(73);
  border-radius: rem(36);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  margin-top: rem(12);
  @include mq(md) {
    margin-top: vw-m(-114);
    padding: vw-m(46) vw-m(37) vw-m(63) vw-m(37);
  }
}

.p-contact__top-txt {
  font-size: var(--fz16);
  letter-spacing: 0.09em;
  line-height: calc(30 / 16);
  font-weight: 500;
  margin-top: rem(36);
  position: relative;
  top: rem(-65);
  padding-left: rem(70);
  @include mq(md) {
    font-size: vw-m(26);
    line-height: calc(66 / 26);
    top: vw-m(-153);
    text-align: left;
    padding-left: 0;
  }
}

.p-contact__sub-ttl.c-sec-ttl.c-sec-ttl--small {
  text-align: center;
  @include mq(md) {
    letter-spacing: 0.06em;
  }
}

.p-contact__tel-btn {
  margin-top: rem(20);
  @include mq(md) {
    margin-top: vw-m(30);
  }
}

.p-contact__form-container {
  margin-top: rem(71);
  background-color: $white;
  padding: rem(24) rem(56) rem(47) rem(73);
  border-radius: rem(36);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  @include mq(md) {
    margin-top: vw-m(54);
    padding: vw-m(68) vw-m(35) vw-m(84) vw-m(35);
  }
}

.p-contact__form-top-txt {
  font-size: var(--fz16);
  letter-spacing: 0.09em;
  line-height: calc(30 / 16);
  font-weight: 500;
  text-align: center;
  margin-top: rem(26);
  @include mq(md) {
    margin-top: vw-m(70);
    font-size: vw-m(24);
    letter-spacing: 0.06em;
    text-align: left;
    line-height: calc(35 / 24);
  }
}

.p-contact__wrapper {
  max-width: rem(800);
  width: 100%;
  margin-inline: auto;
}

.p-contact__form {
  margin-top: rem(40);
  @include mq(md) {
    margin-top: vw-m(62);
  }
}

.p-contact__content:not(:first-child) {
  margin-top: rem(30);
  @include mq(md) {
    margin-top: vw-m(50);
  }
}

.p-contact__head > label {
  display: flex;
  align-items: center;
  gap: rem(15);
  font-size: var(--fz16);
  letter-spacing: 0.09em;
  line-height: calc(30 / 16);
  font-weight: 500;
  @include mq(md) {
    font-size: vw-m(28);
    gap: vw-m(27);
  }
}

.p-contact__required {
  background: #db5f6a;
  color: $white;
  border-radius: rem(3);
  font-size: var(--fz14);
  display: block;
  padding: rem(2) rem(10);
  width: rem(72);
  text-align: center;
  letter-spacing: 0.09em;
  @include mq(md) {
    font-size: vw-m(24);
    width: vw-m(131);
    padding: vw-m(5.5) vw-m(10);
  }
}

.p-contact__item {
  margin-top: rem(9);
  @include mq(md) {
    margin-top: vw-m(20);
  }
}

.p-contact__item-wrapper {
  width: 100%;
  min-height: rem(70);
}

.p-contact__item-wrapper > input {
  width: 100%;
  min-height: rem(70);
  border: rem(1) solid #d1d1d1;
  border-radius: rem(10);
  padding: rem(10) rem(26);
  @include mq(md) {
    padding: vw-m(10) vw-m(26);
    min-height: vw-m(100);
    font-size: vw-m(24);
    border-radius: vw-m(10);
  }
}

.p-contact__item-text {
  width: 100%;
  border: rem(1) solid #d1d1d1;
  border-radius: rem(10);
  padding: rem(23) rem(26);
  font-size: var(--fz16);
  letter-spacing: 0.09em;
  line-height: calc(30 / 16);
  font-weight: 500;
  @include mq(md) {
    font-size: vw-m(24);
    border-radius: vw-m(10);
    padding: vw-m(34) vw-m(27);
  }
}

.p-contact__item-textarea {
  width: 100%;
  border: rem(1) solid #d1d1d1;
  border-radius: rem(10);
  padding: rem(25) rem(26);
  font-size: var(--fz16);
  letter-spacing: 0.09em;
  line-height: calc(30 / 16);
  font-weight: 500;
  height: rem(459);
  @include mq(md) {
    height: vw-m(745);
    font-size: vw-m(24);
    border-radius: vw-m(10);
    padding: vw-m(34) vw-m(27);
  }
}


.p-contact__item-text::placeholder,
.p-contact__item-textarea::placeholder {
  color: #d1d1d1;
  font-size: var(--fz16);
  font-weight: 400;
  letter-spacing: 0.09em;
  @include mq(md) {
    font-size: vw-m(24);
  }
}

.p-contact__bottom-txt {
  text-align: center;
  margin-top: rem(25);
  font-size: var(--fz16);
  letter-spacing: 0.09em;
  line-height: calc(30 / 16);
  font-weight: 500;
  @include mq(md) {
    font-size: vw-m(24);
    margin-top: vw-m(51);
    text-align: left;
  }
}

.p-contact__bottom-link {
  color: #029595;
  text-underline-offset: rem(3);
  text-decoration: underline;
  transition: opacity 0.3s;
}

@media (any-hover: hover) {
  .p-contact__bottom-link:hover {
    opacity: 0.7;
  }
}


.p-contact__check-wrapper {
  margin-top: rem(20);
  border-radius: rem(10);
  font-size: rem(22);
  letter-spacing: 0.09em;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  @include mq(md) {
    margin-top: vw-m(54);
    font-size: vw-m(30);
    letter-spacing: 0.1em;
  }
}

.p-contact__check-btn {
  appearance: none;
  border: 1px solid #d1d1d1;
  background-color: $white;
  border-radius: rem(4);
  width: rem(30);
  height: rem(30);
  position: relative;
  flex-shrink: 0;
  margin-right: rem(19);
  transition: background-color 0.3s, border-color 0.3s;
  @include mq(md) {
    width: vw-m(48);
    height: vw-m(48);
    margin-right: vw-m(29);
  }
}

.p-contact__check-btn:checked {
  background-color: #5FBEBE;
  border: 1px solid #5FBEBE;
}

.p-contact__check-btn:checked::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: rem(16);
  height: rem(8);
  border-left: rem(3) solid $white;
  border-bottom: rem(3) solid $white;
  transform: translate(-50%, -50%) rotate(-50deg);
  @include mq(md) {
    width: vw-m(24);
    height: vw-m(14);
    border-left: vw-m(5) solid $white;
    border-bottom: vw-m(5) solid $white;
  }
}

.p-contact__check-label {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
}

.p-contact__btn-wrapper {
  display: block;
  margin-top: rem(30);
  width: 100%;
  position: relative;
  z-index: 1;
  @include mq(md) {
    margin-top: vw-m(70);
  }
}

.p-contact__btn {
  border-radius: rem(35);
  background-color: #5FBEBE;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: rem(20) 0;
  max-width: unset;
  gap: rem(20);
  @include mq(md) {
    gap: vw-m(17);
    border-radius: vw-m(50);
    justify-content: flex-start;
    padding: vw-m(30.5) vw-m(10) vw-m(30.5) vw-m(242);
  }
}

.p-contact__btn::after {
  width: rem(18);
  height: rem(18);
  @include mq(md) {
    width: vw-m(35);
    height: vw-m(35);
    right: vw-m(36);
  }
}

.p-contact__btn__icon {
  width: rem(22);
  height: rem(22);
  scale: 1;
  transition: all 3s ease-out;
  @include mq(md) {
    width: max(vw-m(22), 14px);
    height: max(vw-m(22), 14px);
  }
}


.p-contact__btn__icon svg {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.p-contact__btn__txt {
  font-weight: 700;
  font-size: rem(20);
  letter-spacing: 0.04em;
  color: $white;
  @include mq(md) {
    font-size: vw-m(26);
    letter-spacing: 0.1em;
  }
}

@media (any-hover: hover) {
  .p-contact__btn:hover,
  .p-contact__btn:hover .p-contact__btn__icon {
    scale: 1.025;
  }
}

.p-contact__error{
  font-size: var(--fz12);
  font-weight: 500;
  line-height: 1.2;
  margin-top: rem(10);
  color: $red;
  @include mq(md) {
    margin-top: vw-m(10);
    font-size: max(vw-m(20), 10px);
  }
}

input:focus-visible,
textarea:focus-visible {
  outline: none;
  border: 1px solid #5FBEBE;
}