@use "global" as *;

.p-common-contact{
  background: $white;
  border-radius: rem(36);
  padding: rem(44) rem(30) rem(63) rem(30);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  @include mq(md) {
    padding: vw-m(40) vw-m(37) vw-m(63) vw-m(37);
  }
}

.p-common-contact__ttl.c-sec-ttl.c-sec-ttl--small{
  text-align: center;
  @include mq(md) {
    letter-spacing: 0.06em;
  }
}

.p-common-contact__wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-top: rem(35);
  gap: rem(60);
  @include mq(md) {
    margin-top: vw-m(35);
    gap: vw-m(28);
    flex-direction: column;
    align-items: center;
  }
}

.p-common-contact__btn {
  max-width: rem(392);
  width: 100%;
  border-radius: rem(20);
  min-height: rem(110);
  padding: rem(17) rem(10) rem(17) rem(10);
  @include mq(md) {
    max-width: unset;
    padding: vw-m(29) vw-m(30) vw-m(30) vw-m(30);
    min-height: vw-m(172)
  }
}

.p-common-contact__btn--tel {
  background: #f0f0f0;
  pointer-events: none;
  @include mq(md) {
    pointer-events: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: vw-m(10);
  }
}

.p-common-contact__btn-wrapper {
  display: flex;
  align-items: baseline;
  justify-content: center;
  gap: rem(12);
  @include mq(md) {

    justify-content: flex-start;
    gap: vw-m(30);
    max-width: vw-m(490);
    width: 100%;
  }
}

.p-common-contact__small {
  font-size: rem(22);
  font-weight: 500;
  color: #a2a2a2;
  @include mq(md) {
    font-size: vw-m(32);
  }
}

.p-common-contact__num {
  font-size: rem(36);
  font-weight: 700;
  letter-spacing: 0.02em;
  @include mq(md) {
    font-size: vw-m(52);
  }
}

.p-common-contact__info {
  width: 100%;
  text-align: center;
  font-size: var(--fz14);
  letter-spacing: 0.02em;
  line-height: calc(20 / 14);
  white-space: nowrap;
  @include mq(md) {
    font-size: vw-m(20);
  }
}

.p-common-contact__btn--form {
  background: $red;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: rem(14);
  color: $white;
  border: 1px solid $black;
  transition: 0.3s ease-in-out;
  @include mq(md) {
    gap: vw-m(20);
  }
}

.p-common-contact__btn-icon {
  display: flex;
  align-items: center;
  @include mq(md) {
    width: vw-m(33);
  }
}

.p-common-contact__btn-icon svg{
  width: rem(20);
  @include mq(md) {
    width: vw-m(33);
  }
}

.p-common-contact__txt {
  font-size: rem(22);
  font-weight: 700;
  letter-spacing: 0.02em;
  @include mq(md) {
    font-size: vw-m(36);
  }
}

.p-common-contact__btn--form:hover {
background-color: #ff5362;
  color: $white;
}