@use "global" as *;

.p-layout {
  background-color: $white;
  padding-top: rem(39);
  @include mq(md) {
    padding-top: vw-m(159);
  }
}

.p-layout__ttl.c-sec-ttl.c-sec-ttl--small {
  @include mq(md) {
    letter-spacing: 0.06em;
  }
}

.p-layout__img-list {
  margin-top: rem(30);
  max-width: rem(1000);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  border-radius: rem(36);
  border: 1px solid #4d4d4d;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: rem(24);
  padding: rem(46) rem(12);
  @include mq(md) {
    margin-top: vw-m(63);
    border: none;
    padding: 0;
    gap: vw-m(25) vw-m(30);
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

.p-layout__img-item {
  max-width: rem(220);
  width: 100%;
  @include mq(md) {
    max-width: vw-m(330);
    border-radius: vw-m(36);
    border: vw-m(1) solid $black;
    padding-bottom: vw-m(40);
  }
}

.p-layout__img-btn {
  @include mq(md) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: rem(235);
    padding-top: vw-m(50);
    padding: vw-m(50) vw-m(40) vw-m(10);
    margin-inline: auto;
  }
  @include mq(sm) {
    padding: rem(25) rem(15);
  }
}

.p-layout__img-btn  {
  width: 100%;
  display: block;
  position: relative;
}

.p-layout__img-btn  img {
  transition: scale 0.3s ease-in-out;
  @include mq(md) {
    width: 100%;
    object-fit: contain;
    aspect-ratio: 235 / 321;
    height: vw-m(321);
  }
}

.p-layout__btn-icon {
  position: absolute;
  right: rem(10);
  bottom: 0;
  width: rem(18);
  height: rem(18);
  transition: scale 0.2s linear;
  @include mq(md) {
    display: none;
  }
}
@media (any-hover: hover) {
  .p-layout__img-btn:hover .p-layout__btn-icon  {
    scale: 1.2;
  }
}

.p-layout__pdf-link {
  max-width: rem(180);
  width: 100%;
  margin-top: rem(20);
  font-size: max(rem(14), 10px);
  margin-inline: auto;
  @include mq(md) {
    margin-top: vw-m(20);
    max-width: vw-m(280);
    min-height: vw-m(50);
    font-size: vw-m(20);
  }
}

.p-layout__btn-wrapper {
  display: flex;
  justify-content: flex-end;
  max-width: rem(960);
  width: 100%;
  margin-inline: auto;
  margin-top: rem(50);
  @include mq(md) {
    margin-top: vw-m(68);
    justify-content: center;
  }
}

.p-layout__bottom-btn {
  max-width: rem(340);
  width: 100%;
  font-size: var(--fz14);
  letter-spacing: 0.04em;
  @include mq(md) {
    max-width: vw-m(600);
    min-height: vw-m(100);
    font-size: vw-m(24);
    border-radius: vw-m(50);
    padding-left: vw-m(60);
  }
}

.p-layout__bottom-btn::after {
  @include mq(md) {
    width: vw-m(34);
    height: vw-m(34);
    right: vw-m(28);
  }
}
/* modal */
.p-layout__modal {
  max-width: 550px;
  width: 100%;
  margin-inline: auto;
  padding: rem(10) 0;
  @include mq(md) {
    max-height: 100vh;
  }
}

.p-layout__modal-container {
  max-width: 550px;
  width: 100%;
  margin-inline: auto;
  @include mq(md) {
    max-height: 100vh;
  }
}

.p-layout__modal-btn-wrapper {
  top: rem(-50);
  @include mq(md) {
    top: vw-m(-50);
  }
}

.p-layout__modal-img {
  max-width: 500px;
  overflow-y: auto;
  margin-inline: auto;
  @include mq(md) {
    max-width: 90%;
  }
}

.p-layout__modal-img img{
  aspect-ratio: 176/250;
  width: 100%;
  object-fit: contain;
}