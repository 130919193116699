@use "global" as *;

.p-room {
  background-color: $white;
  margin-top: rem(45);
  @include mq(md) {
    margin-top: vw-m(190);
  }
}

.p-room__ttl.c-sec-ttl.c-sec-ttl--small {
  @include mq(md) {
    letter-spacing: 0.06em;
  }
}

.p-room__txt {
  max-width: rem(960);
  width: 100%;
  margin-left: rem(70);
  font-size: max(rem(16),10px);
  line-height: calc(30 / 16);
  letter-spacing: 0.09em;
  margin-top: rem(46);
  @include mq(md) {
    margin-left: 0;
    margin-top: vw-m(46);
    font-size: vw-m(26);
    line-height: calc(66 / 26);
  }
}

.p-room__list {
  display: flex;
  justify-content: center;
  column-gap: rem(36);
  margin-top: rem(68);
  @include mq(md) {
    flex-direction: column;
    margin-top: vw-m(110);
    gap: vw-m(83);
  }
}

.p-room__item {
  max-width: rem(460);
  width: 100%;
  @include mq(md) {
    max-width: 100%;
  }
}

.p-room__item:nth-child(2) {
  margin-top: rem(40);
  @include mq(md) {
    margin-top: 0;
  }
}

.p-room__item:nth-child(2) .p-room__item-room {
  top: rem(-42);
  @include mq(md) {
    top: vw-m(-50);
  }
}

.p-room__item-img-wrapper {
  width: 100%;
  position: relative;
}

.p-room__item-img {
  width: 100%;
  aspect-ratio: 460/280;
  object-fit: contain;
  @include mq(md) {
    aspect-ratio: 690 / 428;
  }
}

.p-room__item-room {
  margin-top: rem(10);
  font-weight: 700;
  font-size: max(rem(20),10px);
  line-height: calc(29 / 16);
  display: block;
  background-color: $white;
  border-radius: rem(30);
  position: absolute;
  top: rem(-70);
  left: rem(-30);
  width: rem(166);
  color: $red;
  height: rem(80);
  padding-left: rem(57);
  padding-top: rem(31);
  padding-bottom: rem(20);
  @include mq(md) {
    font-size: vw-m(28);
    left: vw-m(-30);
    width: vw-m(328);
    height: unset;
    padding-top: vw-m(50);
    padding-bottom: vw-m(31);
    padding-left: vw-m(105);
    top: vw-m(-50);
    border-radius: vw-m(30);
  }
}

.p-room__item-txt-wrapper {
  margin-top: rem(12);
  @include mq(md) {
    margin-top: vw-m(30);
    position: relative;
    z-index: 1;
  }
}

.p-room__item-outline {
  font-weight: 700;
  font-size: max(rem(16),10px);
  line-height: calc(24 / 16);
  letter-spacing: 0.06em;
  color: $red;
  padding-bottom: rem(10);
  border-bottom: 1px dashed #f4a5ac;
  @include mq(md) {
    font-size: vw-m(26);
    line-height: calc(37 / 26);
  }
}

.p-room__item-txt {
  margin-top: rem(13);
  font-size: max(rem(16),10px);
  line-height: calc(30 / 16);
  letter-spacing: 0.09em;
  font-weight: 500;
  @include mq(md) {
    font-size: vw-m(26);
    line-height: calc(46 / 26);
    margin-top: vw-m(10);
  }
}