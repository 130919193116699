@use "global" as *;

.p-facilities {
  background-color: $white;
  padding-top: rem(35);
  padding-bottom: rem(200);
  @include mq(md) {
    padding-top: vw-m(60);
    padding-bottom: vw-m(40);
  }
  @include mq(sm) {
    padding-bottom: vw-s(50);
  }
}

.p-facilities__bg{
  @include mq(md) {
    margin-top: vw-m(50);
  }
}


.p-facilities__bg::before {
  height: rem(2328);
  top: vw-lg(-110);
  background-size: 100%  rem(2328);
  @include mq(md) {
    height: vw-m(5322);
    top: vw-m(13);
    background-size: 100%  vw-m(5322);
  }
  @include mq(sm) {
    height: vw-s(2650);
    top: 0;
    background-size: 100% vw-s(2600);
  }
}

.p-facilities-top-ttl.c-sec-ttl.c-sec-ttl--small {
  position: relative;
  top: vw-lg(-67);
  @include mq(md) {
    letter-spacing: 0.06em;
    top: vw-m(-11);
  }
}

.p-facilities__top-txt {
  margin-top: vw-lg(-21);
  max-width: rem(960);
  width: 100%;
  margin-inline: auto;
  font-weight: 500;
  font-size: max(rem(16),10px);
  letter-spacing: 0.09em;
  line-height: calc(30 / 16);
  @include mq(md) {
    margin-top: vw-m(35);
    font-size: vw-m(26);
    line-height: calc(66 / 26);
  }
}

.p-facilities__img-modal-btn.p-top-facility__img-modal-link {
  max-width: rem(1000);
  width: 100%;
  margin-inline: auto;
  margin-top: rem(43);
  overflow: hidden;
  border: rem(1) solid #4d4d4d;
  max-height: rem(412);
  @include mq(md) {
    border-radius: vw-m(36);
    margin-top: vw-m(30);
    border: vw-m(1) solid #4d4d4d;
  }
}

.p-facilities__img-modal-btn.p-top-facility__img-modal-link img {
  object-fit: contain;
  background-color: $white;
  aspect-ratio: 1000 / 420;
  width: 100%;
  @include mq(md) {
    aspect-ratio: 690 / 412;
  }
}

.p-facility__modal-img img {
  border-radius: rem(36);
  @include mq(md) {
    aspect-ratio: 690 / 412;
    border-radius: vw-m(36);
  }
}

.p-facilities__wrapper {
  margin-top: rem(52);
  @include mq(md) {
    margin-top: vw-m(65);
  }
}

.p-facilities__table {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 0 auto;
  gap: rem(18) rem(50);
  max-width: rem(954);
  width: 100%;
  @include mq(md) {
    display: flex;
    flex-direction: column;
    gap: vw-m(28);
  }
}

.p-facilities__cell {
  padding-bottom: rem(13);
  border-bottom: rem(1) dashed #f4a5ac;
  @include mq(md) {
    padding-bottom: vw-m(12);
    border-bottom: vw-m(2) dashed #d1d1d1;
  }
}

.p-facilities__cell-ttl {
  color: $red;
  font-weight: 700;
  font-size: max(rem(16),10px);
  line-height: calc(24 / 16);
  letter-spacing: 0.06em;
  @include mq(md) {
    font-size: vw-m(24);
    line-height: calc(35 / 24);
  }
}

.p-facilities__cell-txt {
  margin-top: rem(10);
  font-weight: 500;
  font-size: max(rem(16),10px);
  line-height: calc(24 / 16);
  letter-spacing: 0.03em;
  white-space: nowrap;
  @include mq(md) {
    margin-top: vw-m(8);
    font-size: vw-m(24);
    line-height: calc(35 / 24);
    white-space: normal;
  }
}

.p-facilities__cell--wide {
  grid-column: span 2;
  @include mq(md) {
    grid-column: span 1;
  }
}

.p-facilities__img-list {
  margin-top: rem(148);
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: rem(37);
  row-gap: rem(44);
  @include mq(md) {
    margin-top: vw-m(102);
    gap: vw-m(92);
  }
}

.p-facilities__img-item {
  max-width: rem(460);
  width: 100%;
  @include mq(md) {
    max-width: unset;
  }
}

.p-facilities__img-item:nth-child(1) {
  margin-top: rem(-45);
  @include mq(md) {
    margin-top: 0;
  }
}

.p-facilities__img-item:nth-child(3) {
  margin-top: rem(-16);
  @include mq(md) {
    margin-top: 0;
  }
}

.p-facilities__img-box {
  position: relative;
}

.p-facilities__img-wrapper {
  width: 100%;
}

.p-facilities__img-wrapper img {
  border-radius: rem(40);
  object-fit: contain;
  aspect-ratio: 460/280;
  @include mq(md) {
    aspect-ratio: 690/428;
  }
}

.p-facilities__txt-wrapper {
  position: absolute;
  top: 0;
  left: 0;
}

.p-facilities__img-item:nth-child(1) .p-facilities__txt-wrapper {
  top: rem(-51);
  left: rem(-30);
  width: rem(166);
  @include mq(md) {
    top: vw-m(-101);
    left: vw-m(-30);
    width: vw-m(328);
  }
}

.p-facilities__img-item:nth-child(2) .p-facilities__txt-wrapper {
  top: rem(-32);
  left: rem(-30);
  width: rem(166);
  @include mq(md) {
    top: vw-m(-100);
    left: vw-m(-30);
    width: vw-m(328);
  }
}

.p-facilities__img-item:nth-child(3) .p-facilities__txt-wrapper {
  top: rem(-28);
  left: rem(-31);
  width: rem(240);
  @include mq(md) {
    top: vw-m(-105);
    left: vw-m(-30);
    width: vw-m(328);
  }
}

.p-facilities__img-item:nth-child(4) .p-facilities__txt-wrapper {
  top: rem(-22);
  left: rem(-31);
  width: rem(197);
  @include mq(md) {
    top: vw-m(-105);
    left: vw-m(-30);
    width: vw-m(328);
  }
}

.p-facilities__img-ttl {
  font-weight: 700;
  font-size: max(rem(16),10px);
  line-height: calc(36 / 16);
  letter-spacing: 0.09em;
  margin-top: rem(10);
  @include mq(md) {
    position: relative;
    z-index: 1;
    margin-top: vw-m(13);
    font-size: vw-m(26);
    line-height: calc(66 / 26);
  }
}

.p-facilities__bottom-wrapper {
  max-width: rem(956);
  width: 100%;
  margin-top: rem(40);
  background-color: $white;
  padding: rem(34);
  border-radius: rem(20);
  margin-inline: auto;
  @include mq(md) {
    margin-top: vw-m(79);
    padding: vw-m(60) vw-m(45) vw-m(41);
  }
}

.p-facilities__bottom-txt {
  max-width: rem(818);
  width: 100%;
  margin-inline: auto;
  font-size: max(rem(16),10px);
  line-height: calc(30 / 16);
  letter-spacing: 0.09em;
  @include mq(md) {
    max-width: vw-m(620);
    font-size: vw-m(26);
    line-height: calc(56 / 26);
  }
}

.p-facilities__btn-wrapper {
  max-width: rem(956);
  width: 100%;
  margin-top: rem(50);
  margin-inline: auto;
  display: flex;
  justify-content: flex-end;
  @include mq(md) {
    margin-top: vw-m(90);
    justify-content: center;
  }
}

.p-facilities__btn {
  max-width: rem(340);
  font-size: max(rem(14),10px);
  @include mq(md) {
    max-width: vw-m(600);
    font-size: vw-m(24);
    letter-spacing: 0.04em;
    min-height: vw-m(100);
    border-radius: vw-m(50);
    padding-left: vw-m(60);
  }
}

.p-facilities__btn::after {
  @include mq(md) {
    width: vw-m(34);
    height: vw-m(34);
    right: vw-m(28);
  }
}