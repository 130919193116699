@use "global" as *;

.p-about {
  background-color: $white;
  padding-top: rem(40);
  @include mq(md) {
    padding-top: vw-m(60);
    padding-bottom: vw-m(40);
  }
  @include mq(sm) {
    padding-bottom: vw-s(50);
  }
}

.p-about__bg.l-bg {
  @include mq(md) {
    margin-top: vw-m(61);
  }
}

.p-about__bg::before {
  height: rem(2105);
  top: vw-lg(-103);
  background-size: 100%  rem(2105);
  @include mq(md) {
    height: vw-m(5150);
    top: vw-m(13);
    background-size: 100%  vw-m(5150);
  }
  @include mq(sm) {
    height: vw-s(2650);
    top: 0;
    background-size: 100% vw-s(2600);
  }
}

.p-about-top-txt {
  position: relative;
  z-index: 1;
  top: vw-lg(-67);
  font-size: rem(26);
  line-height: calc(46 / 26);
  font-weight: 700;
  letter-spacing: 0.11em;
  @include mq(md) {
    top: vw-m(-29);
    letter-spacing: 0.06em;
    font-size: vw-m(42);
    line-height: calc(66 / 42);
  }
}

.p-about-top-txt span {
  color: $red;
}

.p-about__top-img {
  margin-top: rem(-39);
  width: 100%;
  height: auto;
  @include mq(md) {
    margin-top: vw-m(20);
  }
}

.p-about__top-img img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.p-about__top-wrapper {
  display: flex;
  margin-top: rem(63);
  gap: rem(77);
  @include mq(md) {
    flex-direction: column;
    margin-top: vw-m(52);
    gap: vw-m(39);
  }
}

.p-about__img {
  max-width: rem(522);
  width: 100%;
  @include mq(md) {
    max-width: unset;
    width: 100%;
  }
}

.p-about__txt-box {
  max-width: rem(455);
  width: 100%;
  @include mq(md) {
    max-width: unset;
    width: 100%;
  }
}

.p-about__txt {
  font-size: max(rem(16), 10px);
  line-height: calc(36 / 16);
  letter-spacing: 0.09em;
  @include mq(md) {
    font-size: vw-m(26);
    line-height: calc(66 / 26);
  }
}

.p-about__txt span {
  color: $red;
  font-weight: 700;
}

// .p-about__txt-wrapper {
//   margin-top: rem(30);
// }
