@use "global" as *;


.c-wide-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: rem(35);
  background: #ff5362;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
  padding: rem(27) rem(32);
  gap: rem(14);
  letter-spacing: 0.04em;
  position: relative;
  @include mq(md) {
    max-width: unset;
    padding: vw-m(37) vw-m(16) vw-m(37) vw-m(90);
    gap: vw-m(21);
    border-radius: vw-m(50);
    justify-content: flex-start;
    position: relative;
    z-index: 1;
  }
}

.c-wide-btn::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  right: rem(31);
  transform: translateY(-50%);
  width: rem(14);
  height: rem(14);
  background-image: url(../img/icon/icon_plus.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 5;
  @include mq(md) {
    width: vw-m(26);
    height: vw-m(26);
    right: vw-m(42);
  }
}

.c-wide-btn__icon {
  width: rem(2.7);
  height: rem(15.13);
  @include mq(md) {
    width: vw-m(4.71);
    height: vw-m(26.4);
  }
}

.c-wide-btn__icon svg {
  width: 100%;
  height: 100%;
}

.c-wide-btn__ttl {
  letter-spacing: 0.04em;
  font-size: var(--fz16);
  font-weight: 700;
  font-family: $font-sub;
  margin-right: rem(7);
  display: inline-block;
  @include mq(md) {
    letter-spacing: 0.1em;
    font-size: vw-m(26);
    margin-right: 0;
  }
}

@media (any-hover: hover) {
  .c-wide-btn:hover {
    scale: 1.025;
  }
}
