@use "global" as *;

.p-application {
  background-color: $white;
  padding-top: rem(35);
  padding-bottom: rem(172);
  @include mq(md) {
    padding-top: vw-m(43);
    padding-bottom: vw-m(155);
  }
}

.p-application__bg {
  background-position: top left;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  padding-bottom: rem(75);
  position: relative;
  background-color: #F9E9EA;
  margin-top: rem(100);
  @include mq(md) {
    margin-top: vw-m(188);
    padding-bottom: vw-m(94);
  }
}

.p-application__bg::before,
.p-application__bg::after {
  content: "";
  display: block;
  width: 100%;
  height: rem(112);
  position: absolute;
  left: 0;
  background-size: 100% 100%;
  background-position: top left;
  background-repeat: no-repeat;
  @include mq(md) {
    height: vw-m(117);
  }
}

.p-application__bg::before {
  top: rem(-93);
  background-image: url(../img/bg_top.png);
  @include mq(md) {
    top: vw-m(-116);
    background-image: url(../img/bg_top-sp.png);
  }
}

.p-application__bg::after {
  bottom: rem(-110);
  background-position: bottom left;
  background-image: url(../img/bg_bottom.png);
  @include mq(md) {
    background-image: url(../img/bg_bottom-sp.png);
    bottom: vw-m(-110);
  }
}

.p-application__container {
  background-color: $white;
  padding: rem(60) rem(56) rem(82) rem(59);
  border-radius: rem(36);
  margin-top: rem(-18);
  @include mq(md) {
    margin-top: vw-m(-82);
    padding: vw-m(68) vw-m(34.5) vw-m(90) vw-m(35.5);
  }
}

.p-application__wrapper {
  max-width: rem(986);
  width: 100%;
}

.p-application__top-ttl.c-sec-ttl.c-sec-ttl--small {
  position: relative;
  top: rem(-54);
  @include mq(md) {
    top: vw-m(-148);
    letter-spacing: 0.06em;
  }
}

.p-application__top-ttl.c-sec-ttl.c-sec-ttl--small::after{
  font-size: rem(22);
  margin-top:rem(-4);
  letter-spacing: 0.12em;
  @include mq(md) {
    font-size: vw-m(26);
    margin-top:vw-m(-4);
    letter-spacing: 0.06em;
  }
}

.p-application__sub-ttl.c-sec-ttl.c-sec-ttl--small{
  text-align: center;
  @include mq(md) {
    font-size: vw-m(42);
    letter-spacing: 0.06em;
  }
}

.p-application__sub-ttl.c-sec-ttl.c-sec-ttl--small::after{
  @include mq(md) {
    font-size: vw-m(26);
    margin-top:vw-m(-4);
    letter-spacing: 0.06em;
  }
}


.p-application__note-list {
  margin: rem(37) auto 0;
  max-width: rem(958);
  width: 100%;
  margin-left: auto;
  @include mq(md) {
    margin: vw-m(70) auto 0;
  }
}

.p-application__note-item {
  font-size: var(--fz16);
  letter-spacing: 0.06em;
  line-height: calc(24 / 16);
  font-weight: 500;
  padding-bottom: rem(13.5);
  border-bottom: rem(1) dashed #d1d1d1;
  @include mq(md) {
    padding-bottom: vw-m(42);
    font-size: vw-m(24);
    line-height: calc(35 / 24);
    border-bottom: vw-m(2) dashed #d1d1d1;
  }
}

.p-application__note-item + .p-application__note-item {
  margin-top: rem(22);
  @include mq(md) {
    margin-top: vw-m(40);
  }
}

.p-application__note-item::before{
  content: "・";
  display: inline-block;
}

.p-application__note-txt {
  font-size: var(--fz16);
  letter-spacing: 0.04em;
  line-height: calc(24 / 16);
  font-weight: 500;
  margin-top: rem(13.5);
  text-align: center;
  margin-top: rem(36);
  @include mq(md) {
    font-size: vw-m(24);
    letter-spacing: 0.06em;
    line-height: calc(46 / 24);
    margin-top: vw-m(47);
    text-align: left;
  }
}

.p-application__note-txt-link {
  color: $red;
  transition: color 0.3s;
}

.p-application__note-txt-link-ttl{
  text-decoration: underline;
}

@media (any-hover: hover) {
  .p-application__note-txt-link:hover {
    color: #ff5362;
  }
}

.p-application__check-wrapper {
  margin-top: rem(22);
  background: #ff5362;
  border-radius: rem(10);
  color: $white;
  font-size: rem(22);
  letter-spacing: 0.04em;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  cursor: pointer;
  @include mq(md) {
    margin-top: vw-m(52);
    font-size: vw-m(26);
    letter-spacing: 0.1em;
    border-radius: vw-m(20);
  }
}

.p-application__check-btn {
  appearance: none;
  border: 1px solid #d1d1d1;
  background-color: $white;
  border-radius: rem(4);
  width: rem(30);
  height: rem(30);
  position: relative;
  flex-shrink: 0;
  margin: 0;
  margin-right: rem(19);
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
  @include mq(md) {
    width: vw-m(48);
    height: vw-m(48);
    margin-right: vw-m(19);
  }
}

.p-application__check-btn:checked {
  background-color: #FFA2AA;
  border: 1px solid #FFA2AA;
}

.p-application__check-btn:checked::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: rem(16);
  height: rem(8);
  border-left: rem(3) solid $white;
  border-bottom: rem(3) solid $white;
  transform: translate(-50%, -50%) rotate(-50deg);
  @include mq(md) {
    width: vw-m(24);
    height: vw-m(14);
    border-left: vw-m(5) solid $white;
    border-bottom: vw-m(5) solid $white;
  }
}

.p-application__check-label {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  text-align: center;
  cursor: pointer;
  width: 100%;
  min-height: rem(90);
  @include mq(md) {
    min-height: vw-m(100);
    justify-content: flex-start;
    padding-left: vw-m(90);
    gap: vw-m(10);
  }
}

.p-application__check-txt {
  transition: all 0.3s ease-in-out;
}

@media (any-hover: hover) {
  .p-application__check-label:hover .p-application__check-txt {
    opacity: 0.8;
  }
}

.p-application__list {
  margin-top: rem(35);
  pointer-events: none; /* デフォルトはクリック不可 */
  @include mq(md) {
    margin-top: vw-m(70);
  }
}

.p-application__item + .p-application__item {
  margin-top: rem(24);
  @include mq(md) {
    margin-top: vw-m(40);
  }
}

.p-application__link {
  background-color: rgba(77, 77, 77, 0.4);
  color: $white;
  font-size: var(--fz18);
  letter-spacing: 0.04em;
  font-weight: 700;
  line-height: 1;
  display: flex;
  align-items: center;
  padding: rem(19) rem(36);
  position: relative;
  border-radius: rem(10);
  transition: background-color 0.3s, opacity 0.3s;
  @include mq(md) {
    border-radius: vw-m(10);
    font-size: vw-m(22);
    padding: vw-m(37) vw-m(40);
  }
}

.p-application__icon {
  position: absolute;
  top: 50%;
  right: rem(33);
  transform: translateY(-50%);
  width: rem(15);
  @include mq(md) {
    width: vw-m(20);
    right: vw-m(33);
  }
}

.p-application__icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.p-application__contact-area {
  margin-top: rem(40);
  @include mq(md) {
    margin-top: vw-m(92);
  }
}

@media (any-hover: hover) {
  .p-application__link:hover {
    opacity: 0.4;
  }
}