@use "global" as *;

.c-card__img-wrapper {
  overflow: hidden;
  border-radius: rem(20);
  border: rem(1) solid $black;
  @include mq(md) {
    border: vw-m(2) solid $black;
  }
}

.c-card__img-wrapper img {
  width: 100%;
  transition: scale 0.8s cubic-bezier(0.09, 0.43, 0.1, 0.79);
  scale: 1;
}

@media (any-hover: hover) {
  .c-card__link:hover .c-card__img-wrapper img {
    scale: 1.1;
  }
}
