@use "global" as *;

.p-note {
  padding-top: rem(40);
  padding-bottom: rem(60);
  @include mq(md) {
    padding-top: vw-m(60);
    padding-bottom: vw-m(60);
  }
}

.p-note__container {
  background-color:#f8eaea;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: rem(36);
  padding: rem(60) rem(66) rem(55);
  @include mq(md) {
    width: 100vw;
    margin: 0 calc(50% - 50vw);
    border-radius: vw-m(30);
    padding: vw-m(60) vw-m(30) vw-m(120);
  }
}

.p-note__container:first-of-type{
  margin-top: rem(52);
  @include mq(md) {
    margin-top: vw-m(48);
  }
}

.p-note__container + .p-note__container {
  margin-top: rem(50);
  @include mq(md) {
    margin-top: vw-m(80);
  }
}

.p-note__top-img {
  width: 100%;
}

.p-note__top-img img {
  width: 100%;
  aspect-ratio: 968/200;
  object-fit: contain;
  @include mq(md) {
    aspect-ratio: 690/340;
  }
}

.p-note__heading {
  margin-top: rem(25);
  font-size: rem(28);
  font-weight: 700;
  line-height: calc(40 / 28);
  color: $red;
  @include mq(md) {
    margin-top: vw-m(27);
    font-size: vw-m(42);
    line-height: calc(58 / 42);
    letter-spacing: 0.06em;
  }
}

.p-note__container:last-child  .p-note__heading {
  margin-top: 0;
}

.p-note__list {
  margin-top: rem(41);
  @include mq(md) {
    margin-top: vw-m(45);
  }
}

.p-note__item {
  padding-bottom: rem(18);
  border-bottom: 1px dashed #F4A5AC;
  @include mq(md) {
    padding-bottom: vw-m(40);
    border-bottom: vw-m(2) dashed #F4A5AC;
  }
}

.p-note__item + .p-note__item {
  margin-top: rem(26.5);
  @include mq(md) {
    margin-top: vw-m(40);
  }
}

.p-note__item-txt {
  font-size: var(--fz16);
  line-height: calc(24 / 16);
  letter-spacing: 0.06em;
  font-weight: 500;
  @include mq(md) {
    font-size: vw-m(24);
    line-height: calc(35 / 24);
  }
}

.p-note__item-txt::before {
  content: "・";
  display: inline-block;
}

.p-note__item-detail-txt {
  margin-top: rem(11);
  font-size: var(--fz16);
  line-height: calc(24 / 16);
  letter-spacing: 0.06em;
  font-weight: 400;
  max-width: 95.1%;
  width: 100%;
  margin-left: auto;
  @include mq(md) {
    max-width: 90.7%;
    margin-top: vw-m(23);
    font-size: vw-m(24);
    line-height: calc(35 / 24);
  }
}

.p-note__item-sub-list {
  max-width: 95.1%;
  width: 100%;
  margin-left: auto;
  margin-top: rem(11);
  @include mq(md) {
    max-width: 92.7%;
    margin-top: vw-m(20);
  }
}

.p-note__item-sub-item + .p-note__item-sub-item {
  margin-top: rem(10);
  @include mq(md) {
    margin-top: vw-m(0);
  }
}

.p-note__item-sub-item {
  counter-increment: sub-item;
  font-size: var(--fz16);
  line-height: calc(24 / 16);
  letter-spacing: 0.06em;
  font-weight: 400;
  @include mq(md) {
    font-size: vw-m(24);
    line-height: calc(35 / 24);
  }
}
.p-note__item-sub-txt::before {
  content: "(" counter(sub-item) ")";
  display: inline-block;
}

