@use "global" as *;

.c-ttl{
  font-size: rem(22);
  font-weight: 700;
  letter-spacing: 0.08em;
  line-height: calc(40/22);
  @include mq(md) {
    font-size: vw-m(28);
    line-height: calc(34/28);
    letter-spacing: 0.1em;
  }
}
