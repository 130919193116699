@use "global" as *;

.l-bg-wrapper {
  background-image: url(../img/facilities/bg_facilities.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: auto;
  border-radius: rem(30);
  @include mq(md) {
    border-radius: 0;
    background-image: url(../img/background-sp.png);
    width: 100vw;
    margin: 0 calc(50% - 50vw);
  }
}
