@use 'sass:math';

// インナー幅
$innerWidth: 1250px;

// 余白
$padding-pc: 25px;
$padding-sp: 30px;

// フォント
$font-main: "source-han-sans-japanese", "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, sans-serif;
$font-sub: "Helvetica", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, sans-serif;

// 色指定
$black: #151515;
$white: #fff;
$navy: #00317A;
$red: #e60013;
$pink: #ff5362;
$green: #029595;
$bgblue: #eafbff;
$bgpink: #FCF0F0;
$gray: #F0F0F0;
$bg: #F9E9EA;

// ブレイクポイント
$breakpoints: (
  sm: 600,
  md: 767,
  lg: 1080,
  xl: 1440,
);

// メディアクエリ
$mediaquerys: (
  sm: "screen and (max-width: #{map-get($breakpoints,'sm')}px)",
  md: "screen and (max-width: #{map-get($breakpoints,'md')}px)",
  lg: "screen and (max-width: #{map-get($breakpoints,'lg')}px)",
  xl: "screen and (max-width: #{map-get($breakpoints,'xl')}px)",
);

// メディアクエリを "@include mq(){}" で書くことができる
@mixin mq($mediaquery: md) {
  @media #{map-get($mediaquerys, $mediaquery)} {
    @content;
  }
}

:root {
  --fz12: max(0.75rem, 10px);
  --fz14: max(0.875rem, 10px);
  --fz16: max(1rem, 10px);
  --fz18: max(1.125rem, 10px);
}