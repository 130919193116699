@use "global" as *;

.p-header {
  height: rem(104);
  background-color: #fff;
  transition: height 0.4s ease-in-out 0.1s;
  @include mq(md) {
    height: vw-m(94);
  }
}

.p-header.is-scrolled {
  height: rem(64);
}

.p-header__inner {
  padding: 0 0 0 rem(39);
  height: inherit;
  display: flex;
  position: relative;
  justify-content: space-between;
  @include mq(md) {
    padding-left: vw-m(30);
    padding-right: 0;
  }
}

.p-header__logo {
  max-width: rem(210);
  width: 100%;
  margin-right: rem(20);
  height: inherit;
  @include mq(md) {
      max-width: vw-m(313);
      flex-shrink: 0;
      margin-right: 0;
  }
}

.p-header__logo > a {
  height: inherit;
  display: flex;
  align-items: center;
}

.p-header__logo img {
  max-width: rem(210);
  width: 100%;
  height: rem(21);
  object-fit: contain;
  @include mq(md) {
    max-width: vw-m(313);
    height: rem(31);
  }
}

.p-header__nav-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: inherit;
  transition: height 0.2s ease-in-out;
}

.p-header__nav {
  display: block;
  height: inherit;

  @include mq(md) {
    display: none;
  }
}

.p-header__nav-items {
  display: flex;
  height: inherit;
  gap: rem(38);
  @media (max-width: 1330px) {
    gap: rem(24);
  }
  @media (max-width: 1080px) {
    gap: rem(16);
  }
}

.p-header__nav-item {
  height: inherit;
}

.p-header__nav-item-link {
  height: inherit;
  display: flex;
  align-items: center;
  font-size: max(rem(16), 11px);
  font-weight: 700;
  line-height: 1;
  color: $black;
  position: relative;
  cursor: pointer;
  transition: .3s;
}

.p-header__nav-item-link--about {
  gap: rem(12);
  @media (max-width: 1024px) {
    gap: rem(10);
  }
}

.p-header__nav-item-link--facility {
  gap: rem(15);
  @media (max-width: 1024px) {
    gap: rem(10);
  }
}

.p-header__nav-item-link--charge {
  gap: rem(14);
  @media (max-width: 1024px) {
    gap: rem(10);
  }
}

.p-header__nav-item-link--application {
  gap: rem(11);
  @media (max-width: 1024px) {
    gap: rem(10);
  }
}

.p-header__nav-item-link--access {
  gap: rem(14);
  @media (max-width: 1024px) {
    gap: rem(10);
  }
}


.p-header__nav-item-icon svg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.p-header__nav-item-link--about svg {
  width: rem(21);
  height: rem(14);
}

.p-header__nav-item-link--facility svg {
  width: rem(18);
  height: rem(12);
}

.p-header__nav-item-link--charge svg {
  width: rem(18);
  height: rem(19);
}

.p-header__nav-item-link--application svg {
  width: rem(11);
  height: rem(13);
}

.p-header__nav-item-link--access svg {
  width: rem(16);
  height: rem(21);
}
.p-header__nav-item-icon svg path {
  transition: all .3s ease;
}

@media (any-hover: hover) {
  .p-header__nav-item-link:hover {
    color: $red;
  }
  .p-header__nav-item-link:hover .p-header__nav-item-icon path {
    fill: #FFA2AA;
  }
}

.p-header__nav-right{
  margin-left: rem(15);
  @include mq(md) {
    display: none;
  }
}

.p-header__nav-right-items {
  width: rem(257);
}

.p-header__nav-right-item:first-child {
  border-left: rem(1) solid $black;
  border-right: rem(1) solid $black;
  background-color: $white;
  color: #4D4D4D;
  font-size: max(rem(18), 12px);
  font-weight: 700;
  transition: all .3s ease;
}

.p-header__nav-right-item-sub {
  font-size: max(rem(10), 7px);
  font-weight: 400;
  color: #4D4D4D;
  transition: all .3s ease;
}

.p-header__nav-right-item:first-child .p-header__nav-right-item-icon path {
  transition: all .3s ease;
}

@media (any-hover: hover) {
  .p-header__nav-right-item:first-child:hover {
    color: $red;
  }
  .p-header__nav-right-item:first-child:hover .p-header__nav-right-item-sub {
    color: $red;
  }
  .p-header__nav-right-item:first-child:hover .p-header__nav-right-item-icon path {
    fill: #FFA2AA;
  }
}

.p-header__nav-right-item:nth-child(2) {
  border: rem(1) solid $black;
  background-color: $red;
  border-radius: 0px 0px 0px 20px;
  color: $white;
  font-size: max(rem(18), 12px);
  letter-spacing: 0.06em;
  font-weight: 900;
  line-height: 1;
  transition: all .3s ease;
}

@media (any-hover: hover) {
  .p-header__nav-right-item:nth-child(2):hover {
    background-color: $pink;
  }
}


.p-header__nav-right-item-link {
  width: 100%;
  min-height: rem(64);
  gap: rem(9.5);
  display: flex;
  align-items: center;
  padding-left: rem(40.5);
  padding-right: rem(15);
  @media (max-width: 1024px) {
    padding-left: rem(25);
  }
}

.p-header__nav-right-item-icon svg {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: all .3s ease;
}

.p-header__nav-right-item:nth-child(2) .p-header__nav-right-item-icon {
  width: rem(15.94);
  height: rem(18.21);
}
.p-header__nav-right-item:first-child .p-header__nav-right-item-icon {
  width: rem(19.09);
  height: rem(19.11);
  flex-shrink: 0;
}

.p-header__nav-right-item-ttl {
  display: flex;
  align-items: baseline;
  white-space: nowrap;
}

/* spここから */
.p-header__hamburger {
  display: none;
  margin: 0;
  padding: 0;
  outline: none;
  border: none;
  position: relative;
  z-index: 999;
  width: vw-m(112);
  height: inherit;
  background: $red;
  cursor: pointer;
  transition: .3s;
  @include mq(md) {
    display: block;
  }
}

.p-header__hamburger.is-open {
  background-color: $red;
}

.p-header__hamburger span {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  width: vw-m(57);
  height: vw-m(4);
  background: #fff;
  transition: 0.3s;
  border-radius: vw-m(2);
}

.p-header__hamburger span:nth-of-type(1) {
  top: vw-m(-15);
}

.p-header__hamburger span:nth-of-type(2) {
  top: 0;
}

.p-header__hamburger span:nth-of-type(3) {
  top: vw-m(15);
}


.p-header__hamburger.is-open span:nth-of-type(1) {
  top: vw-m(4);
  transform: translateX(-50%) rotate(45deg);
}

.p-header__hamburger.is-open span:nth-of-type(2) {
  opacity: 0;
}

.p-header__hamburger.is-open span:nth-of-type(3) {
  top: vw-m(-4);
  transform: translateX(-50%) rotate(-45deg);
}

.p-header__sp {
  display: none;
  position: absolute;
  z-index: 900;
  top: vw-m(94);
  right: 0;
  bottom: 0;
  width: 100%;
  min-height: 100vh;
  background-color: $white;
  overflow-y: scroll;
  scrollbar-width: none;
  transform: translateX(0);
}

.p-header__sp::-webkit-scrollbar {
  display:none;
}

.p-header__sp-nav {
  padding: 0 vw-m(7);
  border-top: rem(1) solid #d1d1d1;
}

.p-header__sp-items {
  padding-bottom: vw-m(40);
}

.p-header__sp-item-link {
  padding: vw-m(29) 0  vw-m(29) vw-m(35);
  font-size: vw-m(32);
  font-weight: 700;
  color: $black;
  display: flex;
  align-items: center;
  position: relative;
}

.p-header__sp-item-link:after {
  content: "";
  display: block;
  width: vw-m(20);
  height: vw-m(17);
  background-image: url(../img/icon/icon_arrow.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  right: vw-m(37);
  top: 54%;
  transform: translateY(-50%);
}

.p-header__sp-item-link--about {
  gap: vw-m(32);
}

.p-header__sp-item-link--facility {
  gap: vw-m(34.7);
}

.p-header__sp-item-link--charge {
  gap: vw-m(34.7);
}

.p-header__sp-item-link--application {
  gap: vw-m(42);
}

.p-header__sp-item-link--access {
  gap: vw-m(37.6);
}

.p-header__sp-item-icon {
  height: 100%;
  display: flex;
  align-items: center;
}

.p-header__sp-item-icon svg {
  object-fit: contain;
}

.p-header__sp-item-link--about svg {
  width: vw-m(43.74);
  height: vw-m(30.13);
}

.p-header__sp-item-link--facility svg {
  width: vw-m(38.31);
  height: vw-m(25.42);
}

.p-header__sp-item-link--charge svg {
  width: vw-m(38.31);
  height: vw-m(39.41);
}

.p-header__sp-item-link--application svg {
  width: vw-m(23.99);
  height: vw-m(27.98);
}

.p-header__sp-item-link--access svg {
  width: vw-m(32.35);
  height: vw-m(42.81);
}

.p-header__sp-lists {
  padding-top: rem(6);
  padding-bottom: rem(16);
}

.p-header__sp-sub-items {
  max-width: vw-m(625);
  margin-left: auto;
}

.p-header__sp-sub-item-link {
  display: flex;
  align-items: center;
  gap: vw-m(30);
  font-size: vw-m(28);
  font-weight: 700;
  color: #4d4d4d;
  line-height: calc(40/28);
  padding: vw-m(21) 0 ;
  border-bottom: rem(1) dashed #D1D1D1;
}

.p-header__sp-sub-item-link::before {
  content: "";
  display: block;
  width: vw-m(20);
  height: vw-m(17);
  background-image: url(../img/icon/icon_arrow-gy.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-left: vw-m(23);
}

.p-header__sp-btn-wrapper {
  display: flex;
  gap: vw-m(18);
  padding: 0 vw-m(22);
}

.p-header__sp-btn-left {
  display: flex;
  gap: vw-m(18);
  flex-direction: column;
  width: 50%;
}

.p-header__sp-btn-link {
  max-width: vw-m(336);
  width: 100%;
  min-height: vw-m(98);
  border-radius: vw-m(10);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 vw-m(10);
  font-size: vw-m(24);
  font-weight: 700;
}

.p-header__sp-btn-link--availability {
  color: #4d4d4d;
  border: vw-m(2) solid $black;
}

.p-header__sp-btn-link--contact {
  background-color: $red;
  color: $white;
}

.p-header__sp-btn-icon {
  display: flex;
  align-items: center;
}

.p-header__sp-btn-icon svg {
  object-fit: contain;
}

.p-header__sp-btn-link--availability .p-header__sp-btn-icon svg {
  width: vw-m(21);
  height: vw-m(24);
}

.p-header__sp-btn-link--contact .p-header__sp-btn-icon svg {
  width: vw-m(26);
  height: vw-m(26);
}

.p-header__sp-btn-link--availability .p-header__sp-btn-ttl {
  margin-left: vw-m(10);
}
.p-header__sp-btn-link--contact .p-header__sp-btn-ttl {
  margin-left: vw-m(14);
}


.p-header__sp-btn-sub {
  font-size: vw-m(16);
  font-weight: 400;
}

.p-header__sp-btn-right {
  width: 50%;
}

.p-header__sp-right-btn {
  max-width: vw-m(336);
  width: 100%;
  min-height: vw-m(214);
  border-radius: vw-m(10);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.p-header__sp-bottom-link {
  text-align: center;
  display: block;
  margin-top: vw-m(42);
  color: #959595;
  font-size: max(vw-m(18), 10px);
  letter-spacing: 0.14em;
  font-weight: 500;
  text-decoration: underline;
  text-underline-offset: 2px;
}

.p-header__sp-img-wrapper {
  padding-bottom: vw-m(165);
  @media screen and (max-width: 430px) {
    padding-bottom: vw-m(280);
  }
}