@use "global" as *;

.c-tel-btn {
  max-width: rem(576);
  width: 100%;
  border-radius: rem(20);
  min-height: rem(110);
  padding: rem(27) rem(30) rem(27) rem(30);
  display: block;
  background: #f0f0f0;
  margin-inline: auto;
  @include mq(md) {
    max-width: unset;
    padding: vw-m(29) vw-m(30) vw-m(30) vw-m(30);
    min-height: vw-m(172);
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}

.c-tel-btn__wrapper {
  display: flex;
  align-items: center;
  gap: rem(16);
  align-items: baseline;
  margin-left: rem(34);
  @include mq(md) {
    gap: vw-m(22);
    margin-left: vw-m(37);
  }
}

.c-tel-btn__small {
  font-size: rem(32);
  font-weight: 500;
  color: #a2a2a2;
  @include mq(md) {
    font-size: vw-m(32);
  }
}

.c-tel-btn__num {
  font-size: rem(52);
  font-weight: 700;
  letter-spacing: 0.02em;
  @include mq(md) {
    font-size: vw-m(52);
  }
}

.c-tel-btn__info {
  width: 100%;
  text-align: center;
  font-size: rem(20);
  letter-spacing: 0.02em;
  margin-top: rem(5);
  line-height: calc(29 / 20);
  @include mq(md) {
    font-size: vw-m(20);
  }
}