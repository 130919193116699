@use "global" as *;


.p-exemption {
  max-width: rem(968);
  width: 100%;
  margin-inline: auto;
}

.p-exemption__txt {
  margin-top: rem(8);
  font-size: var(--fz16);
  line-height: calc(30/16);
  letter-spacing: 0.09em;
  font-weight: 500;
  @include mq(md) {
    letter-spacing: 0.06em;
    margin-top: vw-m(40);
    font-size: vw-m(24);
    line-height: calc(35/24);
  }
}

.p-exemption__list {
  margin-top: rem(40);
  @include mq(md) {
    margin-top: vw-m(60);
  }
}

.p-exemption__item {
  padding-bottom: rem(13);
  border-bottom: 1px dashed #D1D1D1;
  color: #4d4d4d;
  font-size: var(--fz16);
  line-height: calc(24/16);
  letter-spacing: 0.06em;
  font-weight: 500;
  counter-increment: item;
  display: flex;
  padding-left: rem(15);
  @include mq(md) {
    padding-bottom: vw-m(22);
    border-bottom: vw-m(2) dashed #D1D1D1;
    font-size: vw-m(24);
    line-height: calc(35/24);
    font-weight: 400;
    padding-left: vw-m(20);
  }
}

.p-exemption__item::before {
  content: "(" counter(item) ")";
  margin-right: rem(10);
  @include mq(md) {
    margin-right: vw-m(20);
  }
}

.p-exemption__item + .p-exemption__item {
  margin-top: rem(14);
  @include mq(md) {
    margin-top: vw-m(22);
  }
}

.p-exemption__note {
  margin-top: rem(15);
  font-size: var(--fz16);
  line-height: calc(30/16);
  letter-spacing: 0.09em;
  font-weight: 700;
  color: $red;
  @include mq(md) {
    margin-top: vw-m(40);
    font-size: vw-m(24);
    line-height: calc(35/24);
    letter-spacing: 0.06em;
  }
}
