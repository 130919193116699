@use "global" as *;

.c-sec-ttl {
  font-size: rem(46);
  font-family: $font-sub;
  font-weight: 700;
  color: $red;
  line-height: 1;
  @include mq(md) {
    font-size: vw-m(56);
  }
}

.c-sec-ttl::after{
  content: attr(data-sub);
  display: block;
  font-size: rem(16);
  font-weight: 700;
  color: $black;
  font-family: $font-main;
  margin-top: rem(10);
  @include mq(md) {
    font-size: vw-m(22);
    margin-top: 0;
  }
}

.c-sec-ttl.c-sec-ttl--head {
  font-size: rem(70);
  @include mq(md) {
    font-size: vw-m(56);
  }
}

.c-sec-ttl.c-sec-ttl--head::after {
  font-size: rem(24);
  @include mq(md) {
    font-size: vw-m(22);
  }
}

.c-sec-ttl.c-sec-ttl--small {
  font-size: max(rem(26), 10px);
  line-height: calc(37 / 26);
  font-weight: 700;
  @include mq(md) {
    font-size: vw-m(42);
    line-height: calc(61 / 42);
    letter-spacing: 0.11em;
  }
}

.c-sec-ttl.c-sec-ttl--small::after {
  font-size: max(rem(16), 10px);
  letter-spacing: 0.08em;
  margin-top: 0;
  @include mq(md) {
    font-size: vw-m(26);
    letter-spacing: 0.09em;
    margin-top: vw-m(-10);
  }
}

.c-sec-ttl.c-sec-ttl--tiny {
  font-size: max(rem(16), 10px);
  @include mq(md) {
    font-size: vw-m(42);
    letter-spacing: 0.06em;
  }
}

.c-sec-ttl.c-sec-ttl--tiny::after {
  font-family: $font-main;
  font-size: max(rem(16), 10px);
  letter-spacing: 0.12em;
  margin-top: 0;
  @include mq(md) {
    font-size: vw-m(26);
    letter-spacing: 0.06em;
    margin-top: vw-m(7);
  }
}