@use "global" as *;

// インナー幅＋余白
$breakpointInner: $innerWidth + strip-unit($padding-pc) * 2;

// ルートフォント設定
html {
  // インナー幅＋余白+1px ~
  font-size: 100%;

  // 768px ~ インナー幅＋余白
  @media ( max-width: $breakpointInner ) {
    font-size: vw(strip-unit($breakpointInner), 16);
  }

  // 376px ~ 767px
  @include mq(md) {
    font-size: 100%;
  }

  // ~ 375px
  @media (max-width: 375px) {
    font-size: vw(375, 16);
  }

  @media (max-width: 320px) {
    font-size: vw(320, 14);
  }
  background-color: $white;
}

// フォント、カラー設定
body {
  font-family: $font-main;
  color: $black;
}

// ホバー
a,
button {
  &:hover {
    cursor: pointer;
  }
}