@use "global" as *;

.c-btn {
  background-color: $black;
  max-width: rem(222);
  width: 100%;
  min-height: rem(46);
  padding: rem(10) rem(20) rem(10) rem(30);
  border-radius: rem(23);
  color: $white;
  font-size: max(rem(16), 10px);
  letter-spacing: 0.04em;
  display: flex;
  align-items: center;
  position: relative;
  font-weight: 700;
  transition: all 0.3s ease;
  @include mq(md) {
    max-width: vw-m(340);
    min-height: vw-m(78);
    padding: vw-m(15) vw-m(23) vw-m(15) vw-m(40);
    font-size: vw-m(20);
    border-radius: vw-m(40);
  }
}

.c-btn::after {
  content: "";
  display: block;
  width: rem(13);
  height: rem(13);
  background-image: url(../img/icon/icon_arrow-wh.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  right: rem(20);
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s ease-in-out;
  @include mq(md) {
    width: vw-m(21);
    height: vw-m(21);
    right: vw-m(23);
  }
}

@media (any-hover: hover) {
  .c-btn:hover {
    scale: 1.025;
    background-color: #FF5362;
  }

  .c-btn:hover::after {
    scale: 1.025;
  }
}

.c-btn.c-btn--pink {
  background-color: #FF5362;
  color: $white;
}

.c-btn.c-btn--red {
  background-color: $red;
  color: $white;
}

@media (any-hover: hover) {
  .c-btn.c-btn--red:hover {
    background-color: $black;
  }
}