@use "global" as *;

.p-charge {
  background-color: $white;
  padding-top: rem(36);
  padding-bottom: rem(172);
  @include mq(md) {
    padding-top: vw-m(60);
    padding-bottom: vw-m(85);
  }
}

.p-charge__bg {
  background-position: top left;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  padding-bottom: rem(75);
  position: relative;
  background-color: #F9E9EA;
  margin-top: rem(100);
  @include mq(md) {
    margin-top: vw-m(188);
    padding-bottom: vw-m(95);
  }
}

.p-charge__bg::before,
.p-charge__bg::after {
  content: "";
  display: block;
  width: 100%;
  height: rem(112);
  position: absolute;
  left: 0;
  background-size: 100% 100%;
  background-position: top left;
  background-repeat: no-repeat;
  @include mq(md) {
    height: vw-m(117);
  }
}

.p-charge__bg::before {
  top: rem(-93);
  background-image: url(../img/bg_top.png);
  @include mq(md) {
    top: vw-m(-116);
    background-image: url(../img/bg_top-sp.png);
  }
}

.p-charge__bg::after {
  bottom: rem(-110);
  background-position: bottom left;
  background-image: url(../img/bg_bottom.png);
  @include mq(md) {
    background-image: url(../img/bg_bottom-sp.png);
    bottom: vw-m(-110);
  }
}

.p-charge__txt {
  font-weight: 500;
  font-size: var(--fz16);
  letter-spacing: 0.09em;
  max-width: rem(960);
  width: 100%;
  position: relative;
  top: rem(-60);
  margin-inline: auto;
  @include mq(md) {
    font-weight: 400;
    max-width: unset;
    width: 100%;
    font-size: vw-m(26);
    line-height: calc(66 / 26);
    top: vw-m(-150);
  }
}

.p-charge__txt-link {
  color: $green;
  font-weight: 700;
  transition: opacity 0.3s ease-in-out;
  @include mq(md) {
    font-size: vw-m(26);
    line-height: calc(66 / 26);
  }
}

.p-charge__txt-link:first-of-type {
  pointer-events: none;
  cursor: default;
  @include mq(md) {
    pointer-events: auto;
  }
}

@media (any-hover: hover) {
  .p-charge__txt-link:last-of-type:hover {
    opacity: 0.7;
  }
}

.p-charge__container {
  background-color: $white;
  border-radius: rem(36);
  padding: rem(56) rem(57);
  max-width: rem(1100);
  width: 100%;
  margin: 0 auto;
  @include mq(md) {
    width: 100vw;
    margin: vw-m(-124) calc(50% - 50vw) 0;
    border-radius: vw-m(30);
    padding: vw-m(72) vw-m(30) vw-m(90);
  }
}

.p-charge__container.p-charge__container--rental {
  padding-top: rem(46);
  margin-top: rem(61);
  padding-bottom: rem(70);
  @include mq(md) {
    padding-top: vw-m(130);
    margin-top: vw-m(130);
    padding-bottom: vw-m(90);
  }
}

.p-charge__heading {
  text-align: center;
  @include mq(md) {
    text-align: left;
  }
}

.p-charge__img-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: rem(41);
  margin-top: rem(56);
  @include mq(md) {
    margin-top: vw-m(50);
    gap: vw-m(22);
  }
}

.p-charge__top-img img {
  aspect-ratio: 472/300;
  width: 100%;
  object-fit: contain;
  @include mq(md) {
    aspect-ratio: 334/250;
  }
}

.p-charge__info {
  margin-top: rem(35);
  @include mq(md) {
    margin-top: vw-m(60);
  }
}

.p-charge-info__link-btn {
  margin-top: rem(13);
  transition: all 0.3s ease;
  @include mq(md) {
    margin-top: vw-m(40);
  }
}

.p-charge__price {
  margin-top: rem(64);
  max-width: rem(986);
  width: 100%;
  margin-inline: auto;
  @include mq(md) {
    margin-top: vw-m(90);
  }
}

.p-charge__exemption {
  margin-top: rem(74);
  @include mq(md) {
    margin-top: vw-m(90);
  }
}

.p-charge__info__heading {
  text-align: center;
  @include mq(md) {
    text-align: left;
  }
}

.p-charge__link-wrapper {
  display: flex;
  gap: rem(60);
  margin-top: rem(48);
  @include mq(md) {
    margin-top: vw-m(90);
    flex-direction: column;
    gap: vw-m(50);
  }
}

.p-charge__link-item {
  max-width: rem(520);
  width: 100%;
  @include mq(md) {
    max-width: unset;
  }
}

.p-charge__btn {
  font-size: rem(26);
  font-weight: 700;
  letter-spacing: 0.11em;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.2s ease-in-out;
  width: 100%;
  background: $white;
  border-radius: rem(20);
  border: rem(1) solid $black;
  min-height: rem(158);
  padding: rem(30) rem(30) rem(30) rem(30);
  position: relative;
  overflow: hidden;
  z-index: 1;
  @include mq(md) {
    font-size: vw-m(34);
    padding: vw-m(70) vw-m(30) vw-m(70) vw-m(30);
    min-height: vw-m(208);
    border-radius: vw-m(20);
  }
}

.p-charge__btn:after {
  content: "";
  display: block;
  background-image: url(../img/icon/icon_arrow-right.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: rem(41);
  height: rem(41);
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
  @include mq(md) {
    width: vw-m(66);
    height: vw-m(66);
    right: vw-m(-1);
    bottom: vw-m(-1);
  }
}

.p-charge__btn-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.p-charge__btn-icon svg {
  width: 100%;
  object-fit: contain;
}
.p-charge__btn.p-charge__btn--download .p-charge__btn-icon {
  width: rem(20.96);
  margin-right: rem(20);
  @include mq(md) {
    width: vw-m(27.81);
    margin-right: vw-m(26.5);
  }
}

.p-charge__btn.p-charge__btn--external .p-charge__btn-icon {
  width: rem(23.65);
  margin-right: rem(19);
  @include mq(md) {
    width: vw-m(31.39);
    margin-right: vw-m(25.2);
  }
}

.p-charge__btn-txt--small {
  font-size: var(--fz16);
  letter-spacing: 0.11em;
  @include mq(md) {
    font-size: vw-m(21);
  }
}

.p-charge__btn-icon svg path {
  transition: fill 0.3s ease-in-out;
}

@media (any-hover: hover) {
  .p-charge__btn:hover {
    color: $red;
  }
  .p-charge__btn:hover .p-charge__btn-icon svg path {
    fill: #FFA2AA;
  }
}

.p-charge__contact-area {
  margin-top: rem(38);
  @include mq(md) {
    margin-top: vw-m(40);
  }
}

