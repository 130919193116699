@use "global" as *;

.c-page-top {
  background-color: rgb(180, 214, 182);
  width: rem(100);
  height: rem(100);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: rem(20);
  text-align: center;
  color: $white;
}