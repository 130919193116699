@use "global" as *;

.p-top-about {
  background-color: $white;
  padding-top: rem(67);
  @include mq(md) {
    padding-top:vw-m(45);
  }
}

.p-top-about__bg {
  background-image: url(../img/about_bg-pc.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  min-height: rem(888);
  @include mq(md) {
    background-image: url(../img/about_bg-sp.png);
    min-height: vw-m(1782);
    background-size: cover;
  }
}

.p-top-about__ttl.c-sec-ttl {
  color: $black;
  margin-left: 50%;
  margin-right: auto;
  padding-top: rem(7);
  @include mq(md) {
    margin-left: 0;
    margin-right: 0;
    padding-top: vw-m(46);
  }
}

.p-top-about__wrapper {
  display: flex;
  justify-content: center;
  margin-top: rem(30);
  @include mq(md) {
    margin-top: vw-m(-10);
    flex-direction: column;
  }
}

.p-top-about__img-wrapper {
  max-width: rem(555);
  width: 100%;
  margin-top: rem(14);
  margin-left: rem(-18);
  @include mq(md) {
    max-width: vw-m(545);
    order: 2;
    margin-inline: auto;
    margin-top: vw-m(-55);
  }
}

.p-top-about__img-wrapper img {
  width: 100%;
  aspect-ratio: 493 / 363;
  object-fit: contain;
  @include mq(md) {
    aspect-ratio: 488 / 360;
  }
}

.p-top-about__content {
  max-width: rem(452);
  width: 100%;
  @include mq(md) {
    display: contents;
  }
}

.p-top-about__heading {
  margin-left: rem(-43);
  font-size: rem(26);
  letter-spacing: 0.06em;
  font-weight: 700;
  line-height: calc(37 / 26);
  position: relative;
  z-index: 2;
  @include mq(md) {
    margin-left: 0;
    order: 1;
    font-size: vw-m(42);
    line-height: calc(86 / 42);
    margin-top: vw-m(41);
  }
}

.p-top-about__heading span {
  color: $red;
  display: inline-block;
  position: relative;
  z-index: 1;
  --clipValue: inset(0 100% 0 0);
}

.p-top-about__heading span:after {
  content: "";
  display: block;
  width: 97%;
  height: rem(10);
  background: $white;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
  border-radius: rem(20);
  display: block;
  width: 97%;
  clip-path: var(--clipValue);
  transition: clip-path 0.3s ease-out;
  @include mq(md) {
    height: vw-m(32);
    border-radius: vw-m(10);
    bottom: vw-m(16);
  }
}

.p-top-about__txt-wrapper {
  margin-top: rem(43);
  margin-left: rem(25);
  @include mq(md) {
    margin-left: 0;
    order: 3;
    max-width: vw-m(585);
    width: 100%;
    margin-inline: auto;
    margin-top: vw-m(6);
  }
}

.p-top-about__txt {
  font-size: max(rem(16), 10px);
  letter-spacing: 0.09em;
  font-weight: 400;
  line-height: calc(30 / 16);
  @include mq(md) {
    font-size: vw-m(24);
    letter-spacing: 0.15em;
    line-height: 2.25;
  }
}

.p-top-about__txt span {
  color: $red;
  font-size: rem(22);
  letter-spacing: 0.09em;
  font-weight: 700;
  position: relative;
  z-index: 1;
  --clipValue: inset(0 100% 0 0);
  @include mq(md) {
    font-size: vw-m(32);
  }
}

.p-top-about__txt span:after {
  content: "";
  display: block;
  width: 100%;
  height: rem(10);
  background: $white;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
  border-radius: rem(20);
  clip-path: var(--clipValue);
  transition: clip-path 0.3s ease-out;
  @include mq(md) {
    height: vw-m(25);
    border-radius: vw-m(7);
  }
}
.p-top-about__txt + .p-top-about__txt {
  margin-top: rem(7);
  @include mq(md) {
    margin-top: vw-m(21);
  }
}

.p-top-about__link {
  max-width: rem(284);
  width: 100%;
  margin-top: rem(62);
  font-size: max(rem(14), 10px);
  @include mq(md) {
    margin-top: vw-m(50);
    max-width: vw-m(600);
    min-width: vw-m(600);
    font-size: vw-m(24);
    min-height: vw-m(100);
    border-radius: vw-m(50);
    padding-left: vw-m(60);
  }
}

.p-top-about__link:after {
  @include mq(md) {
    width: vw-m(34);
    height: vw-m(34);
  }
}

.p-top-about__bottom-wrapper {
  display: flex;
  justify-content: center;
  margin-top: rem(36);
  width: 100%;
  @include mq(md) {
    margin-top: vw-m(41);
  }
}
