@use "global" as *;

.c-open-btn {
  width: 14px;
  height: 14px;
  position: relative;
  cursor: pointer;
}

.c-open-btn span  {
  background: $white;
  display: block;
  height: 2px;
  transform: translateX(-50%);
  width: 14px;
  transition: 0.4s;
  position: absolute;
  left: 50%;
}

.c-open-btn span:nth-of-type(1){
  transform: translateX(-50%) rotate(-90deg);
}

.c-open-btn span:nth-of-type(2) {
  transform: translateX(-50%) rotate(0deg);
}

.c-open-btn.c-open-btn--red {
  background: #ff5362;
  width: rem(29);
  height: rem(29);
  @include mq(md) {
    width: vw-m(61);
    height: vw-m(61);
    background-color: $red;
  }
}

.c-open-btn.c-open-btn--red span {
  width: 10px;
  height: 2px;
  @include mq(md) {
    width: vw-m(21);
    height: vw-m(3);
  }
}

/*rotate*/
.js-rotate.is-active span:nth-of-type(1){
  transform: translateX(-50%) rotate(-180deg);
}

.js-rotate.is-active span:nth-of-type(2){
  transform: translateX(-50%) rotate(0deg);
}