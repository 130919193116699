@use "global" as *;

.p-footer {
  position: relative;
  overflow: hidden;
  margin-top: rem(-1);
  @include mq(md) {
    margin-top: vw-m(-1);
  }
}

.p-footer__container {
  position: relative;
  z-index: 2;
  background-color: #fff;
  margin-bottom: rem(333);
  @include mq(md) {
    margin-bottom: vw-m(364);
  }
}

.p-footer__bg {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: rem(409);
  z-index: -1;
  @media (min-width: 1441px) {
    height: rem(450);
  }
  @include mq(md) {
    height: vw-m(423);
    bottom: vw-m(96);
  }
}

.p-footer__bg::before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(0.00390625px);
  top: 0;
  left: 0;
  z-index: 1;
}

.p-footer__container::after {
  content: "";
  position: absolute;
  bottom: vw-lg(-103);
  left: 0;
  width: 100%;
  background-image: url(../img/bg_wave.png);
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  height: vw-lg(116);
  z-index: 1;
  @include mq(md) {
    background-image: url(../img/bg_wave-sp.png);
    height: vw-m(112);
    bottom: vw-m(-102);
  }
}

.p-footer__bg-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.p-footer__inner {
  max-width: rem(1005);
  width: 100%;
  margin-inline: auto;
  padding-inline: rem(25);
  display: flex;
  gap: rem(109);
  padding-top: rem(56);
  padding-bottom: rem(50);
  z-index: 2;

  @media (max-width: 1024px) {
    gap: rem(80);
  }
  @include mq(md) {
    flex-direction: column;
    gap: vw-m(82);
    padding-top: vw-m(148);
    padding-right: vw-m(30);
    padding-bottom: vw-m(40);
    padding-left: vw-m(50);
  }
}

.p-footer__info {
  max-width: rem(390);
  width: 100%;
  @include mq(md) {
    max-width: vw-m(570);
    margin-inline: auto;
  }
}

.p-footer__info-wrapper {
  padding-left: rem(43);
  @include mq(md) {
    padding-left: vw-m(12);
  }
}

.p-footer__logo {
  max-width: rem(379);
  width: 100%;
  display: block;
  @include mq(md) {
    max-width: vw-m(570);
  }
}

.p-footer__logo img {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.p-footer__info-txt {
  margin-top: rem(16);
  font-style: normal;
    font-size: max(rem(16), 10px);
  letter-spacing: 0.14em;
  font-weight: 500;
  @include mq(md) {
    margin-top: vw-m(16);
    font-size: vw-m(24);
  }
}

.p-footer__info-tel-wrapper {
  display: flex;
  align-items: center;
  gap: rem(15);
  margin-top: rem(10);
  @include mq(md) {
    margin-top: vw-m(32);
    gap: vw-m(27.5);
    align-items: flex-end;
  }
}

.p-footer__icon-tel {
  width: rem(21);
  height: rem(21);
  @include mq(md) {
    width: vw-m(39);
    height: vw-m(39);
  }
}

.p-footer__icon-tel img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.p-footer__info-tel {
  color: $red;
  pointer-events: none;
  font-size: rem(21);
  letter-spacing: 0.06em;
  font-weight: 700;
  line-height: calc(30 / 21);
  @include mq(md) {
    pointer-events: auto;
    font-size: vw-m(35);
    line-height: calc(51 / 35);
  }
}

.p-footer__info-fax {
  margin-top: rem(14);
  font-size: max(rem(16), 10px);
  font-weight: 500;
  @include mq(md) {
    margin-top: vw-m(37);
    font-size: vw-m(24);
    letter-spacing: 0.14em;
  }
}

.p-footer__info-mail {
  margin-top: rem(10);
  font-size: max(rem(16), 10px);
  font-weight: 500;
  white-space: nowrap;
  @include mq(md) {
    margin-top: vw-m(17);
    font-size: vw-m(24);
  }
}

.p-footer__map-btn {
  font-size: max(rem(14), 10px);
  margin-top: rem(26);
  margin-inline: auto;
  font-weight: 700;
  @include mq(md) {
    margin-top: vw-m(65);
    font-size: vw-m(20);
    letter-spacing: 0.04em;
  }
}

.p-footer__nav {
  display: flex;
  gap: rem(95);
  @include mq(md) {
    justify-content: space-between;
    gap: vw-m(85);
  }
}

.p-footer__nav-list {
  max-width: rem(145);
  width: 100%;
  @include mq(md) {
    max-width: vw-m(250);
  }
}

.p-footer__nav-item {
  padding: rem(11) 0;
  @include mq(md) {
    padding: vw-m(33) 0;
  }
}

.p-footer__nav-item:first-child {
  padding-top: 0;
  @include mq(md) {
  }
}

.p-footer__nav-link {
  font-size: max(rem(16), 10px);
  font-weight: 700;
  display: flex;
  align-items: center;
  word-break: keep-all;
  transition: color 0.3s ease;
  @include mq(md) {
    font-size: vw-m(26);
  }
}

.p-footer__nav-link--about {
  gap: rem(12);
  @include mq(md) {
    gap: vw-m(22);
  }
}

.p-footer__nav-link--facility {
  gap: rem(15);
  @include mq(md) {
    gap: vw-m(31);
  }
}

.p-footer__nav-link--facility span {
  white-space: nowrap;
}

.p-footer__nav-link--charge {
  gap: rem(14);
  @include mq(md) {
    gap: vw-m(29);
  }
}

.p-footer__nav-link--application {
  gap: rem(18.2);
  @include mq(md) {
    gap: vw-m(36);
  }
}

.p-footer__nav-link--access {
  gap: rem(16);
  @include mq(md) {
    gap: vw-m(33);
  }
}

.p-footer__nav-icon{
  display: flex;
  align-items: center;
}

.p-footer__nav-icon svg path {
  transition: all 0.3s ease;
}

@media (any-hover: hover) {
  .p-footer__nav-link:hover {
    color: $red;
  }

  .p-footer__nav-link:hover svg path{
    fill: #FFA2AA;
  }
}

.p-footer__nav-link--about svg {
  width: rem(21.66);
  height: rem(14.92);
  @include mq(md) {
    width: vw-m(47.82);
    height: vw-m(32.94);
  }
}

.p-footer__nav-link--facility svg {
  width: rem(18.97);
  height: rem(12.59);
  @include mq(md) {
    width: vw-m(39.48);
    height: vw-m(26.2);
  }
}

.p-footer__nav-link--charge svg {
  width: rem(18.97);
  height: rem(19.51);
  @include mq(md) {
    width: vw-m(39.48);
    height: vw-m(40.61);
  }
}

.p-footer__nav-link--application svg {
  width: rem(12.24);
  height: rem(14.28);
  @include mq(md) {
    width: vw-m(34.32);
    height: vw-m(40.04);
  }
}

.p-footer__nav-link--access svg {
  width: rem(16.02);
  height: rem(21.2);
  @include mq(md) {
    width: vw-m(33.34);
    height: vw-m(44.12);
  }
}

.p-footer__nav-sub-list {
  padding-left: rem(52);
  position: relative;
  @include mq(md) {
    padding-left: vw-m(110);
  }
}

.p-footer__nav-sub-list::before {
  content: "";
  position: absolute;
  transform: translateY(-50%);
  left: rem(34);
  @include mq(md) {
    left: vw-m(75);
    top: 55%;
  }
}

.p-footer__nav-item:nth-child(3) {
  @include mq(md) {
    padding-top: vw-m(8);
  }
}

.p-footer__nav-item:nth-child(2) .p-footer__nav-sub-list::before {
  top: 55%;
  width: rem(2);
  height: rem(89.95);
  display: block;
  border-left: rem(1) dashed #d1d1d1;
  @include mq(md) {
    top: 55%;
    border-left: vw-m(2) dashed #d1d1d1;
    width: vw-m(2);
    height: vw-m(199.5);
  }
}

.p-footer__nav-item:nth-child(3) .p-footer__nav-sub-list::before {
  top: 55%;
  width: rem(2);
  height: rem(39.7);
  display: block;
  border-left: rem(1) dashed #d1d1d1;
  @include mq(md) {
    border-left: vw-m(2) dashed #d1d1d1;
    height: vw-m(83.99);
    top: 88%;
  }
}

.p-footer__nav-item:nth-child(3) .p-footer__nav-sub-item:last-child .p-footer__nav-sub-link {
  padding-bottom: rem(18);
  @include mq(md) {
    padding-bottom: 0;
    padding-top: vw-m(76);
  }
}

.p-footer__nav-sub-item:first-child .p-footer__nav-sub-link {
  padding-top: rem(20);
  @include mq(md) {
    padding-top: vw-m(47);
  }
}

.p-footer__nav-sub-item:last-child .p-footer__nav-sub-link {
  padding-bottom: rem(4);
}

.p-footer__nav-sub-link {
  color: #4d4d4d;
  font-size: max(rem(14), 9px);
  font-weight: 700;
  padding: rem(10) 0;
  display: block;
  transition: color 0.3s ease;
  @include mq(md) {
    font-size: vw-m(22);
    padding: vw-m(28) 0;
  }
}

@media (any-hover: hover) {
  .p-footer__nav-sub-link:hover {
    color: $red;
  }
}

.p-footer__nav-btn-wrapper {
  max-width: rem(216);
  width: 100%;
  @include mq(md) {
    max-width: vw-m(335);
  }
}

.p-footer__nav-btn {
  display: block;
}

.p-footer__nav-btn {
  width: 100%;
  min-height: rem(56);
  border-radius: rem(10);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 rem(18);
  padding-left: rem(18);
  font-size: max(rem(16), 10px);
  font-weight: 700;
  @include mq(md) {
    font-size: vw-m(24);
    min-height: vw-m(88);
    border-radius: vw-m(10);
  }
}

.p-footer__nav-btn--availability {
  color: #4d4d4d;
  border: rem(1) solid $black;
  transition: all 0.3s ease;
  @include mq(sm) {
    padding-left: rem(10);
  }
}

.p-footer__nav-btn--contact {
  background-color: $red;
  color: $white;
  margin-top: rem(20);
  transition: all 0.3s ease;
  @include mq(md) {
    margin-top: vw-m(35);
  }
}

.p-footer__nav-btn-icon {
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.p-footer__nav-btn--availability svg {
  width: rem(13.42);
  height: rem(15.34);
  @include mq(md) {
    width: vw-m(21.12);
    height: vw-m(24.14);
  }
}

.p-footer__nav-btn--availability svg path {
  transition: all 0.3s ease;
}
.p-footer__nav-btn--contact svg {
  width: rem(16.95);
  height: rem(19.95);
  @include mq(md) {
    width: vw-m(26.34);
    height: vw-m(26.36);
  }
}

.p-footer__nav-btn-ttl {
  margin-left: rem(7.6);
  display: flex;
  align-items: flex-end;
  white-space: nowrap;
  @include mq(md) {
    flex-wrap: wrap;
    margin-left: vw-m(10);
    display: block;
  }
}

.p-footer__nav-btn--contact .p-footer__nav-btn-ttl {
  margin-left: rem(9);
  @include mq(md) {
    margin-left: vw-m(14);
  }
}

.p-footer__nav-btn--bnr {
  margin-top: rem(20);
  width: 100%;
  padding: 0;
  transition: all 0.3s ease;
  @include mq(md) {
    margin-top: vw-m(35);
    display: block;
  }
}

.p-footer__nav-btn--bnr img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.p-footer__nav-btn-sub {
  font-size: max(rem(10), 8px);
  font-weight: 400;
  @include mq(md) {
    font-size: vw-m(16);
  }
}


@media (any-hover: hover) {
  .p-footer__nav-btn:hover {
    scale: 1.025;
  }

  .p-footer__nav-btn--availability:hover {
    color: $red;
    scale: 1;
  }
  .p-footer__nav-btn--availability:hover svg path {
    fill:  #FFA2AA;
  }

  .p-footer__nav-btn--contact:hover {
    background-color: #ff5362;
    scale: 1;
  }
}

.p-footer__nav-center-wrapper {
  margin-top: rem(28);
  display: flex;
  gap: rem(39);
  @include mq(md) {
    margin-top: vw-m(35);
  }
}

.p-footer__nav-center-link {
  display: flex;
  align-items: center;
  gap: rem(8.8);
  color: #4d4d4d;
  transition: color 0.3s ease;
  @include mq(md) {
    gap: vw-m(18);
  }
}

@media (any-hover: hover) {
  .p-footer__nav-center-link:hover {
    color: $red;
  }
  .p-footer__nav-center-link:hover svg path {
    fill: #FFA2AA;
  }
  .p-footer__nav-center-link:hover svg rect {
    fill: #fff;
  }
}

.p-footer__nav-center-icon {
  display: flex;
  align-items: center;
  width: rem(20.15);
  @include mq(md) {
    width: max(vw-m(28.55), 15px);
  }
}

.p-footer__nav-center-icon svg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.p-footer__nav-center-icon svg path {
  transition: all 0.3s ease;
}

.p-footer__nav-center-ttl {
  font-size: rem(16);
  font-weight: 700;
  @include mq(md) {
    font-size: vw-m(18);
  }
}

.p-footer__nav-bottom-link {
  display: block;
  margin-top: rem(28);
  font-size: max(rem(12), 10px);
  font-weight: 500;
  letter-spacing: 0.14em;
  text-decoration: underline;
  text-underline-offset: rem(3);
  color: #959595;
  transition: color 0.3s ease;
  @include mq(md) {
    font-size: vw-m(18);
    margin-top: vw-m(35);
  }
}

.p-footer__nav-bottom-link + .p-footer__nav-bottom-link {
  margin-top: rem(20);
}

@media (any-hover: hover) {
  .p-footer__nav-bottom-link:hover {
    color: $red;
  }
}

.p-footer__copyright {
  text-align: center;
  font-size: max(rem(12), 10px);
  font-weight: 700;
  letter-spacing: 0.14em;
  color: $white;
  margin-inline: auto;
  display: block;
  padding-bottom: rem(26);
  line-height: calc(17 / 12);
  @include mq(md) {
    font-size: vw-m(20);
    padding-bottom: vw-m(112);
  }
}

.p-footer__fix-wrapper {
  display: none;
  @include mq(md) {
    position: fixed;
    bottom: vw-m(-96);
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    background: $white;
    margin-bottom: vw-m(96);
  }
}

.p-footer__fix-btn {
  display: flex;
  align-items: center;
  width: 50%;
  border: vw-m(2) solid $black;
  min-height: vw-m(96);
}

.p-footer__fix-btn--availability {
  border-right: none;
  color: #4d4d4d;
  font-weight: 700;
  font-size: vw-m(26);
  gap: vw-m(19);
  padding: vw-m(15) vw-m(10) vw-m(15) vw-m(34);
}

.p-footer__fix-btn--availability svg {
  width: vw-m(20.49);
  height: vw-m(23.41);
}

.p-footer__fix-btn-icon svg {
  display: flex;
  align-items: center;
}

.p-header__sp-btn-sub {
  font-weight: 400;
  font-size: vw-m(19);
}

.p-footer__fix-btn--contact {
  background-color: $red;
  color: $white;
  padding: vw-m(15) vw-m(10) vw-m(15) vw-m(47.6);
  letter-spacing: 0.06em;
  font-size: vw-m(34);
  gap: vw-m(19);
}

.p-footer__fix-btn--contact svg {
  width: vw-m(37.37);
  height: vw-m(37.39);
}


.p-footer__fix-btn-sub {
  @include mq(md) {
    font-size: vw-m(19);
    font-weight: 400;
  }
}

.p-footer__fix-btn {
  @include mq(md) {
    font-size: vw-m(26);
  }
}

.p-footer__fix-btn--contact {
  @include mq(md) {
    font-size: vw-m(34);
    font-weight: 900;
    letter-spacing: 0.06em;
    gap: vw-m(19);
    display: flex;
    align-items: center;
  }
}

.p-footer__fix-btn--contact .p-footer__fix-btn-icon svg {
  @include mq(md) {
    width: vw-m(37.37);
    height: vw-m(37.39);
    margin-top: vw-m(12);
  }
}
