@use "global" as *;

.p-usage {
  background-color: $white;
  margin-top: rem(130);
  padding-bottom: rem(10);
  @include mq(md) {
    margin-top: vw-m(160);
    padding-bottom: 0;
  }
}

.p-usage__bg-wrapper {
  position: relative;
}

.p-usage__bg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.p-usage__ttl {
  color: $green;
  text-align: center;
}

.p-usage__ttl::after {
  color: $white;
}

.c-sec-ttl.p-usage__ttl {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  @include mq(md) {
    top: 64%;
  }
}

.p-usage__txt {
  font-size: max(rem(16), 10px);
  letter-spacing: 0.09em;
  text-align: center;
  margin-top: rem(60);
  @include mq(md) {
    margin-top: vw-m(63);
    font-size: vw-m(26);
    line-height: calc(66 / 26);
    text-align: left;
    padding-inline: vw-m(30);
  }
}

