@use "global" as *;

.c-sub-ttl {
  font-size: rem(26);
  font-weight: 700;
  color: $red;
  font-family: $font-sub;
  @include mq(md) {
    font-size: vw-m(28);
  }
}

.c-sub-ttl span {
  font-size: max(rem(16), 10px);
  font-weight: 700;
  color: $black;
  display: block;
  margin-top: rem(-3);
  font-family: $font-main;
  @include mq(md) {
    margin-top: vw-m(-5);
    font-size: vw-m(21);
    line-height: 1.2;
  }
}