@use "global" as *;


.p-price.p-price--rental {
  margin-top: rem(50);
  @include mq(md) {
    margin-top: vw-m(90);
  }
}

.p-price__top-txt {
  margin-top: rem(7);
  letter-spacing: 0.09em;
  font-weight: 400;
  font-size: var(--fz16);
  line-height: calc(30/16);
  @include mq(md) {
    margin-top: vw-m(35);
    font-size: vw-m(24);
    letter-spacing: 0.06em;
    line-height: calc(46/24);
  }
}

.p-price__top-txt + .p-price__top-txt {
  margin-top: 0;
}

.p-price__container {
  margin-top: rem(22);
  @include mq(md) {
    margin-top: vw-m(33);
  }
}

.p-price__item {
  cursor: pointer;
}

/*個人料金*/
.p-price__item:nth-child(3) .p-details {
  padding-bottom: rem(57);
  margin-bottom: 0;
  @include mq(md) {
    padding-bottom: vw-m(57);
    margin-bottom: vw-m(30);
  }
}
.p-charge__container.p-charge__container--rental
.p-price__item:nth-child(3) .p-details__item-container {
  margin-top: rem(24);
  padding-bottom: rem(16);
  @include mq(md) {
    margin-top: vw-m(22);
    padding-bottom: vw-m(46);
  }
}

.p-price__item:nth-child(3) .p-details__item-container {
  @include mq(md) {
    margin-top: vw-m(22);
    padding-bottom: vw-m(16);
  }
}


.p-price__item:nth-child(3) .p-details__item-wrapper + .p-details__item-wrapper {
  margin-top: rem(22);
  @include mq(md) {
    margin-top: vw-m(29);
  }
}

.p-price__item:nth-child(3) .p-details__item-wrapper {
  padding-bottom: rem(21);
  @include mq(md) {
    padding-bottom: vw-m(29);
  }
}
/*設備使用料*/
.p-price__item:nth-child(4) .p-details {
  padding-bottom: rem(57);
  margin-top: rem(34);
  @include mq(md) {
    padding-bottom: vw-m(46);
    margin-top: vw-m(34);
  }
}

.p-price__item:nth-child(4) .p-details__item-container {
  margin-top: 0;
  padding: rem(45) rem(38.5) rem(38) rem(32);
  @include mq(md) {
    margin-top:0;
    padding: vw-m(45) vw-m(38.5) vw-m(11) vw-m(32);
  }
}

.p-price__item:nth-child(4) .p-details__item-wrapper {
  padding-bottom: rem(27);
  gap: rem(45);
  @include mq(md) {
    padding-bottom: vw-m(26);
    gap: vw-m(37);
  }
}


.p-price__item:not(:first-child) {
  margin-top: rem(24);
  @include mq(md) {
    margin-top: vw-m(46);
  }
}

.p-price__item-ttl-wrapper {
  position: relative;
  padding: rem(13) rem(28) rem(13) rem(25);
  background-color: #4d4d4d;;
  border-radius: rem(8);
  min-height: rem(56);
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include mq(md) {
    min-height: vw-m(96);
    padding: vw-m(32) vw-m(30) vw-m(32) vw-m(25);
  }
}

.p-price__item-ttl {
  font-size: var(--fz18);
  line-height: calc(26/18);
  font-weight: 700;
  letter-spacing: 0.04em;
  color: $white;
  display: flex;
  align-items: center;
  height: inherit;
  @include mq(md) {
    font-size: vw-m(22);
    line-height: calc(32/22);
  }
}

.p-price__item-ttl-icon  {
  display: block;
}

.p-price__item-ttl-icon svg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.p-price__item-ttl-icon--miyako {
  width: rem(35.24);
  margin-right: rem(16);
  @include mq(md) {
    width: vw-m(35.24);
    margin-right: vw-m(16);
  }
}

.p-price__item-ttl-icon--ppl {
  width: rem(30.7);
  margin-right: rem(15);
  margin-left: calc(rem(35) - rem(30));
  @include mq(md) {
    width: vw-m(30.7);
    margin-right: vw-m(17);
    margin-left: calc(vw-m(29) - vw-m(25));
  }
}

.p-price__item-ttl-icon--person {
  width: rem(18.71);
  margin-right: rem(23);
  margin-left: calc(rem(35) - rem(25));
  @include mq(md) {
    width: vw-m(18.71);
    margin-right: vw-m(23);
    margin-left: calc(vw-m(35) - vw-m(25));
  }
}

.p-price__item-ttl-icon--speaker {
  width: rem(17.45);
  margin-right: rem(22);
  margin-left: calc(rem(37) - rem(25));
  @include mq(md) {
    width: vw-m(17.45);
    margin-right: vw-m(23);
    margin-left: calc(vw-m(38) - vw-m(25));
  }
}

.p-price__item-ttl-icon--table {
  width: rem(24.99);
  margin-right: rem(21);
  margin-left: calc(rem(30) - rem(25));
  @include mq(md) {
    width: vw-m(24.99);
    margin-right: vw-m(22);
    margin-left: calc(vw-m(29) - vw-m(25));
  }
}

.p-price__item-ttl-icon--sport {
  width: rem(24.56);
  margin-right: rem(22);
  margin-left: calc(rem(30) - rem(25));
  @include mq(md) {
    width: vw-m(26.01);
    margin-right: vw-m(21);
    margin-left: calc(vw-m(29) - vw-m(25));
  }
}

.p-price__item-ttl-icon--other {
  width: rem(15.49);
  margin-right: rem(31);
  margin-left: calc(rem(30) - rem(25));
  @include mq(md) {
    width: vw-m(18.77);
    margin-right: vw-m(22);
    margin-left: calc(vw-m(36) - vw-m(25));
  }
}

.p-price__btn {
  display: flex;
  align-items: center;
  height: inherit;
  @include mq(md) {
    right: vw-m(30);
  }
}

/*会議室*/
.p-price.p-price--rental .p-details__item-wrapper {
  margin-top: rem(19);
  padding-bottom: rem(19);
  gap: rem(45);
  @include mq(md) {
    gap: vw-m(27);
    margin-top: vw-m(30);
    padding-bottom: vw-m(30);
  }
}

.p-price.p-price--rental .p-details__item-container {
  margin-top: 0;
  padding: rem(33) rem(38.5) rem(19) rem(32);
  @include mq(md) {
    padding: vw-m(15) vw-m(38.5) vw-m(47) vw-m(38);
  }
}