@use "global" as *;


.c-card-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: rem(34) rem(46);
  @include mq(md) {
    grid-template-columns: repeat(2, 1fr);
    gap: vw-m(68) vw-m(30);
  }
}

.c-card-list__item-img {
  aspect-ratio: 280 / 200;
  border-radius: rem(20);
  object-fit: contain;
  @include mq(md) {
    aspect-ratio: 330 / 236;
  }
}

.c-card-list__item-ttl {
  font-size: max(rem(16), 10px);
  font-weight: 700;
  line-height: calc(30 / 16);
  letter-spacing: 0.09em;
  margin-top: rem(10);
  @include mq(md) {
    font-size: vw-m(22);
    line-height: calc(34 / 22);
    margin-top: vw-m(16);
  };
}

