@use "global" as *;

.l-bg {
  position: relative;
  z-index: 1;
  margin-top: vw-lg(112);
  position: relative;
  @include mq(md) {
    margin-top: vw-m(112);
  }
}

.l-bg::before {
  content: "";
  display: block;
  width: 100%;
  background-image: url(../img/bg_sub.png);
  background-position: top;
  background-repeat: no-repeat;
  position: absolute;
  top: vw-lg(-111);
  left: 0;
  z-index: -1;
  @include mq(md) {
    background-image: url(../img/bg_sub-sp.png);
  }
}

// .l-bg::before {
//   content: "";
//   display: block;
//   width: 100%;
//   height: vw-lg(112);
//   background-image: url(../img/bg_top.png);
//   background-size: cover;
//   background-position: center;
//   background-repeat: no-repeat;
//   position: absolute;
//   top: vw-lg(-111);
//   left: 0;
//   z-index: -1;
// }

// .l-bg::after {
//   content: "";
//   display: block;
//   width: 100%;
//   height: vw-lg(115);
//   background-image: url(../img/bg_bottom.png);
//   background-size: cover;
//   background-position: center;
//   background-repeat: no-repeat;
//   position: absolute;
//   bottom: vw-lg(-114);
//   left: 0;
//   background-color: $white;
// }