@use "global" as *;

.p-naming {
  background-color: $white;
  position: relative;
  z-index: 1;
  padding-top: rem(80);
  padding-bottom: rem(60);
  margin-top: rem(-1);
  @include mq(md) {
    padding-top: vw-m(48);
    padding-bottom: vw-m(33);
  }
}

.p-naming__wrapper {
  margin-top: rem(25);
  display: flex;
  align-items: center;
  gap: rem(129);
  border: 1px solid #D8D8D8;
  border-radius: rem(20);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  padding: rem(45) rem(67) rem(45) rem(47);
  @include mq(md) {
    margin-top: vw-m(40);
    flex-direction: column;
    gap: vw-m(86);
    justify-content: center;
    border-radius: vw-m(20);
    padding: vw-m(102) vw-m(70) vw-m(95) vw-m(70);
  }
}

.p-naming__img {
  max-width: rem(467);
  width: 100%;
  @include mq(md) {
    max-width: vw-m(550);
  }
}

.p-naming__img img {
  width: 100%;
  object-fit: contain;
}

.p-naming__txt {
  max-width: rem(389);
  width: 100%;
  font-size: max(rem(16), 10px);
  line-height: calc(36 / 16);
  letter-spacing: 0.09em;
  @include mq(md) {
    max-width: vw-m(551);
    font-size: vw-m(26);
    line-height: calc(66 / 26);
  }
}

