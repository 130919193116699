@use "global" as *;

.p-top-facility {
  background-color: $white;
  padding-top: rem(90);
  @include mq(md) {
    padding-top: vw-m(98);
  }
}

.p-top-facility__wrapper {
  padding: rem(101) rem(100) rem(64.5);
  @include mq(md) {
    padding: vw-m(91) vw-m(30) vw-m(116);
  }
}

.p-top-facility__img-modal-link {
  max-width: rem(900);
  width: 100%;
  position: relative;
  z-index: 1;
}

.p-top-facility__img-modal-link img {
  aspect-ratio: 900/464;
  width: 100%;
  object-fit: contain;
  border-radius: rem(36);
  @include mq(md) {
    aspect-ratio: 690/464;
    border-radius: vw-m(26);
  }
}
.p-top-facility__icon {
  width: rem(30);
  height: rem(30);
  position: absolute;
  bottom: rem(28);
  right: rem(56);
  z-index: 1;
  transition: scale 0.3s ease-in-out;
  @include mq(md) {
    width: vw-m(30);
    height: vw-m(30);
    right: vw-m(48);
    bottom: vw-m(28);
  }
}

.p-top-facility__icon > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 0;
}

@media (any-hover: hover) {
  .p-top-facility__img-modal-link:hover .p-top-facility__icon {
    scale: 1.2;
  }
}

.p-top-facility__heading {
  margin-top: rem(19.5);
  font-weight: 700;
  letter-spacing: 0.06em;
  font-size: rem(26);
  line-height: calc(37 / 26);
  @include mq(md) {
    margin-top: vw-m(41);
    font-size: vw-m(42);
    line-height: calc(66 / 42);
  }
}

.p-top-facility__img-modal-link {
  display: block;
  width: 100%;
  margin-top: rem(38);
  border-radius: rem(36);
  cursor: pointer;
  position: relative;
  @include mq(md) {
    margin-top: vw-m(51);
  }
}

.p-top-facility__lists {
  margin-top: rem(40);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: rem(61) rem(8);
  @include mq(md) {
    margin-top: vw-m(50);
    gap: vw-m(50) vw-m(26);
  }
}

.p-top-facility__btn {
  max-width: rem(340);
  width: 100%;
  margin: rem(55) auto 0;
  font-size: max(rem(14), 10px);
  font-weight: 700;
  @include mq(md) {
    max-width: vw-m(600);
    margin-top: vw-m(70);
    font-size: vw-m(24);
    border-radius: vw-m(50);
    min-height: vw-m(100);
    padding-left: vw-m(60);
  }
}

.p-top-facility__btn::after {
  @include mq(md) {
    right: vw-m(27);
    width: vw-m(34);
    height: vw-m(34);
  }
}

.p-top-facility__img-wrapper {
  max-width: rem(446);
  width: 100%;
  position: relative;
  @include mq(md) {
    max-width: vw-m(332);
    border-radius: vw-m(23);
  }
}

.p-top-facility__img-wrapper::before {
  content: "";
  position: absolute;
  bottom: rem(-2);
  right: rem(-1);
  width: rem(41);
  height: rem(41);
  background-image: url(../img/icon/icon_arrow-right.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 1;
  @include mq(md) {
    width: vw-m(62);
    height: vw-m(62);
    bottom: vw-m(-3);
    right: vw-m(-3);
  }
}

.p-top-facility__img-wrapper img {
  aspect-ratio: 444/208;
  object-fit: cover;
  @include mq(md) {
    aspect-ratio: 332/276;
  }
}

.p-top-facility__img-ttl {
  color: $red;
  margin-top: rem(17);
  font-family: $font-sub;
  font-weight: 700;
  font-size: rem(26);
  line-height: 1;
  @include mq(md) {
    margin-top: vw-m(10);
    font-size: vw-m(28);
    line-height: calc(40 / 28);
  }
}

.p-top-facility__txt {
  margin-top: rem(1);
  font-size: max(rem(16), 10px);
  font-weight: 700;
  line-height: 1.5;
  position: relative;
  z-index: 1;
  display: inline-block;
  @include mq(md) {
    margin-top: vw-m(3);
    font-size: vw-m(22);
    line-height: calc(40 / 22);
    display: inline;
  }
}

.p-top-facility__txt::before {
  content: "";
  position: absolute;
  bottom: rem(-4);
  left: 0;
  width: calc(100% + rem(10));
  height: 50%;
  background: $white;
  border-radius: rem(10);
  z-index: 0;
  display: inline-block;
  @include mq(md) {
    display: none;
  }
}

.p-top-facility__txt span {
  display: inline;
  position: relative;
  z-index: 1;
  @include mq(md) {
    display: none;
  }
}

.p-top-facility__bottom-list {
  margin-top: rem(80);
  display: flex;
  gap: rem(50);
  @include mq(md) {
    margin-top: vw-m(90);
    gap: vw-m(26);
  }
}

.p-top-facility__bottom-item {
  max-width: rem(516);
  width: 100%;
}

.p-top-facility__bottom-link {
  display: flex;
  align-items: center;
  gap: rem(20);
  @include mq(md) {
    gap: vw-m(15);
    flex-direction: column;
    align-items: flex-start;
  }
}

.p-top-facility__bottom-img-wrapper {
  max-width: rem(304);
  width: 100%;
  border-radius: rem(20);
  position: relative;
  @include mq(md) {
    max-width: vw-m(332);
    border-radius: vw-m(22);
  }
}

.p-top-facility__bottom-img-wrapper::before {
  content: "";
  position: absolute;
  bottom: rem(-2);
  right: rem(-1);
  width: rem(41);
  height: rem(41);
  background-image: url(../img/icon/icon_arrow-right.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 1;
  @include mq(md) {
    width: vw-m(64);
    height: vw-m(64);
    bottom: vw-m(-2);
    right: vw-m(-4);
  }
}

.p-top-facility__bottom-img-wrapper img {
  border-radius: rem(20);
  aspect-ratio: 304/187;
  object-fit: cover;
  min-width: rem(304);
  @include mq(md) {
    aspect-ratio: 332/200;
    border-radius: vw-m(20);
    min-width: unset;
  }
}

/* modal */
.p-top-facility__modal {
  max-width: rem(1440);
  width: 100%;
  padding: rem(10) 0;
  background: none;
}

.p-top-facility__modal-btn-wrapper.p-modal__btn-wrapper {
  right: 4%;
  top: -3%;
  @include mq(md) {
    right: 6.5%;
    top: -5%;
  }
}

.p-top-facility__modal-container {
  padding: 0 rem(25);
  max-width: rem(1440);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @include mq(md) {
    padding: 0 vw-m(30);
  }
}

.p-top-facility__modal-img-wrapper {
  max-width: rem(1440);
  width: 100%;
}

.p-top-facility__modal-img-wrapper img {
  width: 100%;
  object-fit: cover;
}

